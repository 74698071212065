import { useIntl } from "react-intl";
import {
  MIN_PHONE_NUMBER_LEN,
  MAX_PHONE_NUMBER_LEN,
} from "../../../common/constants";
import commonMessages from "../../../common/messages";
import { ValidationTranslations } from "./getValidationSchema";
import messages from "./messages";

const useTranslations = (params: {
  minimumValue: number;
  maximumValue: number;
}) => {
  const { minimumValue, maximumValue } = params;
  const { formatMessage } = useIntl();

  const translations = {
    defaultError: formatMessage(commonMessages.defaultError),
    errorEmailInUse: formatMessage(messages.errorEmailInUse),
  };

  const validationSchemaTranslations: ValidationTranslations = {
    requiredField: formatMessage(messages.requiredField),
    dateIsInvalid: formatMessage(messages.dateIsInvalid),
    emailIsInvalid: formatMessage(messages.emailIsInvalid),
    phoneNumberIsInvalid: formatMessage(messages.phoneNumberIsInvalid),
    pregnantError: formatMessage(messages.pregnantError),
    wearingDeviceError: formatMessage(messages.wearingDeviceError),
    minimumLengthError: formatMessage(commonMessages.minimumLengthError, {
      value: minimumValue,
    }),
    maximumLengthError: formatMessage(commonMessages.maximumLengthError, {
      value: maximumValue,
    }),
    phoneNumberMinLengthError: formatMessage(
      commonMessages.minimumLengthError,
      { value: MIN_PHONE_NUMBER_LEN },
    ),
    phoneNumberMaxLengthError: formatMessage(
      commonMessages.maximumLengthError,
      { value: MAX_PHONE_NUMBER_LEN },
    ),
  };

  return { translations, validationSchemaTranslations };
};

export default useTranslations;
