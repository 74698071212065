import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import { ITheme } from "../../../common/theme";

export default makeStyles<ITheme>(({ spacing, palette }) => ({
  paperRoot: {
    padding: spacing(3),
    backgroundColor: alpha(palette.primary.main, 0.08),
  },
  titleRoot: {
    lineHeight: "266%",
    letterSpacing: 1,
    textTransform: "uppercase",
    color: palette.common.black,
  },
  boldTextRoot: {
    fontWeight: 700,
  },
  linkRoot: {
    textDecoration: "none",
  },
}));
