import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "Appointment.AppointmentWelcomeDialog.title",
    defaultMessage: "Confirm your email address",
  },
  desc: {
    id: "Appointment.AppointmentWelcomeDialog.desc",
    defaultMessage:
      "We need to confirm your email address since it will be receiving sensitive medical information and so we can retrieve your information from previous appointments.",
  },
  emailDescStart: {
    id: "Appointment.AppointmentWelcomeDialog.emailDescStart",
    defaultMessage: "We’ve sent an email to ",
  },
  emailDescEnd: {
    id: "Appointment.AppointmentWelcomeDialog.emailDescEnd",
    defaultMessage: " with a code to confirm your email address.",
  },
  resendButton: {
    id: "Appointment.AppointmentWelcomeDialog.resendButton",
    defaultMessage: "Resend email",
  },
  submitButton: {
    id: "Appointment.AppointmentWelcomeDialog.submitButton",
    defaultMessage: "Submit",
  },
  emailCodePlaceholder: {
    id: "Appointment.AppointmentWelcomeDialog.emailCodePlaceholder",
    defaultMessage: "Enter confirmation code",
  },
  invalidCodeError: {
    id: "Appointment.AppointmentWelcomeDialog.invalidCodeError",
    defaultMessage: "Please provide a valid code",
  },
  useAnotherEmail: {
    id: "Appointment.AppointmentWelcomeDialog.useAnotherEmail",
    defaultMessage: "Use another email address",
  },
});
