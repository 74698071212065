import React, { FC, ReactNode } from "react";
import { useIntl } from "react-intl";
import { DEFAULT_CURRENCY_CODE } from "../../../common/constants";
import Box from "../../Unknown/Box";
import Container from "../../Unknown/Container";
import AppointmentSummaryDetails, {
  AppointmentSummaryDetailsProps,
} from "../AppointmentSummaryDetails";
import AppointmentSummaryMobile from "../AppointmentSummaryMobile";

type AppointmentLayoutMobileProps = {
  summaryDetailsProps: AppointmentSummaryDetailsProps;
  children?: ReactNode;
};

const AppointmentLayoutMobile: FC<AppointmentLayoutMobileProps> = ({
  summaryDetailsProps,
  children,
}) => {
  const { formatNumber } = useIntl();

  const { selectedCenter, priceWithDiscount } = summaryDetailsProps;

  const { defaultCurrencyCode } = selectedCenter || {};

  const summary = formatNumber(priceWithDiscount, {
    style: "currency",
    currency: defaultCurrencyCode || DEFAULT_CURRENCY_CODE,
  });

  const {
    onSubmitClick,
    isSubmitButtonDisabled,
    isSubmitLoading,
    buttonSubmitText,
    ...detailsProps
  } = summaryDetailsProps;

  return (
    <Container maxWidth="xl" sx={{ paddingX: 3 }}>
      <Box mt={3} mb={16}>
        {children}
      </Box>
      <AppointmentSummaryMobile
        summary={summary}
        onSubmitClick={onSubmitClick}
        isSubmitButtonDisabled={isSubmitButtonDisabled}
        isSubmitLoading={isSubmitLoading}
        buttonSubmitText={buttonSubmitText}
      >
        <AppointmentSummaryDetails {...detailsProps} disableCenterShowing />
      </AppointmentSummaryMobile>
    </Container>
  );
};

export default AppointmentLayoutMobile;
