import { useCallback, useState } from "react";

type DataStateLoading = { status: "loading"; data: null; error: null };
type DataStateError = { status: "error"; data: null; error: string };
type DataStateSuccess<T> = { status: "success"; data: T; error: null };

export type DataState<T> =
  | DataStateLoading
  | DataStateError
  | DataStateSuccess<T>;

export type UpdateDataStateParams<T> =
  | { status: "loading" }
  | { status: "error"; error: string }
  | { status: "success"; data: T };

const useDataState = <T>() => {
  const [dataState, setDataState] = useState<DataState<T>>({
    status: "loading",
    data: null,
    error: null,
  });

  const updateDataState = useCallback<
    (params: UpdateDataStateParams<T>) => void
  >((params) => {
    const { status } = params;
    if (status === "loading") {
      setDataState({ status: "loading", data: null, error: null });
    }
    if (status === "error") {
      const { error } = params;
      setDataState({ status: "error", data: null, error });
    }
    if (status === "success") {
      const { data } = params;
      setDataState({ status: "success", data, error: null });
    }
  }, []);

  return { dataState, updateDataState };
};

export default useDataState;
