import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();
  const translations = {
    selectSideLabel: formatMessage(messages.selectSideLabel),
    leftSide: formatMessage(messages.leftSide),
    rightSide: formatMessage(messages.rightSide),
  };

  return { translations };
};

export default useTranslations;
