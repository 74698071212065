import { useCallback, useState } from "react";
import { useEvent, useIsomorphicLayoutEffect } from "react-use";

type Position = {
  offsetTop: number;
  offsetLeft: number;
  offsetWidth: number;
  offsetHeight: number;
};

const basePosition = {
  offsetTop: 0,
  offsetLeft: 0,
  offsetWidth: 0,
  offsetHeight: 0,
};

const useElementPosition = <T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  Position,
] => {
  const [ref, setRef] = useState<T | null>(null);
  const [position, setPosition] = useState(basePosition);

  const handlePosition = useCallback(() => {
    if (!ref) {
      setPosition(basePosition);
      return;
    }

    const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = ref;

    setPosition({ offsetTop, offsetLeft, offsetWidth, offsetHeight });
  }, [ref]);

  useEvent("reposition", handlePosition);
  useEvent("resize", handlePosition);

  useIsomorphicLayoutEffect(() => {
    handlePosition();
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  return [setRef, position];
};

export default useElementPosition;
