import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = {
    submitButtonText: formatMessage(messages.submitButtonText),
  };

  return { translations };
};

export default useTranslations;
