/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect } from "react";
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import useDataState from "../../../hooks/useDataState";
import useSupabase from "../../../hooks/useSupabase";

export type SupabaseBookingAddOn = Pick<
  DatabaseRow<"center_add_ons">,
  "id" | "name" | "type" | "price"
>;

const supabaseBookingAddOnsRequest = `
id,
name,
type,
price,
visibility
`;

export type BookingAddOn = {
  id: string;
  name: string;
  type: string;
  price: number;
};

export const parseAddOn = (addOn: SupabaseBookingAddOn): BookingAddOn => {
  return {
    id: addOn.id,
    name: addOn.name,
    type: addOn.type,
    price: addOn.price,
  };
};

const useBookingAddOns = (centerId?: string) => {
  const supabase = useSupabase();

  const { dataState, updateDataState } = useDataState<BookingAddOn[]>();

  const getAddOnsRequest = useCallback(async () => {
    try {
      if (!centerId) return;

      updateDataState({ status: "loading" });

      const response = await supabase
        .from<DatabaseRow<"center_add_ons">>("center_add_ons")
        .select(supabaseBookingAddOnsRequest)
        .eq("center_id", centerId)
        .eq("visibility", "public");

      if (response.error) {
        throw new Error(response.error.message);
      }

      if (response.data) {
        const parsedAddOns = response.data.map(parseAddOn);
        updateDataState({ status: "success", data: parsedAddOns });
      }
    } catch (err) {
      updateDataState({ status: "error", error: (err as Error).message });
    }
  }, [centerId, supabase, updateDataState]);

  useEffect(() => {
    getAddOnsRequest();
  }, [getAddOnsRequest]);

  return dataState;
};

export default useBookingAddOns;
