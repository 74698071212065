import React, { FC, ReactNode } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useElementPosition from "../../../hooks/useElementPosition";
import Box from "../../Unknown/Box";
import Container from "../../Unknown/Container";
import Grid from "../../Unknown/Grid";
import AppointmentSummaryDesktop from "../AppointmentSummaryDesktop";
import AppointmentSummaryDetails, {
  AppointmentSummaryDetailsProps,
} from "../AppointmentSummaryDetails";
import { marginY, spacingBreakpoint } from "./constants";
import useStyles from "./useStyles";

type AppointmentLayoutDesktopProps = {
  summaryDetailsProps: AppointmentSummaryDetailsProps;
  children?: ReactNode;
};

const AppointmentLayoutDesktop: FC<AppointmentLayoutDesktopProps> = ({
  summaryDetailsProps,
  children,
}) => {
  const [summaryParentRef, summaryParentPosition] = useElementPosition();

  const theme = useTheme();
  const isSpacingLess = useMediaQuery(
    theme.breakpoints.down(spacingBreakpoint),
  );

  const { summaryWrapperRoot } = useStyles();

  return (
    <Container maxWidth="xl">
      <Grid container direction="row" wrap="nowrap" columns={10} my={marginY}>
        <Grid item xs mr={isSpacingLess ? 12 : 14}>
          {children}
        </Grid>
        <Grid
          item
          minWidth={275}
          width="33%"
          maxWidth={360}
          ref={summaryParentRef}
        >
          <Box
            position="fixed"
            width={summaryParentPosition.offsetWidth}
            className={summaryWrapperRoot}
          >
            <AppointmentSummaryDesktop>
              <AppointmentSummaryDetails {...summaryDetailsProps} />
            </AppointmentSummaryDesktop>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AppointmentLayoutDesktop;
