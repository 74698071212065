import { useState } from "react";
import { useParams } from "react-router-dom";

import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { PaymentType } from "../../../common/types";
import useTranslations from "./useTranslations";

export type SubmitValues = {
  paymentMethodId: string;
  centerPaymentMethodId: string;
  amount: number;
  paymentType: PaymentType;
};

const useSubmitHandler = (params: {
  setErrorMessage: (value: string | null) => void;
  handleAddPayment: () => void;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { setErrorMessage, handleAddPayment } = params;

  const { appointmentId } = useParams();

  const { translations } = useTranslations();

  const payAppointmentInitialBalance = useFirebaseAppFunction(
    "payAppointmentInitialBalance",
  );

  const onSubmit = async (values: SubmitValues) => {
    setIsSubmitting(true);
    try {
      const { paymentMethodId, centerPaymentMethodId, amount, paymentType } =
        values;

      if (!appointmentId) return;

      const {
        data: { status },
      } = await payAppointmentInitialBalance({
        paymentMethodId,
        centerPaymentMethodId,
        amount,
        appointmentId,
        paymentType,
      });

      switch (status) {
        case "succeeded":
          handleAddPayment();
          break;
        case "failed":
          setErrorMessage(translations.paymentFailed);
          break;
        case "timeout_exceeded":
          setErrorMessage(translations.timeoutExceeded);
          break;
        default:
          setErrorMessage(translations.defaultError);
      }
    } catch (error) {
      const message =
        error instanceof Error ? error.message : translations.defaultError;
      setErrorMessage(message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return { onSubmit, isSubmitting };
};

export default useSubmitHandler;
