import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = {
    btnChange: formatMessage(messages.btnChange),
    btnBook: formatMessage(messages.btnBook),
  };

  return translations;
};

export default useTranslations;
