import { defineMessages } from "react-intl";

export default defineMessages({
  yourInformationHeader: {
    id: "Appointment.AppointmentCheckoutGuardianForm.yourInformationHeader",
    defaultMessage: "Your information",
  },
  emailLabel: {
    id: "Appointment.AppointmentCheckoutGuardianForm.emailLabel",
    defaultMessage: "Email address",
  },
  phoneNumberLabel: {
    id: "Appointment.AppointmentCheckoutGuardianForm.phoneNumberLabel",
    defaultMessage: "Mobile phone number",
  },
  firstNameLabel: {
    id: "Appointment.AppointmentCheckoutGuardianForm.firstNameLabel",
    defaultMessage: "First name",
  },
  lastNameLabel: {
    id: "Appointment.AppointmentCheckoutGuardianForm.lastNameLabel",
    defaultMessage: "Last name",
  },
  patientInformationHeader: {
    id: "Appointment.AppointmentCheckoutGuardianForm.patientInformationHeader",
    defaultMessage: "Patient information",
  },
  patientInformationDesc: {
    id: "Appointment.AppointmentCheckoutGuardianForm.patientInformationDesc",
    defaultMessage: "The information of the person attending the appointment",
  },
  dateOfBirthLabel: {
    id: "Appointment.AppointmentCheckoutGuardianForm.dateOfBirthLabel",
    defaultMessage: "Date of birth",
  },
  dateOfBirthPlaceholder: {
    id: "Appointment.AppointmentCheckoutGuardianForm.dateOfBirthPlaceholder",
    defaultMessage: "mm/dd/yyyy",
  },
  pregnantHeader: {
    id: "Appointment.AppointmentCheckoutGuardianForm.pregnantHeader",
    defaultMessage: "Is the patient pregnant?",
  },
  answerYes: {
    id: "Appointment.AppointmentCheckoutGuardianForm.answerYes",
    defaultMessage: "Yes",
  },
  answerNo: {
    id: "Appointment.AppointmentCheckoutGuardianForm.answerNo",
    defaultMessage: "No",
  },
  wearingDeviceHeader: {
    id: "Appointment.AppointmentCheckoutGuardianForm.wearingDeviceHeader",
    defaultMessage: "Is the patient wearing a pacemaker or aneurysm clip?",
  },
  checkUpToDateData: {
    id: "Appointment.AppointmentCheckoutGuardianForm.checkUpToDateData",
    defaultMessage: "Please check your information is correct and up to date",
  },
  mobilePhoneNumberHelper: {
    id: "Appointment.AppointmentCheckoutGuardianForm.mobilePhoneNumberHelper",
    defaultMessage: "Enter only numbers, including the area code",
  },
  dateOfBirthHelper: {
    id: "Appointment.AppointmentCheckoutGuardianForm.dateOfBirthHelper",
    defaultMessage: "Use format mm/dd/yyyy",
  },
  authErrorMessage: {
    id: "Appointment.AppointmentCheckoutGuardianForm.authErrorMessage",
    defaultMessage:
      "This email is already in use, please confirm authorization or choose another address",
  },
});
