import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles<Theme>((theme) => ({
  input: {
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot": {
      paddingRight: theme.spacing(4),
    },
  },
  searchIcon: {
    color: theme.palette.text.secondary,
  },
}));
