import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "../../Unknown/Box";
import Grid from "../../Unknown/Grid";
import AppointmentBookedInformation from "../AppointmentBookedInformation";
import useTranslations from "./useTranslations";
import Typography from "../../Unknown/Typography";
import useConfirmedAppointment from "./useConfirmedAppointment";
import { ConfirmedAppointment } from "./parseConfirmedAppointment";
import CircularProgress from "../../Unknown/CircularProgress";
import Alert from "../../Unknown/Alert";
import AlertTitle from "../../Unknown/AlertTitle";
import useStyles from "./useStyles";

const AppointmentConfirmedScreen: FC = () => {
  const { descriptionRoot, alertRoot } = useStyles();

  const { appointmentId } = useParams();

  const { data: appointmentData } = useConfirmedAppointment(appointmentId);

  const [appointmentStatus, setAppointmentStatus] = useState<
    ConfirmedAppointment["status"] | null
  >(null);

  useEffect(() => {
    if (!appointmentData) return;
    const { status } = appointmentData;
    setAppointmentStatus(status);
  }, [appointmentData, appointmentStatus]);

  const { translations } = useTranslations({
    email: appointmentData?.customerDetails.email || "",
  });

  const {
    titleText,
    incompleteTitleText,
    descriptionText,
    confirmationRequired,
    incompleteWarningMessage,
  } = translations;

  if (
    !appointmentData ||
    !appointmentStatus ||
    !["incomplete", "confirmed"].includes(appointmentStatus)
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        mt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  const title =
    appointmentStatus === "incomplete" ? incompleteTitleText : titleText;

  return (
    <Box display="flex" justifyContent="center" alignItems="center" m={6}>
      <Box width="100%" maxWidth={760}>
        <Grid container spacing={7} direction="column">
          <Grid item>
            <Box>
              <Typography variant="h2" sx={{ wordBreak: "break-word" }}>
                {title}
              </Typography>
            </Box>
            {appointmentStatus === "confirmed" && (
              <Typography
                mt={1}
                className={descriptionRoot}
                sx={{ wordBreak: "break-word" }}
              >
                {descriptionText}
              </Typography>
            )}
          </Grid>
          {appointmentStatus === "incomplete" && (
            <Grid item>
              <Alert severity="warning" classes={{ root: alertRoot }}>
                <AlertTitle>{confirmationRequired}</AlertTitle>
                <Typography variant="body1" fontSize={14}>
                  {incompleteWarningMessage}
                </Typography>
              </Alert>
            </Grid>
          )}
          <Grid item>
            <AppointmentBookedInformation appointment={appointmentData} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AppointmentConfirmedScreen;
