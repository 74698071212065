import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";

const getCenterAddress = (params: {
  addresses: DatabaseRow<"center_addresses">[];
  city: string;
  postalCode: string;
  state: string;
  country: string;
}) => {
  const { addresses, city, postalCode, state, country } = params;

  const address =
    addresses.find((centerAddress) => centerAddress.type === "primary")
      ?.address || null;

  const address2 =
    addresses.find((centerAddress) => centerAddress.type === "secondary")
      ?.address || null;

  const addressText = address ? `${address}, ` : "";

  const address2Text = address2 ? `${address2}, ` : "";

  return `${addressText}${address2Text}${city} ${postalCode} ${state}, ${country}`;
};

export default getCenterAddress;
