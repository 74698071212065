import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles<Theme>(({ spacing, palette }) => ({
  menuItemRoot: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: spacing(2),
    paddingRight: spacing(3),
    paddingBottom: spacing(2),
    paddingLeft: spacing(3),
  },
  oldPriceTextRoot: {
    color: palette.error.main,
    textDecoration: "line-through",
  },
}));
