import React, { FC, ReactNode } from "react";

import { useIntl } from "react-intl";
import Box from "../../Unknown/Box";
import Typography from "../../Unknown/Typography";
import messages from "./messages";

import useStyles from "./useStyles";

interface AppointmentSummaryDesktopProps {
  children: ReactNode;
}
const AppointmentSummaryDesktop: FC<AppointmentSummaryDesktopProps> = ({
  children,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <Box p={6} className={classes.root}>
      <Box mb={6}>
        <Typography variant="h2">
          {intl.formatMessage(messages.desktopTitle)}
        </Typography>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default AppointmentSummaryDesktop;
