import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import Dialog from "../../Unknown/Dialog";
import Box from "../../Unknown/Box";
import DialogContent from "../../Unknown/DialogContent";
import DialogActions from "../../Unknown/DialogActions";
import Button from "../../Unknown/Button";
import messages from "./messages";
import { formatInternational } from "../../../common/phoneNumberHelpers";

type AppointmentNotPossibleDialogProps = {
  question: string;
  centerName: string;
  phoneNumber: string;
  isOpen: boolean;
  onClose: () => void;
};

const AppointmentNotPossibleDialog: FC<AppointmentNotPossibleDialogProps> = ({
  question,
  centerName,
  phoneNumber,
  isOpen,
  onClose,
}) => {
  const { desc, contact, closeButton } = messages;

  const formattedNumber = formatInternational({ phoneNumber });

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <FormattedMessage {...desc} values={{ question }} />

        <Box mt={5}>
          <FormattedMessage
            {...contact}
            values={{
              b: (chunks) => <b>{chunks}</b>,
              centerName,
              phoneNumber: formattedNumber,
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage {...closeButton} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppointmentNotPossibleDialog;
