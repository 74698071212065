import { defineMessages } from "react-intl";

export default defineMessages({
  selectSideLabel: {
    id: "Center.SelectCenterServiceSideInput.selectSideLabel",
    defaultMessage: "Select side",
  },
  leftSide: {
    id: "Center.SelectCenterServiceSideInput.leftSide",
    defaultMessage: "Left",
  },
  rightSide: {
    id: "Center.SelectCenterServiceSideInput.rightSide",
    defaultMessage: "Right",
  },
});
