import React, { FC } from "react";
import RadioGroup, { RadioGroupProps } from "../RadioGroup";
import FormControlLabel from "../FormControlLabel";
import Radio from "../Radio";
import Box from "../Box";
import useStyles from "./useStyles";

export type RadioBlockOption = {
  value: string;
  label: string;
  isDisabled?: boolean;
};

type RadioBlocksProps = RadioGroupProps & {
  options: RadioBlockOption[];
};

const RadioBlocks: FC<RadioBlocksProps> = ({ options, ...radioGroupProps }) => {
  const { radioBox, radioLabel } = useStyles();
  return (
    <RadioGroup {...radioGroupProps}>
      {options.map((option) => (
        <Box
          key={option.value}
          className={radioBox}
          py={0.5}
          pl={2}
          mt={2}
          mr={4}
          flexWrap="wrap"
          alignSelf="center"
        >
          <FormControlLabel
            value={option.value}
            control={<Radio />}
            label={option.label}
            disabled={option.isDisabled}
            classes={{
              label: radioLabel,
            }}
          />
        </Box>
      ))}
    </RadioGroup>
  );
};

export default RadioBlocks;
