import React, { FC, useState } from "react";
import Divider from "@mui/material/Divider";
import useTheme from "@mui/system/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { InfoOutlinedIcon } from "../../Unknown/Icons";
import IconButton from "../../Unknown/IconButton";
import Box from "../../Unknown/Box";
import Button, { ButtonProps } from "../../Unknown/Button";
import useStyles from "./useStyles";
import CenterOperatingHoursPreview, {
  CenterOperatingHoursPreviewProps,
} from "../CenterOpeningHoursPreview";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import CenterContactInformationPreview from "../CenterContactInformationPreview";

export type CenterPreviewProps = {
  name: string;
  description: string;
  phone: {
    styled: string;
    link: string;
  };
  email: string;
  fax?: {
    styled: string;
    link: string;
  };
  operatingHours: CenterOperatingHoursPreviewProps["operatingHours"];
  actionButton?: {
    props?: ButtonProps;
    text: string;
  };
};

const CenterPreview: FC<CenterPreviewProps> = ({
  name,
  description,
  phone,
  fax,
  email,
  operatingHours,
  actionButton,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { containerRoot, descriptionRoot } = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => setIsExpanded((prev) => !prev);

  return (
    <Box className={containerRoot}>
      <Box display="flex" alignItems="center">
        <Box>
          <IconButton aria-label="info" onClick={toggleExpanded}>
            <InfoOutlinedIcon />
          </IconButton>
        </Box>
        <Box flexGrow={1} px={4}>
          <Typography sx={{ wordBreak: "break-word" }}>{name}</Typography>
          <Box mt={0.5}>
            <Typography className={descriptionRoot}>{description}</Typography>
          </Box>
        </Box>
        {actionButton && (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Box>
              <Button {...actionButton.props}>{actionButton.text}</Button>
            </Box>
          </Box>
        )}
      </Box>
      {isExpanded && (
        <Box>
          <Box mt={4.5} mb={3}>
            <Divider />
          </Box>
          <Grid
            container
            direction={isSmallScreen ? "row" : undefined}
            rowSpacing={5}
            columnSpacing={4}
          >
            <Grid item xs={12} sm={6}>
              <Box maxWidth={200}>
                <CenterOperatingHoursPreview operatingHours={operatingHours} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CenterContactInformationPreview
                phone={phone}
                fax={fax}
                email={email}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default CenterPreview;
