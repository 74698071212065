import { defineMessages } from "react-intl";

export default defineMessages({
  desc: {
    id: "Appointment.AppointmentNotPossibleDialog.desc",
    defaultMessage:
      'Unfortunately it won\'t be possible for you to receive an MRI scan with Imago because you answered yes to "{question}"',
  },
  contact: {
    id: "Appointment.AppointmentNotPossibleDialog.contact",
    defaultMessage:
      "Please contact {centerName} at <b>{phoneNumber}</b> for more information.",
  },
  closeButton: {
    id: "Appointment.AppointmentNotPossibleDialog.closeButton",
    defaultMessage: "Close",
  },
});
