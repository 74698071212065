import { makeStyles } from "@mui/styles";
import { lighten, Theme } from "@mui/material";

export default makeStyles<Theme>(({ palette }) => ({
  paperRoot: {
    maxHeight: `min(350px, 85vh)`,
  },
  groupRoot: {
    backgroundColor: lighten(palette.primary.main, 0.9),
  },
}));
