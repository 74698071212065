import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "Center.CenterOpeningHoursPreview.title",
    defaultMessage: "Opening hours",
  },
  closed: {
    id: "Center.CenterOpeningHoursPreview.closed",
    defaultMessage: "Closed",
  },
});
