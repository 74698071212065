import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import React, { FC } from "react";
import { LogInFormPersonType } from "@Shape-Digital/kudzu-data/lib/types/actions";
import AppointmentCheckoutPatientForm from "../AppointmentCheckoutPatientForm";
import AppointmentCheckoutGuardianForm from "../AppointmentCheckoutGuardianForm";
import AppointmentCheckoutOtherPersonForm from "../AppointmentCheckoutOtherPersonForm";

type AppointmentCheckoutFormSectionProps = {
  personType: LogInFormPersonType | null;
  onNotPossibleAnswerClick: (question: string) => void;
  onEmailBlur?: (email: string, valid: boolean) => void | Promise<void>;
  isEmailLoading?: boolean;
  isCheckDataShown?: boolean;
  isEmailInUse?: boolean;
  isCenterUser: boolean;
  onSelectCustomer: (customer: DatabaseRow<"customers">) => void;
};

const formComponents = {
  patient: AppointmentCheckoutPatientForm,
  legalGuardian: AppointmentCheckoutGuardianForm,
  otherPerson: AppointmentCheckoutOtherPersonForm,
};

const AppointmentCheckoutFormSection: FC<
  AppointmentCheckoutFormSectionProps
> = ({
  personType,
  onNotPossibleAnswerClick,
  onEmailBlur,
  isEmailLoading,
  isCheckDataShown,
  isEmailInUse,
  isCenterUser,
  onSelectCustomer,
}) => {
  if (!personType) return null;

  const FormComponent = formComponents[personType];

  return (
    <FormComponent
      onNotPossibleAnswerClick={onNotPossibleAnswerClick}
      onEmailBlur={onEmailBlur}
      isEmailLoading={isEmailLoading}
      isCheckDataShown={isCheckDataShown}
      isEmailInUse={isEmailInUse}
      isCenterUser={isCenterUser}
      onSelectCustomer={onSelectCustomer}
    />
  );
};

export default AppointmentCheckoutFormSection;
