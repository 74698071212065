import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = {
    total: formatMessage(messages.total),
  };

  return translations;
};

export default useTranslations;
