import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import { FirebaseAppProvider } from "reactfire";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { ThemeProvider as GlobalThemeProvider } from "@mui/system";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Amplify from "aws-amplify";
import ReactGA from "react-ga4";

import { Provider } from "react-supabase";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import theme from "../../../common/theme";
import firebaseApp from "../../../common/firebaseApp";
import FirebaseSDKProviders from "../FirebaseSDKProviders";
import GlobalStyles from "../GlobalStyles";
import IntlProvider from "../IntlProvider";
import Root from "../Root";
import amplifyConfig from "../../../common/amplifyConfig";
import supabaseClient from "../../../common/supabaseClient";
import Head from "../Head";

Amplify.configure(amplifyConfig);

if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

if (process.env.REACT_APP_GTAG_ID) {
  const tagManagerArgs: TagManagerArgs = {
    gtmId: process.env.REACT_APP_GTAG_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

if (process.env.REACT_APP_GOOGLE_ADS_ID) {
  const tagManagerArgs: TagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_ADS_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

const App: React.FC = () => {
  return (
    <FirebaseAppProvider firebaseApp={firebaseApp}>
      <FirebaseSDKProviders>
        <Provider value={supabaseClient}>
          <GlobalThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <StyledEngineProvider injectFirst>
                <Router basename={process.env.PUBLIC_URL || "/"}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <IntlProvider>
                      <ThemeProvider theme={theme}>
                        <Head />
                        <GlobalStyles />
                        <CssBaseline />
                        <Root />
                      </ThemeProvider>
                    </IntlProvider>
                  </LocalizationProvider>
                </Router>
              </StyledEngineProvider>
            </ThemeProvider>
          </GlobalThemeProvider>
        </Provider>
      </FirebaseSDKProviders>
    </FirebaseAppProvider>
  );
};

export default App;
