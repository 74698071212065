import {
  AppointmentBookingParams,
  AppointmentBookingParamsOffering,
} from "@Shape-Digital/kudzu-data/lib/types/actions";
import { SelectedOfferingSide } from "@Shape-Digital/kudzu-data/lib/types/common";
import subMilliseconds from "date-fns/subMilliseconds";
import formatISOInUTC from "../../../common/formatISOInUTC";

type CenterData = { id: string; timezoneOffsetMilliseconds: number };
type CenterServiceGroupId = "single-offering" | "package";
type CenterService = {
  value: string;
  groupId: CenterServiceGroupId;
  side?: SelectedOfferingSide;
};
type AddOn = { id: string };

const getSelectedCenterServices = (centerServices: CenterService[]) => {
  const initialSelectedCenterServices: {
    selectedPackageIds: AppointmentBookingParams["selectedPackageIds"];
    selectedOfferings: AppointmentBookingParams["selectedOfferings"];
  } = {
    selectedPackageIds: [],
    selectedOfferings: [],
  };

  return centerServices.reduce((acc, centerService) => {
    const { groupId } = centerService;

    if (groupId === "package") {
      const { selectedPackageIds } = acc;
      const { value } = centerService;

      return {
        ...acc,
        selectedPackageIds: [...selectedPackageIds, value],
      };
    }

    if (groupId === "single-offering") {
      const { selectedOfferings } = acc;
      const { value: id, side } = centerService;

      const offering: AppointmentBookingParamsOffering = { id };

      if (side) {
        offering.selectedSide = side;
      }

      return {
        ...acc,
        selectedOfferings: [...selectedOfferings, offering],
      };
    }

    return acc;
  }, initialSelectedCenterServices);
};

const parseAppointment = (params: {
  center: CenterData;
  centerServices: CenterService[];
  addOns: AddOn[];
  dateTime: Date;
  updateAppointmentId?: string;
  overriddenPrice: number | null;
  overriddenDeposit: number | null;
  overriddenTime: string | null;
}): AppointmentBookingParams => {
  const {
    center,
    centerServices,
    addOns,
    dateTime,
    updateAppointmentId,
    overriddenPrice,
    overriddenDeposit,
    overriddenTime,
  } = params;

  const { selectedPackageIds, selectedOfferings } =
    getSelectedCenterServices(centerServices);

  const finalDate = new Date(dateTime);

  if (overriddenTime) {
    const [hours, minutes] = overriddenTime.split(":").map(Number);
    finalDate.setHours(hours);
    finalDate.setMinutes(minutes);
  }

  const selectedAddOnIds = addOns.map((addOn) => addOn.id);

  const dateUTC = subMilliseconds(finalDate, center.timezoneOffsetMilliseconds);

  return {
    centerId: center.id,
    selectedPackageIds,
    selectedOfferings,
    selectedAddOnIds,
    selectedStartISO: formatISOInUTC(dateUTC),
    updateAppointmentId,
    overriddenPrice,
    overriddenDeposit,
  };
};

export default parseAppointment;
