import React, { FC, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import stripePromise from "../../../common/stripePromise";
import StripePaymentElement from "../StripePaymentElement";
import Box from "../../Unknown/Box";
import { DEFAULT_CURRENCY_CODE } from "../../../common/constants";
import CircularProgress from "../../Unknown/CircularProgress";
import PaymentTypeSelection from "../PaymentTypeSelection";
import { PaymentType } from "../../../common/types";

type CustomerAppointmentStripePaymentSectionProps = {
  depositAmount?: number;
  currencyCode?: string;
};

const CustomerAppointmentStripePaymentSection: FC<
  CustomerAppointmentStripePaymentSectionProps
> = ({ depositAmount = 0, currencyCode = DEFAULT_CURRENCY_CODE }) => {
  const { appointmentId } = useParams();

  const [searchParams] = useSearchParams();

  const clientSecret = searchParams.get("clientSecret");

  const [paymentType, setPaymentType] = useState(PaymentType.TotalAmount);

  const [isChangePaymentTypeLoading, setIsChangePaymentTypeLoading] =
    useState(false);

  const [isError, setIsError] = useState(false);

  if (isChangePaymentTypeLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        mt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return null;
  }

  return (
    <>
      <Box mb={5}>
        <PaymentTypeSelection
          depositAmount={depositAmount}
          currencyCode={currencyCode}
          paymentType={paymentType}
          appointmentId={appointmentId}
          setIsError={setIsError}
          setIsLoading={setIsChangePaymentTypeLoading}
          setPaymentType={setPaymentType}
        />
      </Box>
      {!!clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <StripePaymentElement />
        </Elements>
      )}
    </>
  );
};

export default CustomerAppointmentStripePaymentSection;
