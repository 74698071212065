import { startOfMonth, endOfMonth, eachDayOfInterval, format } from "date-fns";
import addMinutes from "date-fns/addMinutes";

export const roundDateMinutes = (date: Date, roundTo: number) => {
  const ms = 1000 * 60 * roundTo;

  return new Date(Math.ceil(date.getTime() / ms) * ms);
};

export const getUtcNow = (): Date => {
  const now = new Date();

  const utcNow = addMinutes(now, now.getTimezoneOffset());

  return utcNow;
};

export const getMonthDayList = (date?: Date | null): number[] => {
  const today = new Date();

  const start = startOfMonth(date || today);
  const end = endOfMonth(date || today);
  const daysArray = eachDayOfInterval({ start, end });

  return daysArray.map((day) => Number(format(day, "d")));
};
