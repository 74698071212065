import Big from "big.js";

const maxCircularValue = 100;

const getCountdownTimerProps = ({
  timeToEnd,
  checkoutDurationSeconds,
  warningDurationSeconds,
}: {
  timeToEnd: number;
  checkoutDurationSeconds: number;
  warningDurationSeconds: number;
}) => {
  const minutes = Big(timeToEnd)
    .div(60)
    .mod(60)
    .round(0, Big.roundDown)
    .toString()
    .padStart(2, "0");

  const seconds = Big(timeToEnd).mod(60).round().toString().padStart(2, "0");

  const text = `${minutes}:${seconds}`;

  const checkoutValue = Big(timeToEnd)
    .div(checkoutDurationSeconds)
    .times(maxCircularValue)
    .toNumber();

  const warningValue = Big(timeToEnd)
    .div(warningDurationSeconds)
    .times(maxCircularValue)
    .toNumber();

  return { text, checkoutValue, warningValue };
};
export default getCountdownTimerProps;
