import React, { FC } from "react";
import { useIntl } from "react-intl";

import startOfDay from "date-fns/startOfDay";
import addMinutes from "date-fns/addMinutes";
import Typography from "../../Unknown/Typography";
import Box from "../../Unknown/Box";

import useStyles from "./useStyles";
import objectKeys from "../../../common/objectKeys";
import useTranslations, {
  CenterOpeningHoursPreviewTranslations,
} from "./useTranslations";

type OperationHoursDayOfWeek = "1" | "2" | "3" | "4" | "5" | "6" | "7";
type OperatingHours = { fromMinutesAt: number; toMinutesAt: number };

export type CenterOperatingHours = Partial<
  Record<OperationHoursDayOfWeek, OperatingHours>
>;

export type CenterOperatingHoursPreviewProps = {
  operatingHours: CenterOperatingHours;
};

const dayNames: Record<
  OperationHoursDayOfWeek,
  keyof CenterOpeningHoursPreviewTranslations
> = {
  "1": "shortMonday",
  "2": "shortTuesday",
  "3": "shortWednesday",
  "4": "shortThursday",
  "5": "shortFriday",
  "6": "shortSaturday",
  "7": "shortSunday",
};

const startOfDayNow = startOfDay(Date.now());

const CenterOperatingHoursPreview: FC<CenterOperatingHoursPreviewProps> = ({
  operatingHours,
}) => {
  const { formatTime } = useIntl();
  const { titleRoot } = useStyles();
  const translations = useTranslations();

  const formatMinutes = (minutes: number) => {
    return formatTime(addMinutes(startOfDayNow, minutes)).replace(":00", "");
  };

  if (!operatingHours) return null;

  return (
    <>
      <Typography className={titleRoot} mb={2}>
        {translations.title}
      </Typography>
      {objectKeys(dayNames).map((key) => {
        const dayData = operatingHours[key];

        let text = "";

        if (dayData) {
          const { fromMinutesAt, toMinutesAt } = dayData;

          const fromText = formatMinutes(fromMinutesAt);
          const toText = formatMinutes(toMinutesAt);

          text = `${fromText} - ${toText}`;
        } else {
          text = translations.closed;
        }

        return (
          <Box key={key} display="flex">
            <Box width="50%">
              <Typography variant="caption">
                {translations[dayNames[key]]}
              </Typography>
            </Box>
            <Box width="50%" minWidth={120}>
              <Typography variant="caption">{text} </Typography>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default CenterOperatingHoursPreview;
