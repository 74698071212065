import { addSeconds } from "date-fns";
import addMilliseconds from "date-fns/addMilliseconds";
import addMinutes from "date-fns/addMinutes";
import addYears from "date-fns/addYears";
import { roundDateMinutes } from "../../../common/dateHelpers";
import {
  MAX_BOOK_APPOINTMENT_YEARS,
  ROUND_BOOK_APPOINTMENT_START_TO_NEAREST_MINUTES,
} from "./constants";

const getDateLimits = (params: {
  timezoneOffsetMillis: number;
  minTimeBeforeBookingSeconds: number;
}) => {
  const { timezoneOffsetMillis, minTimeBeforeBookingSeconds } = params;

  const nowClientTimezone = new Date();
  const clientOffsetMinutes = nowClientTimezone.getTimezoneOffset();

  const nowUtc = addMinutes(nowClientTimezone, clientOffsetMinutes);

  const nowCenterTimezone = addMilliseconds(nowUtc, timezoneOffsetMillis);

  const minDateTimeUTC = roundDateMinutes(
    addSeconds(nowCenterTimezone, minTimeBeforeBookingSeconds),
    ROUND_BOOK_APPOINTMENT_START_TO_NEAREST_MINUTES,
  );

  const maxDateTimeUTC = addYears(minDateTimeUTC, MAX_BOOK_APPOINTMENT_YEARS);

  const minCenterTimezone = addMilliseconds(
    minDateTimeUTC,
    timezoneOffsetMillis,
  );

  const maxCenterTimezone = addMilliseconds(
    maxDateTimeUTC,
    timezoneOffsetMillis,
  );

  return {
    minDateTimeUTC,
    maxDateTimeUTC,
    minCenterTimezone,
    maxCenterTimezone,
  };
};

export default getDateLimits;
