import { defineMessages } from "react-intl";

export default defineMessages({
  buttonDesktopSubmitText: {
    id: "Appointment.AppointmentBookingScreen.buttonDesktopSubmitText",
    defaultMessage: "Proceed to checkout",
  },
  buttonMobileSubmitText: {
    id: "Appointment.AppointmentBookingScreen.buttonMobileSubmitText",
    defaultMessage: "Checkout",
  },
  submitDisabledError: {
    id: "Appointment.AppointmentBookingScreen.submitDisabledError",
    defaultMessage: "Please fill required data before submitting",
  },
  timeSlotNotAvailableError: {
    id: "Appointment.AppointmentBookingScreen.timeSlotNotAvailableError",
    defaultMessage:
      "Oops this time slot is no longer available. Please choose another.",
  },
});
