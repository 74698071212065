/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useUser } from "reactfire";
import { GetCustomerCheckoutFormDataResponse } from "@Shape-Digital/kudzu-data/lib/types/actions";
import commonMessages from "../../../common/messages";
import useDataState from "../../../hooks/useDataState";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";

const useCustomerFormData = () => {
  const { formatMessage } = useIntl();

  const firebaseUser = useUser();

  const getCustomerCheckoutFormData = useFirebaseAppFunction(
    "getCustomerCheckoutFormData",
  );

  const { dataState, updateDataState } =
    useDataState<GetCustomerCheckoutFormDataResponse | null>();

  const updateSupabaseCustomer = useCallback(async () => {
    try {
      updateDataState({ status: "loading" });

      if (!firebaseUser?.data) {
        updateDataState({ status: "success", data: null });
        return;
      }

      const { data } = await getCustomerCheckoutFormData();

      updateDataState({ status: "success", data });
    } catch (err) {
      updateDataState({
        status: "error",
        error: formatMessage(commonMessages.defaultError),
      });
    }
  }, [
    updateDataState,
    firebaseUser,
    getCustomerCheckoutFormData,
    formatMessage,
  ]);

  useEffect(() => {
    updateSupabaseCustomer();
  }, [updateSupabaseCustomer]);

  return dataState;
};

export default useCustomerFormData;
