import { defineMessages } from "react-intl";

export default defineMessages({
  locationTitle: {
    id: "Appointment.AppointmentBookedInformation.locationTitle",
    defaultMessage: "Location",
  },
  dateTitle: {
    id: "Appointment.AppointmentBookedInformation.dateTitle",
    defaultMessage: "Date and time",
  },
  dateText: {
    id: "Appointment.AppointmentBookedInformation.dateText",
    defaultMessage: "{date} at {time}",
  },
  patientTitle: {
    id: "Appointment.AppointmentBookedInformation.patientTitle",
    defaultMessage: "Patient",
  },
  studiesTitle: {
    id: "Appointment.AppointmentBookedInformation.studiesTitle",
    defaultMessage: "Studies/packages",
  },
  sideLeft: {
    id: "Appointment.AppointmentBookedInformation.sideLeft",
    defaultMessage: "left",
  },
  sideRight: {
    id: "Appointment.AppointmentBookedInformation.sideRight",
    defaultMessage: "right",
  },
  labelFax: {
    id: "Appointment.AppointmentBookedInformation.labelFax",
    defaultMessage: "Fax",
  },
  labelPhone: {
    id: "Appointment.AppointmentBookedInformation.labelPhone",
    defaultMessage: "Phone",
  },
});
