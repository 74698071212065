import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = ({ reservedMinutes }: { reservedMinutes: number }) => {
  const { formatMessage } = useIntl();

  const translations = {
    title: formatMessage(messages.title, { reservedMinutes }),
  };

  return { translations };
};

export default useTranslations;
