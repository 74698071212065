import { useState } from "react";

const useAppointmentNotPossibleDialog = () => {
  const [isNotPossibleDialogOpen, setIsNotPossibleDialogOpen] = useState(false);

  const [notPossibleQuestion, setNotPossibleQuestion] = useState("");

  const onNotPossibleAnswerClick = (question: string) => {
    setIsNotPossibleDialogOpen(true);
    setNotPossibleQuestion(question);
  };

  const onNotPossibleClose = () => {
    setNotPossibleQuestion("");
    setIsNotPossibleDialogOpen(false);
  };

  return {
    isNotPossibleDialogOpen,
    notPossibleQuestion,
    onNotPossibleAnswerClick,
    onNotPossibleClose,
  };
};

export default useAppointmentNotPossibleDialog;
