import React, { FC, ReactNode } from "react";
import useDeviceType from "../../../hooks/useDeviceType";
import AppointmentLayoutMobile from "../AppointmentLayoutMobile";
import AppointmentLayoutDesktop from "../AppointmentLayoutDesktop";
import { AppointmentSummaryDetailsProps } from "../AppointmentSummaryDetails";

type AppointmentLayoutProps = AppointmentSummaryDetailsProps & {
  children?: ReactNode;
};

const AppointmentLayout: FC<AppointmentLayoutProps> = ({
  children,
  ...summaryDetailsProps
}) => {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return (
      <AppointmentLayoutMobile summaryDetailsProps={summaryDetailsProps}>
        {children}
      </AppointmentLayoutMobile>
    );
  }

  return (
    <AppointmentLayoutDesktop summaryDetailsProps={summaryDetailsProps}>
      {children}
    </AppointmentLayoutDesktop>
  );
};

export default AppointmentLayout;
