import React, { FC } from "react";

import Typography from "../../Unknown/Typography";
import MenuItem from "../../Unknown/MenuItem";
import useStyles from "./useStyles";
import Grid from "../../Unknown/Grid";

export type CenterServiceMenuItemProps = {
  value: string;
  label: string;
  price: string;
  description: string;
  discountPrice?: string;
  isDisabled?: boolean;
  isDiscountShown?: boolean;
  onClick: () => void;
};

const CenterServiceMenuItem: FC<CenterServiceMenuItemProps> = ({
  value,
  label,
  price,
  description,
  discountPrice,
  isDisabled,
  isDiscountShown,
  onClick,
}) => {
  const { menuItemRoot, oldPriceTextRoot } = useStyles();
  return (
    <MenuItem
      value={value}
      disabled={isDisabled}
      className={menuItemRoot}
      onClick={onClick}
    >
      <Grid container direction="row" flexWrap="nowrap" spacing={2}>
        <Grid item container flex={1} direction="column">
          <Grid item>
            <Typography
              variant="body1"
              whiteSpace="normal"
              sx={{ wordBreak: "break-word" }}
            >
              {label}
            </Typography>
          </Grid>
          {description && (
            <Grid item>
              <Typography
                variant="caption"
                whiteSpace="normal"
                sx={{ wordBreak: "break-word" }}
              >
                {description}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          container
          width="fit-content"
          direction="column"
          alignItems="flex-end"
          justifyContent="center"
        >
          {isDiscountShown && discountPrice ? (
            <>
              <Grid item>
                <Typography className={oldPriceTextRoot}>{price}</Typography>
              </Grid>
              <Grid item>
                <Typography>{discountPrice}</Typography>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Typography>{price}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </MenuItem>
  );
};

export default CenterServiceMenuItem;
