import React, { FC, useEffect } from "react";
import { useAppointmentBookingContext } from "../../Appointment/AppointmentBookingContext";
import Box from "../../Unknown/Box";
import CircularProgress from "../../Unknown/CircularProgress";
import CenterPreview from "../CenterPreview";
import useCenterPreviewPropsList from "./useCenterPreviewPropsList";
import Grid from "../../Unknown/Grid";

const SelectCenterForm: FC = () => {
  const { centers } = useAppointmentBookingContext();

  const { status, error } = centers;

  useEffect(() => {
    if (status === "error") {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  }, [error, status]);

  const { centerPreviewPropsList } = useCenterPreviewPropsList();

  if (status !== "success") {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        mt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={2} wrap="nowrap" direction="column">
        {centerPreviewPropsList.map((centerPreviewProps) => (
          <Grid item flexGrow={1} key={centerPreviewProps.id}>
            <CenterPreview {...centerPreviewProps} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default SelectCenterForm;
