import { alpha, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette, shape }) => {
  return {
    calendarRoot: {
      borderWidth: 1,
      borderColor: alpha(palette.common.black, 0.25),
      borderStyle: "solid",
      borderRadius: shape.borderRadius,
    },
  };
});

export default useStyles;
