import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles<Theme>((theme) => ({
  button: {
    justifyContent: "start",
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
    },
    color: theme.palette.action.active,
    border: "1px dashed",
  },
  icon: {
    marginRight: theme.spacing(3),
    "& *:nth-of-type(1)": {
      fontSize: "24px",
    },
  },
}));
