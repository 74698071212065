import { defineMessages } from "react-intl";

export default defineMessages({
  mobileTitle: {
    id: "Appointment.AppointmentSummaryDetails.mobileTitle",
    defaultMessage: "Appointment Summary",
  },
  location: {
    id: "Appointment.AppointmentSummaryDetails.location",
    defaultMessage: "At {location}",
  },
  dateTime: {
    id: "Appointment.AppointmentSummaryDetails.dateTime",
    defaultMessage: "On {date} at {time}",
  },
  discount: {
    id: "Appointment.AppointmentSummaryDetails.discount",
    defaultMessage: "Discount",
  },
  total: {
    id: "Appointment.AppointmentSummaryDetails.total",
    defaultMessage: "Total",
  },
  depositAmount: {
    id: "Appointment.AppointmentSummaryDetails.depositAmount",
    defaultMessage: "Due today",
  },
  sideLeft: {
    id: "Appointment.AppointmentSummaryDetails.sideLeft",
    defaultMessage: "left",
  },
  sideRight: {
    id: "Appointment.AppointmentSummaryDetails.sideRight",
    defaultMessage: "right",
  },
  priceOverrideLabel: {
    id: "Appointment.AppointmentSummaryDetails.priceOverrideLabel",
    defaultMessage: "Override appointment price",
  },
  depositOverrideLabel: {
    id: "Appointment.AppointmentSummaryDetails.depositOverrideLabel",
    defaultMessage: "Override deposit amount",
  },
  timeOverrideLabel: {
    id: "Appointment.AppointmentSummaryDetails.timeOverrideLabel",
    defaultMessage: "Override Appointment Start Time",
  },
  priceShouldBeLower: {
    id: "Appointment.AppointmentSummaryDetails.priceShouldBeLower",
    defaultMessage: "The overridden price must be lower than the actual price.",
  },
  depositShouldBeLower: {
    id: "Appointment.AppointmentSummaryDetails.depositShouldBeLower",
    defaultMessage: "Please provide a valid deposit amount.",
  },
});
