import React, { FC } from "react";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import CircularProgress from "../CircularProgress";

export interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean;
}

const Button: FC<ButtonProps> = (props) => {
  const { isLoading, children, disabled, startIcon, ...buttonProps } = props;

  const innerStartIcon =
    !startIcon && isLoading ? (
      <CircularProgress size={20} color="inherit" />
    ) : (
      startIcon
    );

  const innerDisabled = isLoading || disabled;

  return (
    <MuiButton
      startIcon={innerStartIcon}
      disabled={innerDisabled}
      {...buttonProps}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
