import React, { FC, Dispatch, SetStateAction } from "react";
import BooleanRadioGroup from "../../Unknown/BooleanRadioGroup";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import useTranslations from "./useTranslations";

type AppointmentCheckoutFormChoiceProps = {
  isPatient: boolean | null;
  setIsPatient: Dispatch<SetStateAction<boolean | null>>;
  isGuardian: boolean | null;
  setIsGuardian: Dispatch<SetStateAction<boolean | null>>;
  isDisabled?: boolean;
};

const AppointmentCheckoutFormChoice: FC<AppointmentCheckoutFormChoiceProps> = ({
  isPatient,
  setIsPatient,
  isGuardian,
  setIsGuardian,
  isDisabled,
}) => {
  const translations = useTranslations();

  return (
    <Grid container spacing={8} direction="column">
      <Grid item container spacing={4} direction="column">
        <Grid item>
          <Typography variant="h3">{translations.isPatientLabel}</Typography>
        </Grid>
        <Grid item>
          <BooleanRadioGroup
            value={isPatient}
            setValue={setIsPatient}
            isDisabled={isDisabled}
          />
        </Grid>
      </Grid>
      {isPatient === false && (
        <Grid item container spacing={4} direction="column">
          <Grid item>
            <Typography variant="h3">{translations.isGuardianLabel}</Typography>
          </Grid>
          <Grid item>
            <BooleanRadioGroup
              value={isGuardian}
              setValue={setIsGuardian}
              isDisabled={isDisabled}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AppointmentCheckoutFormChoice;
