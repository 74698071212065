import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";

const getCustomerLabel = ({
  selectedId,
  customers,
}: {
  selectedId: string;
  customers: DatabaseRow<"customers">[];
}): string => {
  const customer = customers.find(({ id }) => id === selectedId);

  const customerName = customer
    ? `${customer.first_name} ${customer.last_name}`
    : "";

  return customerName;
};

export default getCustomerLabel;
