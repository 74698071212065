import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "Appointment.AppointmentDialogRequestTime.title",
    defaultMessage: "Your reserved date and time are about to expire",
  },
  desc: {
    id: "Appointment.AppointmentDialogRequestTime.desc",
    defaultMessage: "Do you need more time to finish booking your appointment?",
  },
  cancelButtonTitle: {
    id: "Appointment.AppointmentDialogRequestTime.cancelButtonTitle",
    defaultMessage: "No",
  },
  confirmButtonTitle: {
    id: "Appointment.AppointmentDialogRequestTime.confirmButtonTitle",
    defaultMessage: "Yes",
  },
});
