import { useCallback, useEffect } from "react";
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import useDataState from "./useDataState";
import supabaseClient from "../common/supabaseClient";

type SupabaseSystemConfig = DatabaseRow<"system_configs">;

export type SystemConfig = {
  id: string;
  discountAmount: number;
  defaultLanguageCode: string;
  defaultCurrencyCode: string;
  defaultOfferingDurationMinutes: number;
  appointmentCheckoutDurationSeconds: number;
  appointmentCheckoutMaxRepeatCount: number;
  minimumTimeBeforeBookingSeconds: number;
};

const parseSystemConfig = (supabaseSystemConfig: SupabaseSystemConfig) => ({
  id: supabaseSystemConfig.id,
  discountAmount: supabaseSystemConfig.discount_amount,
  defaultLanguageCode: supabaseSystemConfig.default_language_code,
  defaultCurrencyCode: supabaseSystemConfig.default_currency_code,
  defaultOfferingDurationMinutes:
    supabaseSystemConfig.default_offering_duration_minutes,
  appointmentCheckoutDurationSeconds:
    supabaseSystemConfig.appointment_checkout_duration_seconds,
  appointmentCheckoutMaxRepeatCount:
    supabaseSystemConfig.appointment_checkout_max_repeat_count,
  minimumTimeBeforeBookingSeconds:
    supabaseSystemConfig.minimum_time_before_booking_seconds,
});

export const getSystemConfig = async () => {
  const response = await supabaseClient
    .from<DatabaseRow<"system_configs">>("system_configs")
    .select()
    .single();

  if (response.error) {
    throw new Error(response.error.message);
  }

  const parsedSystemConfig = parseSystemConfig(response.data);
  return parsedSystemConfig;
};

const useSystemConfig = () => {
  const { dataState, updateDataState } = useDataState<SystemConfig>();

  const sendRequest = useCallback(async () => {
    try {
      updateDataState({ status: "loading" });

      const data = await getSystemConfig();
      updateDataState({ status: "success", data });
    } catch (err) {
      updateDataState({ status: "error", error: (err as Error).message });
    }
  }, [updateDataState]);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);

  return dataState;
};

export default useSystemConfig;
