import differenceInSeconds from "date-fns/differenceInSeconds";
import { useEffect, useState } from "react";

const delay = 1000;

const calcTime = (expiresAt: number) =>
  Math.max(differenceInSeconds(expiresAt, Date.now()), 0);

const useCountdownTimer = (params: { expiresAt: number }) => {
  const { expiresAt } = params;

  const [time, setTime] = useState(calcTime(expiresAt));

  useEffect(() => {
    setTime(calcTime(expiresAt));
  }, [expiresAt]);

  useEffect(() => {
    const timeout = setTimeout(() => setTime(() => calcTime(expiresAt)), delay);
    return () => clearTimeout(timeout);
  });

  return { time };
};

export default useCountdownTimer;
