import React, { FC } from "react";
import CircularProgress, { CircularProgressProps } from "../CircularProgress";
import Box from "../Box";
import Typography from "../Typography";

export type CountdownTimerProps = {
  text?: string;
} & Omit<CircularProgressProps, "variant">;

const CountdownTimer: FC<CountdownTimerProps> = ({
  text,
  ...circleProgressProps
}) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        size={60}
        {...circleProgressProps}
      />
      {text && (
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div">
            {text}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CountdownTimer;
