import React, { FC, useMemo, ChangeEvent, useCallback } from "react";
import { useIntl } from "react-intl";
import { useAppointmentBookingContext } from "../../Appointment/AppointmentBookingContext";
import messages from "./messages";
import CheckboxGroup from "../../Unknown/CheckboxGroup";
import parseAddOnToCheckboxGroupOption from "./parseAddOnToCheckboxGroupOption";

const SelectAddOnsForm: FC = () => {
  const intl = useIntl();

  const {
    selectedCenter,
    addOns,
    selectedAddOns,
    onAddOnsChange,
    isSubmitLoading,
  } = useAppointmentBookingContext();

  const options = useMemo(() => {
    const { data } = addOns;

    if (!selectedCenter || !data?.length) return [];

    const { defaultCurrencyCode } = selectedCenter;

    return data.map((addOn) => {
      const checked = selectedAddOns.some(
        (selected) => selected.id === addOn.id,
      );
      return parseAddOnToCheckboxGroupOption(
        addOn,
        defaultCurrencyCode,
        checked,
      );
    });
  }, [addOns, selectedCenter, selectedAddOns]);

  const onChecked = useCallback(
    (checkedValue?: string): void => {
      const { data } = addOns;

      if (!selectedCenter || !data?.length) return;

      if (!checkedValue) return;

      const selectedAddOn = data.find((addOn) => addOn.id === checkedValue);

      if (!selectedAddOn) return;

      onAddOnsChange((prev) => [...prev, selectedAddOn]);
    },
    [addOns, onAddOnsChange, selectedCenter],
  );

  const onUnchecked = useCallback(
    (uncheckedValue?: string) => {
      if (!uncheckedValue) return;

      const indexOfValue = selectedAddOns.findIndex(
        (addOn) => addOn.id === uncheckedValue,
      );

      const isValueInSelectedItems = indexOfValue !== -1;

      if (!isValueInSelectedItems) return;

      onAddOnsChange((prev) => {
        const newAddOns = [...prev];
        newAddOns.splice(indexOfValue, 1);
        return newAddOns;
      });
    },
    [onAddOnsChange, selectedAddOns],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      const actionFunc = checked ? onChecked : onUnchecked;
      actionFunc(e?.target?.value);
    },
    [onChecked, onUnchecked],
  );

  if (!selectedCenter || !addOns.data?.length) return null;

  return (
    <CheckboxGroup
      title={intl.formatMessage(messages.title)}
      options={options}
      onChange={onChange}
      disabled={isSubmitLoading}
    />
  );
};

export default SelectAddOnsForm;
