import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles<Theme>((theme) => ({
  radioBox: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.grey[400],
    borderRadius: 4,
    width: "fit-content",
  },
  radioLabel: {
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
}));
