import { SupabaseClient } from "@supabase/supabase-js";
import {
  SUPABASE_TOKEN,
  SUPABASE_CENTER_USER_TOKEN_KEY,
} from "../common/constants";
import supabaseClient from "../common/supabaseClient";

const { REACT_APP_SUPABASE_KEY } = process.env;

const useSupabase = (): SupabaseClient => supabaseClient;

export default useSupabase;

export const setSupabaseAccessToken = (token: string): void => {
  supabaseClient.auth.setAuth(token);
  localStorage.setItem(SUPABASE_TOKEN, token);
};

export const setCenterUserSupabaseToken = (token: string): void => {
  supabaseClient.auth.setAuth(token);
  sessionStorage.setItem(SUPABASE_CENTER_USER_TOKEN_KEY, token);
};

export const initializeSupabaseAccessToken = () => {
  const supabaseToken =
    sessionStorage.getItem(SUPABASE_CENTER_USER_TOKEN_KEY) ||
    localStorage.getItem(SUPABASE_TOKEN) ||
    REACT_APP_SUPABASE_KEY ||
    "";
  setSupabaseAccessToken(supabaseToken);
};
