import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = ({ email }: { email: string }) => {
  const { formatMessage } = useIntl();

  const translations = {
    titleText: formatMessage(messages.titleText),
    incompleteTitleText: formatMessage(messages.incompleteTitleText),
    descriptionText: formatMessage(messages.descriptionText, {
      email,
    }),
    confirmationRequired: formatMessage(messages.confirmationRequired),
    incompleteWarningMessage: formatMessage(messages.incompleteWarningMessage),
  };

  return { translations };
};

export default useTranslations;
