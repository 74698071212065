import { defineMessages } from "react-intl";

export default defineMessages({
  titleText: {
    id: "Appointment.AppointmentConfirmedScreen.titleText",
    defaultMessage: "Appointment confirmed!",
  },
  incompleteTitleText: {
    id: "Appointment.AppointmentConfirmedScreen.incompleteTitleText",
    defaultMessage: "Appointment scheduled successfully!",
  },
  descriptionText: {
    id: "Appointment.AppointmentConfirmedScreen.descriptionText",
    defaultMessage: "A confirmation email has been sent to {email}",
  },
  confirmationRequired: {
    id: "Appointment.AppointmentConfirmedScreen.confirmationRequired",
    defaultMessage: "Confirmation required",
  },
  incompleteWarningMessage: {
    id: "Appointment.AppointmentConfirmedScreen.incompleteWarningMessage",
    defaultMessage:
      "This appointment needs to be confirmed by an Admin or Technologist before it is effective and the confirmation email is sent out to the patient.",
  },
});
