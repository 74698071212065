import { defineMessages } from "react-intl";

export default defineMessages({
  yourInformationHeader: {
    id: "Appointment.AppointmentCheckoutPatientForm.yourInformationHeader",
    defaultMessage: "Your information",
  },
  emailLabel: {
    id: "Appointment.AppointmentCheckoutPatientForm.emailLabel",
    defaultMessage: "Email address",
  },
  phoneNumberLabel: {
    id: "Appointment.AppointmentCheckoutPatientForm.phoneNumberLabel",
    defaultMessage: "Mobile phone number",
  },
  firstNameLabel: {
    id: "Appointment.AppointmentCheckoutPatientForm.firstNameLabel",
    defaultMessage: "First name",
  },
  lastNameLabel: {
    id: "Appointment.AppointmentCheckoutPatientForm.lastNameLabel",
    defaultMessage: "Last name",
  },
  dateOfBirthLabel: {
    id: "Appointment.AppointmentCheckoutPatientForm.dateOfBirthLabel",
    defaultMessage: "Date of birth",
  },
  dateOfBirthPlaceholder: {
    id: "Appointment.AppointmentCheckoutPatientForm.dateOfBirthPlaceholder",
    defaultMessage: "mm/dd/yyyy",
  },
  pregnantHeader: {
    id: "Appointment.AppointmentCheckoutPatientForm.pregnantHeader",
    defaultMessage: "Are you pregnant?",
  },
  answerYes: {
    id: "Appointment.AppointmentCheckoutPatientForm.answerYes",
    defaultMessage: "Yes",
  },
  answerNo: {
    id: "Appointment.AppointmentCheckoutPatientForm.answerNo",
    defaultMessage: "No",
  },
  wearingDeviceHeader: {
    id: "Appointment.AppointmentCheckoutPatientForm.wearingDeviceHeader",
    defaultMessage: "Are you wearing a pacemaker or aneurysm clip?",
  },
  checkUpToDateData: {
    id: "Appointment.AppointmentCheckoutPatientForm.checkUpToDateData",
    defaultMessage: "Please check your information is correct and up to date",
  },
  mobilePhoneNumberHelper: {
    id: "Appointment.AppointmentCheckoutPatientForm.mobilePhoneNumberHelper",
    defaultMessage: "Enter only numbers, including the area code",
  },
  dateOfBirthHelper: {
    id: "Appointment.AppointmentCheckoutPatientForm.dateOfBirthHelper",
    defaultMessage: "Use format mm/dd/yyyy",
  },
  authErrorMessage: {
    id: "Appointment.AppointmentCheckoutPatientForm.authErrorMessage",
    defaultMessage:
      "This email is already in use, please confirm authorization or choose another address",
  },
});
