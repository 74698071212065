import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = {
    title: formatMessage(messages.title),
    labelFax: formatMessage(messages.labelFax),
    labelPhone: formatMessage(messages.labelPhone),
  };

  return translations;
};

export default useTranslations;
