import React, { FC, ChangeEvent } from "react";
import { useIntl } from "react-intl";
import FormControl from "../FormControl";
import RadioGroup from "../RadioGroup";
import FormControlLabel from "../FormControlLabel";
import Radio from "../Radio";
import messages from "./messages";

type BooleanRadio = "yes" | "no" | null;

const getRadioValue = (value: boolean | null): BooleanRadio => {
  switch (value) {
    case true:
      return "yes";
    case false:
      return "no";
    case null:
      return null;
    default:
      throw new Error("Value is not allowed");
  }
};

const getBooleanFromRadio = (value: BooleanRadio): boolean | null => {
  switch (value) {
    case "yes":
      return true;
    case "no":
      return false;
    case null:
      return null;
    default:
      throw new Error("Value is not allowed");
  }
};

type BooleanRadioGroupProps = {
  value: boolean | null;
  setValue: (value: boolean | null) => void;
  isDisabled?: boolean;
};

const BooleanRadioGroup: FC<BooleanRadioGroupProps> = ({
  value,
  setValue,
  isDisabled,
}) => {
  const { formatMessage } = useIntl();

  const radioOptions = [
    { value: "yes" as BooleanRadio, label: formatMessage(messages.answerYes) },
    { value: "no" as BooleanRadio, label: formatMessage(messages.answerNo) },
  ];

  const radioValue = getRadioValue(value);

  const onRadioChange = (
    _event: ChangeEvent<HTMLInputElement>,
    newValue: string,
  ) => {
    setValue(getBooleanFromRadio(newValue as BooleanRadio));
  };

  return (
    <FormControl>
      <RadioGroup row onChange={onRadioChange} value={radioValue}>
        {radioOptions.map((radioOption) => (
          <FormControlLabel
            key={radioOption.value}
            value={radioOption.value}
            label={radioOption.label}
            control={<Radio />}
            disabled={isDisabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default BooleanRadioGroup;
