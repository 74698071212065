import { defineMessages } from "react-intl";

export default defineMessages({
  buttonDesktopSubmitText: {
    id: "Appointment.AppointmentCheckoutScreen.buttonDesktopSubmitText",
    defaultMessage: "Confirm and pay",
  },
  buttonMobileSubmitText: {
    id: "Appointment.AppointmentCheckoutScreen.buttonMobileSubmitText",
    defaultMessage: "Pay now",
  },
  saveAppointment: {
    id: "Appointment.AppointmentCheckoutScreen.saveAppointment",
    defaultMessage: "Save appointment",
  },
  emailCanNotBeUsed: {
    id: "Appointment.AppointmentCheckoutScreen.emailCanNotBeUsed",
    defaultMessage: "The provided email cannot be used",
  },
});
