import React, { FC } from "react";

import Typography from "../../Unknown/Typography";
import Grid from "../../Unknown/Grid";
import useStyles from "./useStyles";
import useTranslations from "./useTranslations";

export type CenterContactInformationPreviewProps = {
  phone?: {
    styled: string;
    link: string;
  };
  fax?: {
    styled: string;
    link: string;
  };
  email?: string;
};

const CenterContactInformationPreview: FC<
  CenterContactInformationPreviewProps
> = ({ fax, phone, email }) => {
  const { titleRoot, linkRoot } = useStyles();
  const { title, labelFax, labelPhone } = useTranslations();

  return (
    <>
      <Typography className={titleRoot} mb={2}>
        {title}
      </Typography>
      <Grid container spacing={0.5} direction="column">
        {phone && (
          <Grid item>
            <Typography variant="caption">
              {labelPhone}
              {": "}
            </Typography>
            <a href={phone.link} className={linkRoot}>
              <Typography
                variant="caption"
                color="primary.main"
                sx={{ textDecoration: "none" }}
              >
                {phone.styled}
              </Typography>
            </a>
          </Grid>
        )}
        {fax && (
          <Grid item>
            <Typography variant="caption">
              {labelFax}
              {": "}
            </Typography>
            <a href={fax.link} className={linkRoot}>
              <Typography
                variant="caption"
                color="primary.main"
                sx={{ textDecoration: "none" }}
              >
                {fax.styled}
              </Typography>
            </a>
          </Grid>
        )}
        {email && (
          <Grid item>
            <a href={`mailto:${email}`} className={linkRoot}>
              <Typography
                variant="caption"
                color="primary.main"
                sx={{ textDecoration: "none" }}
              >
                {email}
              </Typography>
            </a>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CenterContactInformationPreview;
