import React, { FC, ReactNode, useState } from "react";

import useStyles from "./useStyles";
import Box from "../../Unknown/Box";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import SwipeableDrawer from "../../Unknown/SwipeableDrawer";
import useTranslations from "./useTranslations";
import Button from "../../Unknown/Button";

interface AppointmentSummaryMobileProps {
  summary: string;
  onSubmitClick?: () => void | Promise<void>;
  isSubmitButtonDisabled?: boolean;
  isSubmitLoading?: boolean;
  buttonSubmitText?: string;
  children: ReactNode;
}

const maxDrawerHeightPercent = 85;
const drawerBleeding = 60;

const AppointmentSummaryMobile: FC<AppointmentSummaryMobileProps> = ({
  summary,
  onSubmitClick,
  isSubmitButtonDisabled,
  isSubmitLoading,
  buttonSubmitText,
  children,
}) => {
  const { total } = useTranslations();

  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const { pullerBoxRoot, headerBoxRoot, checkoutButtonRoot } = useStyles({
    isOpen,
  });

  const isButtonShown = onSubmitClick && buttonSubmitText;

  return (
    <SwipeableDrawer
      open={isOpen}
      anchor="bottom"
      onClose={onClose}
      onOpen={onOpen}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={false}
      ModalProps={{ keepMounted: true }}
      PaperProps={{
        sx: {
          height: `calc(${maxDrawerHeightPercent}% - ${drawerBleeding}px)`,
          overflow: "visible",
        },
      }}
    >
      <Box
        className={headerBoxRoot}
        top={-drawerBleeding}
        position="absolute"
        height={drawerBleeding}
        visibility="visible"
        right={0}
        left={0}
        py={2}
        px={4}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box>
          <Box className={pullerBoxRoot} />
        </Box>
        <Grid
          container
          justifyContent={isButtonShown ? "space-between" : "flex-start"}
          alignItems="center"
        >
          <Grid item>
            <Typography>{`${total}: ${summary}`}</Typography>
          </Grid>
          <Grid item>
            {isButtonShown && (
              <Button
                className={checkoutButtonRoot}
                variant="contained"
                size="medium"
                onClick={onSubmitClick}
                disabled={isSubmitButtonDisabled}
                isLoading={isSubmitLoading}
              >
                {buttonSubmitText}
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box m={0} p={6} height="100%" overflow="auto">
        {children}
      </Box>
    </SwipeableDrawer>
  );
};

export default AppointmentSummaryMobile;
