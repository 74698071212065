import React, {
  FC,
  createContext,
  ReactNode,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { Auth as AWSAuth } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { useSessionStorage } from "react-use";
import {
  ObservableStatus,
  useAuth as useFirebaseAuth,
  useUser as useFirebaseUser,
} from "reactfire";
import { signInWithCustomToken, User } from "firebase/auth";
import { SendAuthCodeParams } from "@Shape-Digital/kudzu-data/lib/types/actions";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import {
  setSupabaseAccessToken,
  setCenterUserSupabaseToken,
} from "../../../hooks/useSupabase";
import useTranslations from "./useTranslations";
import normalizeCodeString from "../../Appointment/AppointmentWelcomeDialog/normalizeCodeString";
import useIsCenterUser from "./useIsCenterUser";
import { SUPABASE_CENTER_USER_TOKEN_KEY } from "../../../common/constants";

export type FirebaseLoadingStatus = "success" | "loading" | "error";

export type AnswerCustomChallengeFuncType = (params: {
  appointmentId: string;
  email: string;
  code: string;
}) => Promise<boolean>;

type AuthContextProps = {
  isAuthenticated: boolean | null;
  signIn: (args: SendAuthCodeParams) => Promise<boolean>;
  answerCustomChallenge: AnswerCustomChallengeFuncType;
  signOut: typeof AWSAuth.signOut;
  firebaseUser: ObservableStatus<User | null> | null;
  awsSession: Awaited<
    Promise<ReturnType<typeof AWSAuth.currentSession>>
  > | null;
  isCenterUser: boolean;
  isCenterUserLoading: boolean;
  isTokenChecked: boolean;
};

type AuthContextProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: null,
  signIn: () => Promise.resolve(false),
  answerCustomChallenge: () => Promise.resolve(false),
  signOut: () => Promise.resolve(),
  firebaseUser: null,
  awsSession: null,
  isCenterUser: false,
  isCenterUserLoading: false,
  isTokenChecked: false,
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider: FC<AuthContextProviderProps> = ({
  children,
}) => {
  const [centerUserFirebaseToken, setCenterUserFirebaseToken] =
    useSessionStorage<string | null>("CENTER_USER_FIREBASE_TOKEN", null);
  const { authCodeInvalid } = useTranslations();

  const firebaseAuth = useFirebaseAuth();
  const firebaseUser = useFirebaseUser();
  const [isAuthenticated, setIsAuthenticated] =
    useState<AuthContextProps["isAuthenticated"]>(null);
  const sendAuthCode = useFirebaseAppFunction("sendAuthCode");
  const createCustomToken = useFirebaseAppFunction("createCustomToken");
  const generateCenterUserCustomTokens = useFirebaseAppFunction(
    "generateCenterUserCustomTokens",
  );

  const supabaseCenterUserToken = sessionStorage.getItem(
    SUPABASE_CENTER_USER_TOKEN_KEY,
  );

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const centerUserToken = queryParams.get("token");

  const [awsSession, setAwsSession] =
    useState<AuthContextProps["awsSession"]>(null);

  useEffect(() => {
    const updateAwsSession = async () => {
      try {
        const innerAwsSession = await AWSAuth.currentSession();
        setAwsSession(innerAwsSession);
      } catch (error) {
        setAwsSession(null);
      }
    };

    updateAwsSession();
  }, []);

  const checkIsAuthenticated = useCallback(async () => {
    try {
      const isAwsAuth = !!(await AWSAuth.currentSession());
      const isFirebaseAuth = !!firebaseUser;
      return isAwsAuth && isFirebaseAuth;
    } catch (error) {
      return false;
    }
  }, [firebaseUser]);

  useEffect(() => {
    checkIsAuthenticated().then((res) => setIsAuthenticated(res));
  }, [checkIsAuthenticated]);

  useEffect(() => {
    if (centerUserToken) {
      const centerUserAuth = async () => {
        const {
          data: { firebaseToken, supabaseToken },
        } = await generateCenterUserCustomTokens({ token: centerUserToken });

        setCenterUserFirebaseToken(firebaseToken);

        setCenterUserSupabaseToken(supabaseToken);
      };
      centerUserAuth();
    }

    if (!centerUserToken && supabaseCenterUserToken === undefined) {
      sessionStorage.setItem(SUPABASE_CENTER_USER_TOKEN_KEY, "");
    }
  }, [
    centerUserToken,
    firebaseAuth,
    generateCenterUserCustomTokens,
    setCenterUserFirebaseToken,
    supabaseCenterUserToken,
  ]);

  useEffect(() => {
    if (!firebaseUser?.data?.uid && centerUserFirebaseToken) {
      const signInCenterUser = async () => {
        await signInWithCustomToken(firebaseAuth, centerUserFirebaseToken);
      };

      signInCenterUser();
    }
  }, [centerUserFirebaseToken, firebaseAuth, firebaseUser?.data?.uid]);

  const {
    isCenterUser,
    isLoading: isCenterUserLoading,
    isTokenChecked,
  } = useIsCenterUser({
    userId: firebaseUser?.data?.uid || null,
    centerUserFirebaseToken,
  });

  const signIn = useCallback<AuthContextProps["signIn"]>(
    async ({ email }) => {
      const { data } = await sendAuthCode({ email });
      const { isSent } = data;

      return isSent;
    },
    [sendAuthCode],
  );

  const answerCustomChallenge: AuthContextProps["answerCustomChallenge"] =
    async (params) => {
      const { appointmentId, email, code } = params;

      const normalizedCode = normalizeCodeString(code);

      const { data } = await createCustomToken({
        appointmentId,
        email,
        code: normalizedCode,
      });

      if (data.status === "error") {
        switch (data.error.code) {
          case "AUTH_CODE_INVALID": {
            throw new Error(authCodeInvalid);
            break;
          }
          default: {
            break;
          }
        }
        return false;
      }

      const { cognitoUsername, firebaseToken, supabaseToken } = data.data;

      const cognitoUser = await AWSAuth.signIn(cognitoUsername);

      await AWSAuth.sendCustomChallengeAnswer(cognitoUser, code);

      await signInWithCustomToken(firebaseAuth, firebaseToken);

      const isAuth = await checkIsAuthenticated();

      setSupabaseAccessToken(supabaseToken);

      try {
        const innerAwsSession = await AWSAuth.currentSession();
        setAwsSession(innerAwsSession);
      } catch (error) {
        setAwsSession(null);
      }

      setIsAuthenticated(isAuth);

      return isAuth;
    };

  const signOut = useCallback<AuthContextProps["signOut"]>(async () => {
    await AWSAuth.signOut();
    setAwsSession(null);

    await firebaseAuth.signOut();

    setSupabaseAccessToken("");

    setIsAuthenticated(false);
  }, [firebaseAuth]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        firebaseUser,
        awsSession,
        isCenterUser,
        isCenterUserLoading,
        isTokenChecked,
        signIn,
        signOut,
        answerCustomChallenge,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
