/* eslint-disable @typescript-eslint/naming-convention */
import { PostgrestSingleResponse } from "@supabase/supabase-js";
import { useCallback, useEffect, useState } from "react";
import useSupabase from "../../../hooks/useSupabase";
import useSystemConfig from "../../../hooks/useSystemConfig";
import parseConfirmedAppointment, {
  ConfirmedAppointment,
  SupabaseConfirmedAppointment,
  supabaseConfirmedAppointmentRequest,
} from "./parseConfirmedAppointment";

const refetchIntervalMs = 5000;

const useConfirmedAppointment = (id?: string) => {
  const [data, setData] = useState<ConfirmedAppointment | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const supabase = useSupabase();

  const systemConfig = useSystemConfig();

  const getConfirmedAppointmentRequest = useCallback(async () => {
    try {
      const {
        data: systemConfigData,
        status: systemConfigStatus,
        error: systemConfigError,
      } = systemConfig;

      if (!id) {
        throw new Error("Appointment is not found");
      }

      setIsLoading(true);

      if (systemConfigStatus === "loading") {
        return;
      }

      if (systemConfigStatus === "error") {
        throw new Error(systemConfigError);
      }

      const { data: appointment, error: appointmentError } = (await supabase
        .from("appointments")
        .select(supabaseConfirmedAppointmentRequest)
        .eq("id", id)
        .is("appointment_offerings.appointment_package_id", null)
        .limit(1, { foreignTable: "center.center_configs" })
        .limit(1, { foreignTable: "appointment_customer_details" })
        .limit(1, { foreignTable: "appointment_patient_details" })
        .single()) as PostgrestSingleResponse<SupabaseConfirmedAppointment>;

      if (appointmentError) {
        throw new Error(appointmentError.message);
      }

      if (appointment) {
        const parsedAppointment = parseConfirmedAppointment(
          appointment,
          systemConfigData,
        );

        setData(parsedAppointment);
      }
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [id, supabase, systemConfig]);

  useEffect(() => {
    getConfirmedAppointmentRequest();

    const intervalId = setInterval(
      getConfirmedAppointmentRequest,
      refetchIntervalMs,
    );

    return () => {
      clearInterval(intervalId);
    };
  }, [getConfirmedAppointmentRequest]);

  return { error, isLoading, data };
};

export default useConfirmedAppointment;
