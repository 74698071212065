import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import BookingSection from "../AppointmentBookingSection";
import { useAppointmentBookingContext } from "../AppointmentBookingContext";
import AppointmentLayout from "../AppointmentLayout";
import parseAppointment from "./parseAppointment";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import useTranslations from "./useTranslations";
import getTotalSummary from "./getTotalSummary";
import { setSupabaseAccessToken } from "../../../hooks/useSupabase";
import { useAuthContext } from "../../Auth/AuthContextProvider";
import { SelectedCenterService } from "../AppointmentBookingContext/types";
import { setStorageAppointment } from "../AppointmentBookingContext/storageAppointmentHelpers";
import useDeviceType from "../../../hooks/useDeviceType";
import {
  GAEventCategory,
  SUPABASE_CENTER_USER_TOKEN_KEY,
} from "../../../common/constants";

const AppointmentBookingScreen: FC = () => {
  const { signOut, isCenterUser } = useAuthContext();

  useEffect(() => {
    const centerUserSupabaseToken = sessionStorage.getItem(
      SUPABASE_CENTER_USER_TOKEN_KEY,
    );

    if (!centerUserSupabaseToken) {
      signOut();
    }
  }, [signOut]);

  const navigate = useNavigate();

  const { translations } = useTranslations();

  const { isMobile } = useDeviceType();

  const appointmentBooking = useFirebaseAppFunction("appointmentBooking");

  const {
    selectedCenter,
    isAllCenterServicesValid,
    selectedAddOns,
    selectedDate,
    selectedTimeSlot,
    isSubmitLoading,
    selectedCenterServices,
    availableTimeSlots,
    storageAppointmentId,
    overriddenPrice,
    overriddenDeposit,
    overriddenTime,
    onTimeOverride,
    onPriceOverride,
    onDepositOverride,
    setIsSubmitLoading,
  } = useAppointmentBookingContext();

  const { updateAvailableTimeSlots } = availableTimeSlots;

  const isSubmitButtonDisabled =
    !selectedCenter ||
    !isAllCenterServicesValid ||
    !selectedDate ||
    (selectedTimeSlot === null && !overriddenTime);

  const filteredCenterServices = useMemo(
    () => selectedCenterServices.filter(Boolean) as SelectedCenterService[],
    [selectedCenterServices],
  );

  const overriddenPriceNumber = overriddenPrice
    ? parseFloat(overriddenPrice)
    : null;

  const overriddenDepositNumber = overriddenDeposit
    ? parseFloat(overriddenDeposit)
    : null;

  const onSubmitClick = useCallback(async () => {
    ReactGA.event({
      action: "Click proceed to checkout",
      category: GAEventCategory.click,
    });

    const { submitDisabledError, timeSlotNotAvailableError, defaultError } =
      translations;
    try {
      if (isSubmitButtonDisabled) {
        throw new Error(submitDisabledError);
      }

      setIsSubmitLoading(true);

      const appointmentBookingParams = parseAppointment({
        center: selectedCenter,
        centerServices: filteredCenterServices,
        addOns: selectedAddOns,
        dateTime: selectedTimeSlot || selectedDate,
        overriddenTime,
        updateAppointmentId: storageAppointmentId || undefined,
        overriddenPrice: overriddenPriceNumber,
        overriddenDeposit: overriddenDepositNumber,
      });

      const response = await appointmentBooking(appointmentBookingParams);

      if (response.data.status === "error") {
        const { code } = response.data.error;

        switch (code) {
          case "TIME_SLOT_NOT_AVAILABLE": {
            await updateAvailableTimeSlots();
            throw new Error(timeSlotNotAvailableError);
          }
          default: {
            throw new Error(defaultError);
          }
        }
      }

      const { data } = response.data;

      setStorageAppointment(
        data.appointmentId,
        isCenterUser ? undefined : data.supabaseToken,
      );

      if (
        !isCenterUser &&
        !sessionStorage.getItem(SUPABASE_CENTER_USER_TOKEN_KEY)
      ) {
        setSupabaseAccessToken(data.supabaseToken);
      }

      navigate(`/${data.appointmentId}/checkout`);
      return;
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert((error as Error).message);
    } finally {
      setIsSubmitLoading(false);
    }
  }, [
    translations,
    isSubmitButtonDisabled,
    setIsSubmitLoading,
    selectedCenter,
    filteredCenterServices,
    selectedAddOns,
    selectedTimeSlot,
    selectedDate,
    overriddenTime,
    storageAppointmentId,
    overriddenPriceNumber,
    overriddenDepositNumber,
    appointmentBooking,
    isCenterUser,
    navigate,
    updateAvailableTimeSlots,
  ]);

  const {
    depositAmount,
    discountSum,
    priceWithDiscount,
    isOverriddenPriceError,
    isOverriddenDepositError,
  } = useMemo(() => {
    const { discountAmount } = selectedCenter || {};
    return getTotalSummary({
      centerServices: filteredCenterServices || [],
      addOns: selectedAddOns || [],
      discountAmount,
      overriddenPrice: overriddenPriceNumber,
      overriddenDeposit: overriddenDepositNumber,
    });
  }, [
    filteredCenterServices,
    overriddenPriceNumber,
    overriddenDepositNumber,
    selectedAddOns,
    selectedCenter,
  ]);

  const buttonSubmitText = isMobile
    ? translations.buttonMobileSubmitText
    : translations.buttonDesktopSubmitText;

  return (
    <AppointmentLayout
      selectedCenter={selectedCenter}
      selectedDateTime={selectedTimeSlot}
      selectedCenterServices={filteredCenterServices}
      selectedAddOns={selectedAddOns}
      onSubmitClick={onSubmitClick}
      isSubmitButtonDisabled={isSubmitButtonDisabled}
      isSubmitLoading={isSubmitLoading}
      depositAmount={depositAmount}
      discountSum={discountSum}
      priceWithDiscount={priceWithDiscount}
      buttonSubmitText={buttonSubmitText}
      isCenterUser={isCenterUser}
      overriddenPrice={overriddenPrice}
      overriddenDeposit={overriddenDeposit}
      onPriceOverride={onPriceOverride}
      onDepositOverride={onDepositOverride}
      onTimeOverride={onTimeOverride}
      overriddenTime={overriddenTime}
      isDaySelected={!!selectedDate}
      isOverriddenPriceError={isOverriddenPriceError}
      isOverriddenDepositError={isOverriddenDepositError}
    >
      <BookingSection />
    </AppointmentLayout>
  );
};

export default AppointmentBookingScreen;
