import React, { FC } from "react";
import Grid from "../Grid";
import Paper from "../Paper";
import Typography from "../Typography";
import Box from "../Box";

import useStyles from "./useStyles";

export type BlockData = {
  text: string;
  isBold?: boolean;
  prefix?: string;
  href?: string;
};

export type Block = { title: string; data: BlockData[] };

type TableInfoBlockProps = {
  blocks: Block[];
};

const TableInfoBlock: FC<TableInfoBlockProps> = ({ blocks }) => {
  const { paperRoot, titleRoot, boldTextRoot, linkRoot } = useStyles();
  return (
    <Paper classes={{ root: paperRoot }}>
      <Grid container spacing={1} columns={{ xs: 6, sm: 12 }}>
        {blocks.map(({ title, data }) => (
          <Grid item xs={6} key={title}>
            <Box sx={{ overflowWrap: "break-word" }}>
              <Typography
                variant="body2"
                className={titleRoot}
                sx={{ wordBreak: "break-word" }}
              >
                {title}
              </Typography>
              {data.map(({ text, isBold, prefix, href }) => {
                if (!text) return null;

                return (
                  <Box key={text}>
                    {prefix && (
                      <Typography component="span">
                        {prefix}
                        {": "}
                      </Typography>
                    )}
                    {href ? (
                      <a href={href} className={linkRoot}>
                        <Typography
                          className={isBold ? boldTextRoot : undefined}
                          sx={{ wordBreak: "break-word" }}
                          display="inline"
                          color="primary.main"
                        >
                          {text}
                        </Typography>
                      </a>
                    ) : (
                      <Typography
                        className={isBold ? boldTextRoot : undefined}
                        sx={{ wordBreak: "break-word" }}
                        display="inline"
                      >
                        {text}
                      </Typography>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default TableInfoBlock;
