import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormik } from "formik";
import Dialog from "../../Unknown/Dialog";
import DialogContent from "../../Unknown/DialogContent";
import DialogActions from "../../Unknown/DialogActions";
import DialogTitle from "../../Unknown/DialogTitle";
import Button from "../../Unknown/Button";
import TextField from "../../Unknown/TextField";
import Grid from "../../Unknown/Grid";
import InputAdornment from "../../Unknown/InputAdornment";
import CircularProgress from "../../Unknown/CircularProgress";
import Box from "../../Unknown/Box";
import normalizeCodeString from "./normalizeCodeString";
import messages from "./messages";
import Typography from "../../Unknown/Typography";

export type AppointmentAuthFormik = {
  authCode: string;
};

type AppointmentWelcomeDialogProps = {
  email: string;
  isOpen: boolean;
  onResendClick: () => void | Promise<void>;
  onClose: () => void;
  formik: ReturnType<typeof useFormik<AppointmentAuthFormik>>;
};

const AppointmentWelcomeDialog: FC<AppointmentWelcomeDialogProps> = ({
  email,
  isOpen,
  formik,
  onClose,
  onResendClick,
}) => {
  const { formatMessage } = useIntl();
  const {
    title,
    desc,
    emailDescStart,
    emailDescEnd,
    resendButton,
    submitButton,
    useAnotherEmail,
    emailCodePlaceholder,
  } = messages;

  const {
    isSubmitting,
    values,
    errors,
    touched,
    resetForm,
    handleBlur,
    handleSubmit,
    submitForm,
    handleChange,
  } = formik;

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <FormattedMessage {...title} />
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={5}>
          <Grid item>
            <FormattedMessage {...desc} />
          </Grid>
          <Grid item overflow="hidden" textOverflow="ellipsis">
            <FormattedMessage {...emailDescStart} />
            <Typography component="span" fontWeight={700} title={email}>
              {email}
            </Typography>
            <FormattedMessage {...emailDescEnd} />
          </Grid>
          <Grid item width="100%">
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                id="authCode"
                name="authCode"
                type="text"
                onChange={handleChange}
                value={normalizeCodeString(values.authCode)}
                disabled={isSubmitting}
                placeholder={formatMessage(emailCodePlaceholder)}
                onBlur={handleBlur}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
                error={Boolean(errors.authCode && touched.authCode)}
                helperText={
                  errors.authCode && touched.authCode && String(errors.authCode)
                }
                autoComplete="off"
                required
                InputProps={{
                  endAdornment: isSubmitting ? (
                    <InputAdornment position="end">
                      <CircularProgress size={20} />
                    </InputAdornment>
                  ) : undefined,
                }}
              />
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: { sm: "space-between" },
          flexWrap: "wrap",
        }}
      >
        <Button onClick={onClose} disabled={isSubmitting} size="small">
          <FormattedMessage {...useAnotherEmail} />
        </Button>
        <Box display="flex" flexWrap="wrap">
          <Button
            sx={{ px: 1 }}
            onClick={onResendClick}
            disabled={isSubmitting}
          >
            <FormattedMessage {...resendButton} />
          </Button>
          <Button
            sx={{ px: 1.5, ml: 1.5 }}
            onClick={submitForm}
            disabled={isSubmitting}
          >
            <FormattedMessage {...submitButton} />
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AppointmentWelcomeDialog;
