import { useIntl } from "react-intl";
import { DEFAULT_CURRENCY_CODE } from "../../../common/constants";
import messages from "./messages";

const useTranslations = ({
  discountAmount,
  defaultCurrencyCode,
}: {
  discountAmount?: number;
  defaultCurrencyCode?: string;
}) => {
  const { formatMessage, formatNumber } = useIntl();

  const discountAmountString = formatNumber(discountAmount || 0, {
    style: "currency",
    currency: defaultCurrencyCode || DEFAULT_CURRENCY_CODE,
  }).replace(".00", "");

  const translations = {
    label: formatMessage(messages.label),
    removeBtn: formatMessage(messages.removeBtn),
    addBtn: formatMessage(messages.addBtn, {
      discountAmount: discountAmountString,
    }),
    singleOfferingsTitle: formatMessage(messages.singleOfferingsTitle),
    packagesTitle: formatMessage(messages.packagesTitle),
  };

  return { translations };
};

export default useTranslations;
