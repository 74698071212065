import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { SearchOutlinedIcon } from "../../Unknown/Icons";
import useStyles from "./useStyles";
import messages from "./messages";
import getCustomerLabel from "./getCustomerLabel";
import useCustomers from "./useCustomers";
import getCustomerMenuItem from "./getCustomerMenuItem";
import Box from "../../Unknown/Box";
import CircularProgress from "../../Unknown/CircularProgress";

interface CustomerSearchInputProps {
  onSelectCustomer?: (customer: DatabaseRow<"customers">) => void;
}

const CustomerSearchInput: React.FC<CustomerSearchInputProps> = ({
  onSelectCustomer,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(
    null,
  );

  const classes = useStyles();
  const intl = useIntl();
  const { customers, isLoading, errorMessage } = useCustomers({
    searchValue,
  });

  const options = useMemo(() => {
    return customers.map((customer) => customer.id);
  }, [customers]);

  useEffect(() => {
    if (selectedCustomerId) {
      const customer = customers.find(({ id }) => id === selectedCustomerId);
      if (customer && onSelectCustomer) {
        onSelectCustomer(customer);
      }
    }
  }, [customers, onSelectCustomer, selectedCustomerId]);

  return (
    <Autocomplete
      options={options}
      renderOption={(props, option) => {
        return getCustomerMenuItem({
          customers,
          selectedId: option,
          props,
        });
      }}
      getOptionLabel={(selectedId) =>
        getCustomerLabel({ customers, selectedId })
      }
      slotProps={{
        paper: {
          elevation: 8,
        },
      }}
      value={selectedCustomerId}
      onChange={(event, newValue: string | null) => {
        setSelectedCustomerId(newValue);
      }}
      filterOptions={() => options}
      inputValue={searchValue}
      onInputChange={(event, newInputValue) => {
        setSearchValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          classes={{
            root: classes.input,
          }}
          label={intl.formatMessage(messages.searchLabel)}
          variant="outlined"
          fullWidth
          autoComplete="off"
          error={!!errorMessage}
          helperText={errorMessage}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Box display="flex" alignItems="center">
                {isLoading && <CircularProgress size={18} sx={{ mr: 2 }} />}
                <SearchOutlinedIcon classes={{ root: classes.searchIcon }} />
              </Box>
            ),
          }}
        />
      )}
    />
  );
};

export default CustomerSearchInput;
