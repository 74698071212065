import React, { FC, useMemo } from "react";
import { useAppointmentBookingContext } from "../../Appointment/AppointmentBookingContext";
import Button from "../../Unknown/Button";
import AddInputButton from "../../Unknown/AddInputButton";
import SelectCenterServiceInput, { Groups } from "../SelectCenterServiceInput";
import Grid from "../../Unknown/Grid";

import useCenterServiceOptions from "./useCenterServiceOptions";
import useTranslations from "./useTranslations";
import SelectCenterServiceSideInput from "../SelectCenterServiceSideInput";
import getSelectCenterServiceFormItemProps from "./getSelectCenterServiceFormItemProps";
import useStyles from "./useStyles";
import { AddIcon } from "../../Unknown/Icons";

const SelectCenterServiceForm: FC = () => {
  const { addIconRoot } = useStyles();

  const {
    selectedCenter,
    selectedDate,
    offerings,
    packages,
    isSubmitLoading,
    isAllCenterServicesValid,
    appointmentDuration,
    availableTimeSlots,
  } = useAppointmentBookingContext();

  const {
    options,
    selectedOptions,
    isCanCreateMore,
    addCenterService,
    removeCenterService,
    changeCenterService,
    changeSelectedSide,
  } = useCenterServiceOptions();

  const { translations } = useTranslations({
    discountAmount: selectedCenter?.discountAmount,
    defaultCurrencyCode: selectedCenter?.defaultCurrencyCode,
  });

  const groups: Groups = useMemo(() => {
    return {
      package: translations.packagesTitle,
      "single-offering": translations.singleOfferingsTitle,
    };
  }, [translations]);

  const isAddButtonShown = useMemo(() => {
    if (!selectedDate) return true;

    if (availableTimeSlots.status !== "success") return true;

    const currentDateDay = selectedDate.getDate();
    const currentDayData =
      availableTimeSlots.data.currentMonthTimeSlots[currentDateDay];

    if (!currentDayData) return true;

    const currentDayMaxDuration = currentDayData.maxDurationMinutes;

    return appointmentDuration < currentDayMaxDuration;
  }, [
    appointmentDuration,
    availableTimeSlots.data,
    availableTimeSlots.status,
    selectedDate,
  ]);

  if (!selectedCenter || !offerings) return null;

  const { status: offeringsStatus } = offerings;
  const { status: packagesStatus } = packages;

  return (
    <Grid container>
      {selectedOptions.map((selectedOption, index) => {
        const { key, isFirst, isDual, side, isLeftDisabled, isRightDisabled } =
          getSelectCenterServiceFormItemProps({
            index,
            selectedOption,
            selectedOptions,
          });

        return (
          <Grid key={key} item container>
            {!isFirst && (
              <Grid
                item
                display="flex"
                justifyContent="center"
                width="100%"
                my={2}
              >
                <AddIcon fontSize="large" className={addIconRoot} />
              </Grid>
            )}
            <Grid item xs={12}>
              <SelectCenterServiceInput
                inputLabel={translations.label}
                options={options}
                groups={groups}
                isDiscountShown={index !== 0}
                selectedOption={selectedOption}
                onChange={changeCenterService(index)}
                isLoading={
                  offeringsStatus === "loading" || packagesStatus === "loading"
                }
                isDisabled={isSubmitLoading}
              />
            </Grid>
            {(!isFirst || isDual) && (
              <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-end"
                mt={2}
              >
                {isDual && (
                  <Grid item flex={1}>
                    <SelectCenterServiceSideInput
                      id={`${key}-select-side`}
                      value={side}
                      onChange={changeSelectedSide(index)}
                      isLeftDisabled={isLeftDisabled}
                      isRightDisabled={isRightDisabled}
                    />
                  </Grid>
                )}
                {!isFirst && (
                  <Grid item ml={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="error"
                      size="medium"
                      onClick={() => removeCenterService(index)}
                      disabled={isSubmitLoading}
                    >
                      {translations.removeBtn}
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        );
      })}
      {isAddButtonShown && (
        <Grid item flexGrow={1} mt={3}>
          <AddInputButton
            fullWidth
            disabled={
              !isAllCenterServicesValid || !isCanCreateMore || isSubmitLoading
            }
            text={translations.addBtn}
            onClick={addCenterService}
            sx={{ whiteSpace: "unset" }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SelectCenterServiceForm;
