import { defineMessages } from "react-intl";

export default defineMessages({
  isPatientLabel: {
    id: "Appointment.AppointmentCheckoutFormChoice.isPatientLabel",
    defaultMessage: "Are you the patient?",
  },
  isGuardianLabel: {
    id: "Appointment.AppointmentCheckoutFormChoice.isGuardianLabel",
    defaultMessage: "Are you the patient's legal guardian?",
  },
});
