import { defineMessages } from "react-intl";

export default defineMessages({
  selectCenterTitle: {
    id: "Appointment.AppointmentBookingSection.selectCenterTitle",
    defaultMessage: "Where would you like your MRI scan?",
  },
  selectCenterServiceTitle: {
    id: "Appointment.AppointmentBookingSection.selectCenterServiceTitle",
    defaultMessage: "What MRI scan would you like?",
  },
  selectDateTitle: {
    id: "Appointment.AppointmentBookingSection.selectDateTitle",
    defaultMessage: "When would you like your MRI scan?",
  },
});
