import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import React, { FC, FocusEventHandler } from "react";
import { Form, useFormikContext } from "formik";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import useTranslations from "./useTranslations";
import FormikTextField from "../../Unknown/FormikTextField";
import TextField from "../../Unknown/TextField";
import InputAdornment from "../../Unknown/InputAdornment";
import CircularProgress from "../../Unknown/CircularProgress";
import FormikRadioGroup, {
  FormikRadioGroupOption,
} from "../../Unknown/FormikRadioGroup";
import Box from "../../Unknown/Box";
import Alert from "../../Unknown/Alert";
import FormikPhoneInput from "../../Unknown/FormikPhoneInput";
import formatEmail from "../../../common/formatEmail";
import CustomerSearchInput from "../../Customer/CustomerSearchInput";

export type GuardianFormValues = {
  guardianEmail: string;
  guardianPhoneNumber: string;
  guardianFirstName: string;
  guardianLastName: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  pregnant: "yes" | "no" | "";
  wearingDevice: "yes" | "no" | "";
};

type AppointmentCheckoutGuardianFormProps = {
  onNotPossibleAnswerClick?: (question: string) => void;
  onEmailBlur?: (email: string, valid: boolean) => void | Promise<void>;
  isEmailLoading?: boolean;
  isCheckDataShown?: boolean;
  isEmailInUse?: boolean;
  isCenterUser?: boolean;
  onSelectCustomer?: (customer: DatabaseRow<"customers">) => void;
};

const AppointmentCheckoutGuardianForm: FC<
  AppointmentCheckoutGuardianFormProps
> = ({
  onNotPossibleAnswerClick,
  onEmailBlur,
  isEmailLoading,
  isCheckDataShown,
  isEmailInUse,
  isCenterUser,
  onSelectCustomer,
}) => {
  const translations = useTranslations();
  const { isSubmitting, setFieldValue, handleBlur, values, errors, touched } =
    useFormikContext<GuardianFormValues>();

  const { answerYes, answerNo, wearingDeviceHeader } = translations;

  // Uncomment the code if there is a need for displaying pregnancy question in the future.
  // const onPregnantClick = () => {
  //   if (onNotPossibleAnswerClick) {
  //     onNotPossibleAnswerClick(pregnantHeader);
  //   }
  // };

  const onWearingDeviceClick = () => {
    if (onNotPossibleAnswerClick) {
      onNotPossibleAnswerClick(wearingDeviceHeader);
    }
  };

  // const pregnantOptions: FormikRadioGroupOption[] = [
  //   { value: "yes", label: answerYes, onClick: onPregnantClick },
  //   { value: "no", label: answerNo },
  // ];

  const wearingDeviceOptions: FormikRadioGroupOption[] = [
    { value: "yes", label: answerYes, onClick: onWearingDeviceClick },
    { value: "no", label: answerNo },
  ];

  const handleEmailBlur: FocusEventHandler<HTMLInputElement> = async (e) => {
    if (onEmailBlur) {
      onEmailBlur(e.target.value, !errors.guardianEmail);
    }
    handleBlur(e);
  };

  const isEmailInUseError = isEmailInUse ? translations.authErrorMessage : null;

  return (
    <Form>
      <Grid container spacing={8} direction="column">
        <Grid item container spacing={4} direction="column">
          <Grid item>
            <Typography variant="h3">
              {translations.yourInformationHeader}
            </Typography>
          </Grid>
          {isCenterUser && (
            <Grid item>
              <CustomerSearchInput onSelectCustomer={onSelectCustomer} />
            </Grid>
          )}
          <Grid item>
            <TextField
              name="guardianEmail"
              type="guardianEmail"
              onChange={({ target }) =>
                setFieldValue("guardianEmail", formatEmail(target.value))
              }
              onBlur={handleEmailBlur}
              value={formatEmail(values.guardianEmail)}
              label={translations.emailLabel}
              disabled={isEmailLoading || isSubmitting}
              fullWidth
              InputProps={{
                endAdornment: isEmailLoading ? (
                  <InputAdornment position="end">
                    <CircularProgress size={20} />
                  </InputAdornment>
                ) : undefined,
              }}
              InputLabelProps={{ shrink: !!values.guardianEmail }}
              error={
                touched.guardianEmail &&
                Boolean(errors.guardianEmail || isEmailInUseError)
              }
              helperText={
                touched.guardianEmail &&
                (errors.guardianEmail || isEmailInUseError)
              }
              autoComplete="off"
              required
            />
          </Grid>
          <Grid item>
            <FormikPhoneInput
              name="guardianPhoneNumber"
              autoComplete="off"
              required
              fullWidth
              disabled={isSubmitting}
              label={translations.phoneNumberLabel}
            />
          </Grid>
          <Grid item>
            <FormikTextField
              autoComplete="off"
              required
              fullWidth
              name="guardianFirstName"
              label={translations.firstNameLabel}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item>
            <FormikTextField
              autoComplete="off"
              required
              fullWidth
              name="guardianLastName"
              label={translations.lastNameLabel}
              disabled={isSubmitting}
            />
          </Grid>
          {isCheckDataShown && (
            <Grid item>
              <Box mt={4}>
                <Alert severity="info">{translations.checkUpToDateData}</Alert>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid item container spacing={4} direction="column">
          <Grid item>
            <Typography variant="h3">
              {translations.patientInformationHeader}
            </Typography>
            <Typography mt={1} variant="h4" letterSpacing={0.4}>
              {translations.patientInformationDesc}
            </Typography>
          </Grid>
          <Grid item>
            <FormikTextField
              autoComplete="off"
              required
              fullWidth
              name="firstName"
              label={translations.firstNameLabel}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item>
            <FormikTextField
              autoComplete="off"
              required
              fullWidth
              name="lastName"
              label={translations.lastNameLabel}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item>
            <FormikTextField
              autoComplete="off"
              type="date"
              required
              fullWidth
              name="dateOfBirth"
              label={translations.dateOfBirthLabel}
              disabled={isSubmitting}
              InputLabelProps={{ shrink: true }}
              helperText={translations.dateOfBirthHelper}
              placeholder={translations.dateOfBirthPlaceholder}
            />
          </Grid>
        </Grid>
        {/* <Grid item container spacing={4} direction="column">
          <Grid item>
            <Typography variant="h3">{translations.pregnantHeader}</Typography>
          </Grid>
          <Grid item>
            <FormikRadioGroup
              name="pregnant"
              isDisabled={isSubmitting}
              options={pregnantOptions}
            />
          </Grid>
        </Grid> */}
        <Grid item container spacing={4} direction="column">
          <Grid item>
            <Typography variant="h3">
              {translations.wearingDeviceHeader}
            </Typography>
          </Grid>
          <Grid item>
            <FormikRadioGroup
              name="wearingDevice"
              isDisabled={isSubmitting}
              options={wearingDeviceOptions}
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default AppointmentCheckoutGuardianForm;
