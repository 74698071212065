// eslint-disable-next-line import/no-mutable-exports, @typescript-eslint/ban-types
let firebaseConfig: Object;
if (process.env.REACT_APP_RELEASE_CHANNEL === "staging") {
  firebaseConfig = {
    apiKey: "AIzaSyAcZmff7smxmwvrDPGhl9XzfVuzm8ZRGhU",
    authDomain: "kudzu-usa-staging.firebaseapp.com",
    projectId: "kudzu-usa-staging",
    storageBucket: "kudzu-usa-staging.appspot.com",
    messagingSenderId: "487088368746",
    appId: "1:487088368746:web:cb6b86c1b772b08364ae13",
    measurementId: "G-G9X9TLBVJ7",
  };
} else if (process.env.REACT_APP_RELEASE_CHANNEL === "prod") {
  firebaseConfig = {
    apiKey: "AIzaSyCIlPYL2AYSwAVtP6sgkXd3jyBSXT4Rzg8",
    authDomain: "kudzu-usa-prod.firebaseapp.com",
    projectId: "kudzu-usa-prod",
    storageBucket: "kudzu-usa-prod.appspot.com",
    messagingSenderId: "296947342003",
    appId: "1:296947342003:web:bec12d73a1912355892ec6",
    measurementId: "G-WLCT5RN869",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyDTzx0pRDodTxjA5OxEyezmmF_twcxmGyU",
    authDomain: "kudzu-usa-dev.firebaseapp.com",
    projectId: "kudzu-usa-dev",
    storageBucket: "kudzu-usa-dev.appspot.com",
    messagingSenderId: "873827156163",
    appId: "1:873827156163:web:0023c38b7c4d030f356276",
    measurementId: "G-R60V7CKQ7R",
  };
}

export default firebaseConfig;
