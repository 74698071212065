/* eslint-disable @typescript-eslint/naming-convention */
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import formatISOInUTC from "../../../common/formatISOInUTC";
import supabaseClient from "../../../common/supabaseClient";

type Appointment = Pick<DatabaseRow<"appointments">, "id">;

export type SupabaseBookingTimeSlot = Pick<
  DatabaseRow<"center_time_slots">,
  "center_id" | "type" | "expires_at" | "started_at" | "ended_at"
> & {
  appointments: Appointment[];
};

const supabaseBookingTimeSlotsRequest = `
center_id,
type,
expires_at,
started_at,
ended_at,
appointments ( id )
`;

const getOrFilters = (date: Date) => {
  const start = subDays(startOfMonth(date), 1);
  const startOfDateISO = formatISOInUTC(start);
  const end = addDays(endOfMonth(date), 1);
  const endOfDateISO = formatISOInUTC(end);

  const dateOrFilter = [
    `and(started_at.gte.${startOfDateISO},started_at.lte.${endOfDateISO})`,
    `and(ended_at.gte.${startOfDateISO},ended_at.lte.${endOfDateISO})`,
    `and(started_at.lte.${startOfDateISO},ended_at.gte.${startOfDateISO})`,
    `and(started_at.lte.${endOfDateISO},ended_at.gte.${endOfDateISO})`,
  ].join(",");

  const typeOrFilter = [
    "and(type.eq.appointment_booked,expires_at.is.null)",
    "and(type.eq.planned_closures,expires_at.is.null)",
    "and(type.eq.appointment_reserved,expires_at.gte.NOW())",
  ].join(",");

  return { dateOrFilter, typeOrFilter };
};

export type BookingTimeSlot = {
  startedAt: Date;
  endedAt: Date;
};

const getBookingUnavailableTimeSlots = async (params: {
  centerId: string;
  date: Date;
  appointmentId?: string;
  abortSignal: AbortSignal | null;
}) => {
  const { centerId, date, appointmentId, abortSignal } = params;

  const { dateOrFilter, typeOrFilter } = getOrFilters(date);

  let query = supabaseClient
    .from<SupabaseBookingTimeSlot>("center_time_slots")
    .select(supabaseBookingTimeSlotsRequest)
    .eq("center_id", centerId)
    .limit(1, { foreignTable: "appointments" })
    .or(dateOrFilter)
    .or(typeOrFilter);

  if (abortSignal) {
    query = query.abortSignal(abortSignal);
  }

  const { data, error } = await query;

  if (error) {
    throw new Error(error.message);
  }

  const filteredTimeSlots = data.filter(
    (timeSlot) =>
      timeSlot.type === "planned_closures" ||
      !appointmentId ||
      timeSlot.appointments[0]?.id !== appointmentId,
  );

  return filteredTimeSlots;
};

export default getBookingUnavailableTimeSlots;
