import formatISO from "date-fns/formatISO";

const formatISOInUTC = (date: Date) => {
  const formattedDate = formatISO(date);
  const ISOStringWithoutTimeZone = formattedDate.replace(
    /[\\+|\\-]\d\d:\d\d$/,
    "",
  );
  return ISOStringWithoutTimeZone;
};

export default formatISOInUTC;
