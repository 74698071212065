import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";

import { ITheme } from "../../../common/theme";

export default makeStyles<ITheme>((theme) => ({
  available: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),

    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.24),
    },
  },
  futureDate: {
    color: theme.palette.common.black,
    "&.Mui-disabled": {
      color: theme.palette.common.black,
    },
  },
}));
