import { defineMessages } from "react-intl";

export default defineMessages({
  requiredField: {
    id: "Appointment.AppointmentCheckoutFormSectionWrapper.requiredField",
    defaultMessage: "Required field",
  },
  dateIsInvalid: {
    id: "Appointment.AppointmentCheckoutFormSectionWrapper.dateIsInvalid",
    defaultMessage: "Date is invalid",
  },
  emailIsInvalid: {
    id: "Appointment.AppointmentCheckoutFormSectionWrapper.emailIsInvalid",
    defaultMessage: "Email is invalid",
  },
  phoneNumberIsInvalid: {
    id: "Appointment.AppointmentCheckoutFormSectionWrapper.phoneNumberIsInvalid",
    defaultMessage: "Phone number is invalid",
  },
  pregnantError: {
    id: "Appointment.AppointmentCheckoutFormSectionWrapper.pregnantError",
    defaultMessage:
      "Unfortunately it won't be possible for you to receive an MRI scan because you answered yes.",
  },
  wearingDeviceError: {
    id: "Appointment.AppointmentCheckoutFormSectionWrapper.wearingDeviceError",
    defaultMessage:
      "Unfortunately it won't be possible for you to receive an MRI scan because you answered yes.",
  },
  errorEmailInUse: {
    id: "Appointment.AppointmentCheckoutFormSectionWrapper.errorEmailInUse",
    defaultMessage:
      "This email address cannot be used as it is already in use by another customer or patient",
  },
});
