import { useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { SelectedOfferingSide } from "@Shape-Digital/kudzu-data/lib/types/common";
import { useAppointmentBookingContext } from "../../Appointment/AppointmentBookingContext";
import { Option as SelectCenterServiceInputOption } from "../SelectCenterServiceInput";
import { DEFAULT_CURRENCY_CODE } from "../../../common/constants";
import { SelectChangeEvent } from "../../Unknown/Select";
import {
  CenterServiceGroupId,
  SelectedCenterService,
} from "../../Appointment/AppointmentBookingContext/types";

const useCenterServiceOptions = () => {
  const { formatNumber } = useIntl();

  const {
    selectedCenter,
    selectedCenterServices,
    onCenterServicesChange,
    centerServices,
  } = useAppointmentBookingContext();

  const { defaultCurrencyCode } = selectedCenter || {};

  const options = useMemo<SelectCenterServiceInputOption[]>(() => {
    const { data, status } = centerServices;

    if (status !== "success") return [];

    const result = Object.values(data).reduce<SelectCenterServiceInputOption[]>(
      (acc, centerService) => {
        const { groupId, id, name, price, description, discountPrice } =
          centerService;

        const isDual =
          centerService.groupId === "single-offering" &&
          centerService.scanSide === "dual";

        const alreadySelectedItems = selectedCenterServices.filter(
          (selectedCenterService) =>
            selectedCenterService?.value &&
            centerService?.id === selectedCenterService.value,
        );

        const isDisabled = isDual
          ? alreadySelectedItems.length === 2
          : alreadySelectedItems.length === 1;

        const item = {
          groupId,
          value: id,
          label: name,
          price: formatNumber(price, {
            style: "currency",
            currency: defaultCurrencyCode || DEFAULT_CURRENCY_CODE,
          }),
          description,
          discountPrice: formatNumber(discountPrice, {
            style: "currency",
            currency: defaultCurrencyCode || DEFAULT_CURRENCY_CODE,
          }),
          isDisabled,
        };

        return [...acc, item];
      },
      [],
    );
    return result;
  }, [
    centerServices,
    selectedCenterServices,
    formatNumber,
    defaultCurrencyCode,
  ]);

  const selectedOptions = useMemo(() => {
    return selectedCenterServices.map((selectedCenterService) => {
      const { data, status } = centerServices;

      if (!selectedCenterService || status !== "success") return null;

      const centerService = data[selectedCenterService.value];

      if (!centerService) return null;

      const { groupId, id, name, price, description, discountPrice } =
        centerService;

      const isDual =
        centerService.groupId === "single-offering" &&
        centerService.scanSide === "dual";

      const result: SelectCenterServiceInputOption = {
        groupId,
        value: id,
        label: name,
        price: formatNumber(price, {
          style: "currency",
          currency: defaultCurrencyCode || DEFAULT_CURRENCY_CODE,
        }),
        description,
        discountPrice: formatNumber(discountPrice, {
          style: "currency",
          currency: defaultCurrencyCode || DEFAULT_CURRENCY_CODE,
        }),
        isDisabled: true,
        metadata: {
          isDual,
          side: selectedCenterService.side || "",
        },
      };

      return result;
    });
  }, [
    centerServices,
    defaultCurrencyCode,
    formatNumber,
    selectedCenterServices,
  ]);

  const isCanCreateMore = useMemo(() => {
    const { status, data } = centerServices;

    if (status !== "success") return false;

    const selectableCount = Object.keys(data).reduce(
      (acc, centerServiceKey) => {
        const centerService = data[centerServiceKey];

        if (!centerService) return acc;
        const isDual =
          centerService.groupId === "single-offering" &&
          centerService.scanSide === "dual";

        return isDual ? acc + 2 : acc + 1;
      },
      0,
    );

    return selectedOptions.length !== selectableCount;
  }, [centerServices, selectedOptions]);

  const addCenterService = useCallback(() => {
    onCenterServicesChange((prev) => [...prev, null]);
  }, [onCenterServicesChange]);

  const removeCenterService = useCallback(
    (index: number) => {
      onCenterServicesChange((prev) => {
        const list = prev.filter((_, idx) => idx !== index);
        return list.length ? list : [null];
      });
    },
    [onCenterServicesChange],
  );

  const changeCenterService = useCallback(
    (index: number) => {
      return (option: SelectCenterServiceInputOption) => {
        const { status, data } = centerServices;

        if (status !== "success") {
          return;
        }

        const { value, groupId } = option;

        const centerService = data[value];

        if (!centerService) {
          onCenterServicesChange((prev) =>
            prev.map((item, idx) => (idx === index ? null : item)),
          );
          return;
        }

        const { name, price, discountPrice, depositAmount, durationMinutes } =
          centerService;

        if (!option) {
          onCenterServicesChange((prev) =>
            prev.map((item, idx) => (idx === index ? null : item)),
          );
        }

        const selectedCenterService: SelectedCenterService = {
          value,
          groupId: groupId as CenterServiceGroupId,
          name,
          price,
          discountPrice,
          depositAmount,
          durationMinutes,
        };

        onCenterServicesChange((prev) =>
          prev.map((item, idx) =>
            idx === index ? selectedCenterService : item,
          ),
        );
      };
    },
    [centerServices, onCenterServicesChange],
  );

  const changeSelectedSide = useCallback(
    (index: number) => {
      return (event: SelectChangeEvent<string | number | boolean | null>) => {
        onCenterServicesChange((prev) =>
          prev.map((item, idx) => {
            if (idx !== index || !item) return item;

            return {
              ...item,
              side: event.target.value as SelectedOfferingSide,
            };
          }),
        );
      };
    },
    [onCenterServicesChange],
  );

  return {
    options,
    selectedOptions,
    isCanCreateMore,
    addCenterService,
    removeCenterService,
    changeCenterService,
    changeSelectedSide,
  };
};

export default useCenterServiceOptions;
