import React, { FC } from "react";
import { useField } from "formik";
import Radio from "../Radio";
import RadioGroup from "../RadioGroup";
import FormControlLabel from "../FormControlLabel";
import FormControl from "../FormControl";
import FormHelperText from "../FormHelperText";

export type FormikRadioGroupOption = {
  value: string;
  label: string;
  onClick?: () => void;
};

interface FormikRadioGroupProps {
  name: string;
  isDisabled?: boolean;
  options: FormikRadioGroupOption[];
}

const FormikRadioGroup: FC<FormikRadioGroupProps> = ({
  name,
  isDisabled,
  options,
}) => {
  const [field, meta, helper] = useField(name);
  const { onChange } = field;
  const { touched, error } = meta;
  const { setValue, setTouched } = helper;

  return (
    <FormControl error={touched && Boolean(error)}>
      <RadioGroup name={name} onChange={onChange} row>
        {options.map((option) => {
          const { value, label, onClick: onClickUserAction } = option;

          const onClick = () => {
            setValue(value);
            setTouched(true);
            if (onClickUserAction) onClickUserAction();
          };

          return (
            <FormControlLabel
              key={value}
              value={value}
              label={label}
              control={<Radio />}
              disabled={isDisabled}
              onClick={onClick}
            />
          );
        })}
      </RadioGroup>
      {touched && Boolean(error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default FormikRadioGroup;
