import React, { FC } from "react";
import { useIntl } from "react-intl";

import isSameMinute from "date-fns/isSameMinute";
import Button from "../Button";
import Grid from "../Grid";

export type AvailabilityCalendarTimeSlotsProps = {
  availableTimeSlots: Date[];
  selectedTimeSlot?: Date | null;
  onTimeSlotChange: (value: Date) => void;
  isDisabled?: boolean;
};

const AvailabilityCalendarTimeSlots: FC<AvailabilityCalendarTimeSlotsProps> = ({
  availableTimeSlots,
  selectedTimeSlot,
  onTimeSlotChange,
  isDisabled,
}) => {
  const intl = useIntl();

  return (
    <Grid container spacing={3}>
      {availableTimeSlots.map((availableTimeSlot) => {
        const isSelected =
          selectedTimeSlot && isSameMinute(availableTimeSlot, selectedTimeSlot);

        const buttonText = intl.formatTime(availableTimeSlot);

        return (
          <Grid item xs={6} key={buttonText}>
            <Button
              variant={isSelected ? "contained" : "outlined"}
              onClick={() => onTimeSlotChange(availableTimeSlot)}
              disabled={isDisabled}
              fullWidth
            >
              {buttonText}
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AvailabilityCalendarTimeSlots;
