import { useIntl } from "react-intl";
import messages from "./messages";
import commonMessages from "../../../common/messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = {
    buttonDesktopSubmitText: formatMessage(messages.buttonDesktopSubmitText),
    buttonMobileSubmitText: formatMessage(messages.buttonMobileSubmitText),
    submitDisabledError: formatMessage(messages.submitDisabledError),
    timeSlotNotAvailableError: formatMessage(
      messages.timeSlotNotAvailableError,
    ),
    defaultError: formatMessage(commonMessages.defaultError),
  };

  return { translations };
};

export default useTranslations;
