import { defineMessages } from "react-intl";

export default defineMessages({
  cash: {
    id: "Payment.AppointmentStripePaymentSection.cash",
    defaultMessage: "Cash",
  },
  card: {
    id: "Payment.AppointmentStripePaymentSection.card",
    defaultMessage: "Card",
  },
  method: {
    id: "Payment.AppointmentStripePaymentSection.method",
    defaultMessage: "Method",
  },
  amount: {
    id: "Payment.AppointmentStripePaymentSection.amount",
    defaultMessage: "Amount",
  },
  totalPrice: {
    id: "Payment.AppointmentStripePaymentSection.totalPrice",
    defaultMessage: "Total price",
  },
  remainingBalance: {
    id: "Payment.AppointmentStripePaymentSection.remainingBalance",
    defaultMessage: "Remaining balance",
  },
  methodIsRequired: {
    id: "Payment.AppointmentStripePaymentSection.methodIsRequired",
    defaultMessage: "Payment method is required",
  },
  paymentFailed: {
    id: "Payment.AppointmentStripePaymentSection.paymentFailed",
    defaultMessage: "Payment failed",
  },
  invalidAmount: {
    id: "Payment.AppointmentStripePaymentSection.invalidAmount",
    defaultMessage:
      "Amount must be greater than 0 and less than the remaining balance",
  },
  timeoutExceeded: {
    id: "Payment.AppointmentStripePaymentSection.timeoutExceeded",
    defaultMessage: "Timeout exceeded, please try again",
  },
  confirmPayment: {
    id: "Payment.AppointmentStripePaymentSection.confirmPayment",
    defaultMessage: "Confirm Payment",
  },
});
