import { defineMessages } from "react-intl";

export default defineMessages({
  btnChange: {
    id: "Center.SelectCenterForm.btnChange",
    defaultMessage: "Change",
  },
  btnBook: {
    id: "Center.SelectCenterForm.btnBook",
    defaultMessage: "Book here",
  },
});
