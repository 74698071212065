import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import Dialog from "../../Unknown/Dialog";
import DialogContent from "../../Unknown/DialogContent";
import DialogActions from "../../Unknown/DialogActions";
import DialogTitle from "../../Unknown/DialogTitle";
import Button from "../../Unknown/Button";
import Box from "../../Unknown/Box";
import messages from "./messages";
import CountdownTimer, {
  CountdownTimerProps,
} from "../../Unknown/CountdownTimer";

export type AppointmentDialogRequestTimeProps = {
  isOpen: boolean;
  onClose: () => Promise<void> | void;
  isLoading: boolean;
  onConfirmClick: () => Promise<void> | void;
  onCancelClick: () => Promise<void> | void;
  countdownTimerProps: CountdownTimerProps;
};

const AppointmentDialogRequestTime: FC<AppointmentDialogRequestTimeProps> = ({
  isOpen,
  onClose,
  isLoading,
  onConfirmClick,
  onCancelClick,
  countdownTimerProps,
}) => {
  const { title, desc, cancelButtonTitle, confirmButtonTitle } = messages;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage {...title} />
      </DialogTitle>

      <DialogContent>
        <FormattedMessage {...desc} />

        <Box
          mt={5}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
        >
          <CountdownTimer {...countdownTimerProps} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick} disabled={isLoading}>
          <FormattedMessage {...cancelButtonTitle} />
        </Button>
        <Button onClick={onConfirmClick} isLoading={isLoading}>
          <FormattedMessage {...confirmButtonTitle} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppointmentDialogRequestTime;
