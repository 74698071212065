import { useIntl } from "react-intl";
import messages from "./messages";
import commonMessages from "../../../common/messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = {
    buttonDesktopSubmitText: formatMessage(messages.buttonDesktopSubmitText),
    buttonMobileSubmitText: formatMessage(messages.buttonMobileSubmitText),
    defaultError: formatMessage(commonMessages.defaultError),
    saveAppointment: formatMessage(messages.saveAppointment),
    emailCanNotBeUsed: formatMessage(messages.emailCanNotBeUsed),
  };

  return { translations };
};

export default useTranslations;
