import { useIntl, FormatDateOptions } from "react-intl";
import { getDateFromMinutes } from "../../../common/formattedMinutes";
import messages from "./messages";

const dateFormatOptions: FormatDateOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

const useTranslations = ({
  selectedDate,
  selectedTimeSlot,
}: {
  selectedDate: Date;
  selectedTimeSlot: number;
}) => {
  const { formatMessage, formatDate, formatTime } = useIntl();

  const translations = {
    locationTitle: formatMessage(messages.locationTitle),
    dateTitle: formatMessage(messages.dateTitle),
    dateText: formatMessage(messages.dateText, {
      date: formatDate(selectedDate, dateFormatOptions),
      time: formatTime(getDateFromMinutes(selectedTimeSlot)),
    }),
    patientTitle: formatMessage(messages.patientTitle),
    studiesTitle: formatMessage(messages.studiesTitle),
    sideLeft: formatMessage(messages.sideLeft),
    sideRight: formatMessage(messages.sideRight),
    labelFax: formatMessage(messages.labelFax),
    labelPhone: formatMessage(messages.labelPhone),
  };

  return { translations };
};

export default useTranslations;
