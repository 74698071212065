import { useState, useEffect, useCallback } from "react";
import { useSessionStorage } from "react-use";
import getTime from "date-fns/getTime";
import { useNavigate } from "react-router-dom";

import isBefore from "date-fns/isBefore";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { CheckoutAppointment } from "../AppointmentCheckoutScreen/parseCheckoutAppointment";
import {
  OVERRIDDEN_DEPOSIT_KEY,
  OVERRIDDEN_PRICE_KEY,
} from "../../../common/constants";

const useAppointmentCheckoutTimer = (params: {
  appointmentId?: string;
  appointmentData: CheckoutAppointment | null;
}) => {
  const [, setOverriddenPrice] = useSessionStorage(OVERRIDDEN_PRICE_KEY);
  const [, setOverriddenDeposit] = useSessionStorage(OVERRIDDEN_DEPOSIT_KEY);
  const { appointmentId, appointmentData } = params;

  const navigate = useNavigate();

  const resetAppointmentCheckoutDuration = useFirebaseAppFunction(
    "resetAppointmentCheckoutDuration",
  );

  const [isAddTimeLoading, setIsAddTimeLoading] = useState(false);

  const [expiresAt, setExpiresAt] = useState<number | null>(null);
  const [repeatCheckoutCounter, setRepeatCheckoutCounter] = useState<
    number | null
  >(null);

  useEffect(() => {
    if (!appointmentId) {
      navigate("/");
    }

    if (!appointmentData) return;

    if (appointmentData.status !== "checkout") {
      navigate("/");
    }

    if (isBefore(appointmentData.timeSlot.expiresAt, Date.now())) {
      navigate("/");
    }
  }, [appointmentData, appointmentId, navigate]);

  useEffect(() => {
    if (!appointmentData) return;

    setExpiresAt(getTime(appointmentData.timeSlot.expiresAt));
    setRepeatCheckoutCounter(appointmentData.checkoutRepeatCount);
  }, [appointmentData]);

  const onConfirmClick = useCallback(async () => {
    if (!appointmentId) return;

    try {
      setIsAddTimeLoading(true);

      const { data } = await resetAppointmentCheckoutDuration({
        appointmentId,
      });

      setExpiresAt(data.expiresAt);
      setRepeatCheckoutCounter(data.checkoutRepeatCount);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert((error as Error).message);
    } finally {
      setIsAddTimeLoading(false);
    }
  }, [appointmentId, resetAppointmentCheckoutDuration]);

  const onAppointmentExpired = useFirebaseAppFunction("onAppointmentExpired");

  const onCountdownEnd = useCallback(async () => {
    if (appointmentId) {
      setOverriddenPrice("");
      setOverriddenDeposit("");
      await onAppointmentExpired({ appointmentId });
    }
    navigate("/");
  }, [
    appointmentId,
    navigate,
    onAppointmentExpired,
    setOverriddenDeposit,
    setOverriddenPrice,
  ]);

  if (
    !appointmentData ||
    expiresAt === null ||
    repeatCheckoutCounter === null
  ) {
    return null;
  }

  return {
    expiresAt,
    repeatCheckoutCounter,
    isAddTimeLoading,
    onConfirmClick,
    onCountdownEnd,
    durationInSeconds:
      appointmentData.center.appointmentCheckoutDurationSeconds,
    maxRepeatCount: appointmentData.center.appointmentCheckoutMaxRepeatCount,
  };
};

export default useAppointmentCheckoutTimer;
