import React from "react";
import { FunctionsProvider, AuthProvider, useFirebaseApp } from "reactfire";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { connectAuthEmulator, getAuth } from "firebase/auth";

type FirebaseSDKProvidersProps = {
  children?: React.ReactNode;
};

const FirebaseSDKProviders: React.FC<FirebaseSDKProvidersProps> = ({
  children,
}) => {
  const {
    REACT_APP_EMULATOR_FUNCTIONS_HOST,
    REACT_APP_EMULATOR_FUNCTIONS_PORT,
    REACT_APP_EMULATOR_AUTH_URL,
  } = process.env;

  const app = useFirebaseApp();
  const functions = getFunctions(app);
  const auth = getAuth(app);

  if (
    REACT_APP_EMULATOR_FUNCTIONS_HOST &&
    REACT_APP_EMULATOR_FUNCTIONS_PORT &&
    !!Number(REACT_APP_EMULATOR_FUNCTIONS_PORT)
  ) {
    connectFunctionsEmulator(
      functions,
      REACT_APP_EMULATOR_FUNCTIONS_HOST,
      Number(REACT_APP_EMULATOR_FUNCTIONS_PORT),
    );
  }

  if (REACT_APP_EMULATOR_AUTH_URL) {
    connectAuthEmulator(auth, REACT_APP_EMULATOR_AUTH_URL);
  }

  return (
    <FunctionsProvider sdk={functions}>
      <AuthProvider sdk={auth}>{children}</AuthProvider>
    </FunctionsProvider>
  );
};

export default FirebaseSDKProviders;
