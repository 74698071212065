import React, { FC, useCallback, useMemo, useState } from "react";

import AvailabilityCalendarMonth, {
  AvailabilityCalendarMonthView,
} from "../../Unknown/AvailabilityCalendarMonth";
import { useAppointmentBookingContext } from "../AppointmentBookingContext";
import objectKeys from "../../../common/objectKeys";
import { useAuthContext } from "../../Auth/AuthContextProvider";
import { getMonthDayList } from "../../../common/dateHelpers";

const initialView = "day";

const AppointmentDateSelecting: FC = () => {
  const {
    selectedDate,
    selectedMonth,
    onDateChange,
    isSubmitLoading,
    onMonthChange,
    availableTimeSlots,
  } = useAppointmentBookingContext();

  const { isCenterUser } = useAuthContext();

  const [currentView, setCurrentView] =
    useState<AvailabilityCalendarMonthView>(initialView);

  const availableDays = useMemo<number[]>(() => {
    try {
      if (isCenterUser) {
        return getMonthDayList(selectedMonth);
      }

      if (
        availableTimeSlots.status !== "success" ||
        !availableTimeSlots.data.currentMonthTimeSlots
      ) {
        return [];
      }

      return objectKeys(availableTimeSlots.data.currentMonthTimeSlots).map(
        Number,
      );
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert((e as Error).message);
      return [];
    }
  }, [
    availableTimeSlots.data?.currentMonthTimeSlots,
    availableTimeSlots.status,
    isCenterUser,
    selectedMonth,
  ]);

  const onChange = useCallback<typeof onDateChange>(
    (...args) => {
      if (currentView === "day") {
        onDateChange(...args);
      }
    },
    [currentView, onDateChange],
  );

  const minDate = isCenterUser
    ? undefined
    : availableTimeSlots.dateLimits?.minCenterTimezone;

  return (
    <AvailabilityCalendarMonth
      availableDays={availableDays}
      value={selectedDate}
      onChange={onChange}
      onMonthChange={onMonthChange}
      onYearChange={onMonthChange}
      disabled={isSubmitLoading}
      loading={availableTimeSlots.status === "loading"}
      defaultCalendarMonth={selectedMonth}
      onViewChange={setCurrentView}
      openTo={initialView}
      views={["month", "year", "day"]}
      maxDate={availableTimeSlots.dateLimits?.maxCenterTimezone}
      minDate={minDate}
    />
  );
};

export default AppointmentDateSelecting;
