import React, { FC } from "react";
import differenceInMinutes from "date-fns/differenceInMinutes";
import startOfDay from "date-fns/startOfDay";
import useTranslations from "./useTranslations";
import Box from "../../Unknown/Box";
import TableInfoBlock, { BlockData } from "../../Unknown/TableInfoBlock";
import { ConfirmedAppointment } from "../AppointmentConfirmedScreen/parseConfirmedAppointment";
import getCenterAddress from "../../../common/getCenterAddress";
import { formatInternational } from "../../../common/phoneNumberHelpers";

type AppointmentBookedInformationProps = {
  appointment: ConfirmedAppointment;
};

const AppointmentBookedInformation: FC<AppointmentBookedInformationProps> = ({
  appointment,
}) => {
  const { center, patientDetails, timeSlot, packages, addOns, offerings } =
    appointment;

  const selectedDate = timeSlot.startedAt;

  const selectedTimeSlot = differenceInMinutes(
    selectedDate,
    startOfDay(selectedDate),
  );

  const { translations } = useTranslations({ selectedDate, selectedTimeSlot });
  const {
    locationTitle,
    dateTitle,
    dateText,
    patientTitle,
    studiesTitle,
    labelFax,
    labelPhone,
  } = translations;

  const sideTranslations: Record<string, string> = {
    left: translations.sideLeft,
    right: translations.sideRight,
  };

  const centerPhoneNumber = center.phoneNumber
    ? formatInternational({ phoneNumber: center.phoneNumber })
    : "";

  const centerFaxNumber = center.faxNumber
    ? formatInternational({ phoneNumber: center.faxNumber })
    : "";

  const locationData: BlockData[] = [
    { text: center.name, isBold: true },
    { text: getCenterAddress(center) },
    {
      text: centerPhoneNumber,
      prefix: labelPhone,
      href: `tel:${center.phoneNumber}`,
    },
    {
      text: centerFaxNumber,
      prefix: labelFax,
      href: `tel:${center.faxNumber}`,
    },
    { text: center.email, href: `mailto:${center.email}` },
  ];

  const dateData: BlockData[] = [{ text: dateText }];

  const patientPhoneNumber = patientDetails.mobilePhoneNumber
    ? formatInternational({ phoneNumber: patientDetails.mobilePhoneNumber })
    : "";

  const patientData: BlockData[] = [
    {
      text: `${patientDetails.firstName} ${patientDetails.lastName}`,
      isBold: true,
    },
    { text: patientDetails.email || "" },
    { text: patientPhoneNumber },
  ].filter((blockData) => blockData.text);

  const offeringsData: BlockData[] = offerings.map((offeringItem) => {
    const { name, side } = offeringItem;

    const sideTranslation = side ? sideTranslations[side] : "";

    if (!sideTranslation) {
      return { text: name };
    }

    return { text: `${name} (${sideTranslation})` };
  });

  const studiesData: BlockData[] = [
    ...packages.map((packageItem) => ({ text: packageItem.name })),
    ...offeringsData,
    ...addOns.map((addOn) => ({ text: addOn.name })),
  ];

  const blocksList = [
    [
      { title: locationTitle, data: locationData },
      { title: dateTitle, data: dateData },
    ],
    [
      { title: patientTitle, data: patientData },
      { title: studiesTitle, data: studiesData },
    ],
  ];

  return (
    <Box>
      {blocksList.map((blocks, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={`item-${idx}`} mb={7}>
          <TableInfoBlock blocks={blocks} />
        </Box>
      ))}
    </Box>
  );
};

export default AppointmentBookedInformation;
