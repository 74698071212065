import { makeStyles } from "@mui/styles";
import { alpha, Theme } from "@mui/material";

export default makeStyles<Theme>(({ palette }) => ({
  titleRoot: {
    letterSpacing: 1,
    textTransform: "uppercase",
    color: alpha(palette.common.black, 0.6),
    fontSize: 12,
  },
  linkRoot: {
    textDecoration: "none",
  },
}));
