import React, { FC, useState, useEffect, useCallback } from "react";
import differenceInSeconds from "date-fns/differenceInSeconds";
import Big from "big.js";
import CountdownTimer from "../../Unknown/CountdownTimer";
import Box from "../../Unknown/Box";
import Paper from "../../Unknown/Paper";
import Typography from "../../Unknown/Typography";
import useStyles from "./useStyles";
import useTranslations from "./useTranslations";
import useCountdownTimer from "../../../hooks/useCountdownTimer";
import getCountdownTimerProps from "./getCountdownTimerProps";
import AppointmentDialogRequestTime from "../AppointmentDialogRequestTime";

const warningDurationInSeconds = 60;

type AppointmentCheckoutTimerProps = {
  expiresAt: number;
  durationInSeconds: number;
  repeatCheckoutCounter: number;
  maxRepeatCount: number;
  isAddTimeLoading: boolean;
  onConfirmClick: () => void | Promise<void>;
  onCountdownEnd: () => void | Promise<void>;
};

const AppointmentCheckoutTimer: FC<AppointmentCheckoutTimerProps> = ({
  expiresAt,
  durationInSeconds,
  repeatCheckoutCounter,
  maxRepeatCount,
  isAddTimeLoading,
  onConfirmClick,
  onCountdownEnd,
}) => {
  const { paperRoot } = useStyles();

  const reservedMinutes = Big(durationInSeconds || 0)
    .div(60)
    .round(0, Big.roundDown)
    .toNumber();

  const {
    translations: { title },
  } = useTranslations({ reservedMinutes });

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [noClicked, setNoClicked] = useState(false);
  const onClose = () => setIsDialogOpen(false);

  const onCancelClick = () => {
    setNoClicked(true);
    setIsDialogOpen(false);
  };

  const { time } = useCountdownTimer({ expiresAt });

  useEffect(() => {
    if (
      differenceInSeconds(expiresAt, Date.now()) >= warningDurationInSeconds
    ) {
      setNoClicked(false);
      setIsDialogOpen(false);
    }
  }, [expiresAt]);

  const checkTriggers = useCallback(async () => {
    if (isAddTimeLoading) return;

    const shouldOpenWarningDialog =
      !noClicked &&
      !isDialogOpen &&
      time <= warningDurationInSeconds &&
      repeatCheckoutCounter < maxRepeatCount;

    if (shouldOpenWarningDialog) {
      setIsDialogOpen(true);
    }

    if (time <= 0) {
      await onCountdownEnd();
    }
  }, [
    isAddTimeLoading,
    noClicked,
    isDialogOpen,
    time,
    repeatCheckoutCounter,
    maxRepeatCount,
    onCountdownEnd,
  ]);

  useEffect(() => {
    checkTriggers();
  }, [checkTriggers]);

  const { text, checkoutValue, warningValue } = getCountdownTimerProps({
    timeToEnd: time,
    checkoutDurationSeconds: durationInSeconds,
    warningDurationSeconds: warningDurationInSeconds,
  });

  return (
    <>
      <Box mb={8}>
        <Paper classes={{ root: paperRoot }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexGrow={1}
            p={2}
            flexDirection="column"
          >
            <Typography mb={3}>{title}</Typography>
            <CountdownTimer text={text} value={checkoutValue} />
          </Box>
        </Paper>
      </Box>
      <AppointmentDialogRequestTime
        isOpen={isDialogOpen}
        onClose={onClose}
        isLoading={isAddTimeLoading}
        onConfirmClick={onConfirmClick}
        onCancelClick={onCancelClick}
        countdownTimerProps={{ text, value: warningValue }}
      />
    </>
  );
};

export default AppointmentCheckoutTimer;
