/* eslint-disable @typescript-eslint/naming-convention */
import { PostgrestSingleResponse } from "@supabase/supabase-js";
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import useDataState from "../../../hooks/useDataState";
import useSupabase from "../../../hooks/useSupabase";
import useSystemConfig from "../../../hooks/useSystemConfig";
import parseCheckoutAppointment, {
  CheckoutAppointment,
  SupabaseCheckoutAppointment,
  supabaseCheckoutAppointmentRequest,
} from "./parseCheckoutAppointment";

const useCheckoutAppointment = (id?: string) => {
  const supabase = useSupabase();

  const { formatMessage } = useIntl();

  const systemConfig = useSystemConfig();

  const { dataState, updateDataState } = useDataState<CheckoutAppointment>();

  const getAppointmentsRequest = useCallback(async () => {
    try {
      const {
        data: systemConfigData,
        status: systemConfigStatus,
        error: systemConfigError,
      } = systemConfig;

      if (!id) {
        throw new Error("Appointment is not found");
      }

      updateDataState({ status: "loading" });

      if (systemConfigStatus === "loading") {
        return;
      }

      if (systemConfigStatus === "error") {
        throw new Error(systemConfigError);
      }

      const { data, error } = (await supabase
        .from("appointments")
        .select(supabaseCheckoutAppointmentRequest)
        .eq("id", id)
        .limit(1, { foreignTable: "center.center_configs" })
        .is("appointment_offerings.appointment_package_id", null)
        .single()) as PostgrestSingleResponse<SupabaseCheckoutAppointment>;

      if (error) {
        throw new Error(formatMessage(commonMessages.defaultError));
      }

      try {
        const parsedAppointment = parseCheckoutAppointment(
          data,
          systemConfigData,
        );

        updateDataState({ status: "success", data: parsedAppointment });
      } catch (err) {
        throw new Error(formatMessage(commonMessages.defaultError));
      }
    } catch (err) {
      updateDataState({ status: "error", error: (err as Error).message });
    }
  }, [id, supabase, systemConfig, updateDataState, formatMessage]);

  useEffect(() => {
    getAppointmentsRequest();
  }, [getAppointmentsRequest]);

  return dataState;
};

export default useCheckoutAppointment;
