import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = {
    yourInformationHeader: formatMessage(messages.yourInformationHeader),
    emailLabel: formatMessage(messages.emailLabel),
    phoneNumberLabel: formatMessage(messages.phoneNumberLabel),
    firstNameLabel: formatMessage(messages.firstNameLabel),
    lastNameLabel: formatMessage(messages.lastNameLabel),
    patientInformationHeader: formatMessage(messages.patientInformationHeader),
    patientInformationDesc: formatMessage(messages.patientInformationDesc),
    dateOfBirthLabel: formatMessage(messages.dateOfBirthLabel),
    dateOfBirthPlaceholder: formatMessage(messages.dateOfBirthPlaceholder),
    pregnantHeader: formatMessage(messages.pregnantHeader),
    answerYes: formatMessage(messages.answerYes),
    answerNo: formatMessage(messages.answerNo),
    wearingDeviceHeader: formatMessage(messages.wearingDeviceHeader),
    checkUpToDateData: formatMessage(messages.checkUpToDateData),
    mobilePhoneNumberHelper: formatMessage(messages.mobilePhoneNumberHelper),
    dateOfBirthHelper: formatMessage(messages.dateOfBirthHelper),
    authErrorMessage: formatMessage(messages.authErrorMessage),
  };

  return translations;
};

export default useTranslations;
