import React, { FC } from "react";

import Button, { ButtonProps } from "../Button";
import Typography from "../Typography";
import { AddCircleOutlineIcon } from "../Icons";

import useStyles from "./useStyles";

interface AddInputButtonProps extends ButtonProps {
  text: string;
}

const AddInputButton: FC<AddInputButtonProps> = ({ text, ...buttonProps }) => {
  const classes = useStyles();

  return (
    <Button
      {...buttonProps}
      startIcon={<AddCircleOutlineIcon />}
      classes={{ root: classes.button, startIcon: classes.icon }}
    >
      <Typography textTransform="none" variant="body1" color="text.secondary">
        {text}
      </Typography>
    </Button>
  );
};

export default AddInputButton;
