import React, { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import Big from "big.js";
import CircularProgress from "../../Unknown/CircularProgress";
import Box from "../../Unknown/Box";
import AppointmentLayout from "../../Appointment/AppointmentLayout";
import useCheckoutAppointment from "../../Appointment/AppointmentCheckoutScreen/useCheckoutAppointment";
import AppointmentCheckoutTimer from "../../Appointment/AppointmentCheckoutTimer";
import useAppointmentCheckoutTimer from "../../Appointment/AppointmentCheckoutTimer/useAppointmentCheckoutTimer";
import {
  CenterUserAppointmentStripePaymentSection,
  CustomerAppointmentStripePaymentSection,
} from "../AppointmentStripePaymentSection";
import useDeviceType from "../../../hooks/useDeviceType";
import { useAuthContext } from "../../Auth/AuthContextProvider";

const AppointmentStripePaymentScreen: FC = () => {
  const { appointmentId } = useParams();
  const { isMobile } = useDeviceType();
  const { isCenterUser } = useAuthContext();

  const { data: appointmentData, status: appointmentStatus } =
    useCheckoutAppointment(appointmentId);

  const priceWithDiscount = useMemo(() => {
    if (appointmentStatus !== "success") return 0;
    const { totalCostAmount, discountAmount } = appointmentData;

    return Big(totalCostAmount).minus(discountAmount).toNumber();
  }, [appointmentData, appointmentStatus]);

  const appointmentCheckoutTimerProps = useAppointmentCheckoutTimer({
    appointmentId,
    appointmentData,
  });

  if (!appointmentId) {
    return null;
  }

  if (appointmentStatus !== "success" || !appointmentCheckoutTimerProps) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        mt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  const {
    center,
    offerings,
    packages,
    addOns,
    timeSlot,
    depositAmount,
    discountAmount,
  } = appointmentData;

  return (
    <AppointmentLayout
      selectedCenter={center}
      selectedDateTime={timeSlot.startedAt}
      selectedCenterServices={[...offerings, ...packages]}
      selectedAddOns={addOns}
      depositAmount={depositAmount}
      discountSum={discountAmount}
      priceWithDiscount={priceWithDiscount}
    >
      <AppointmentCheckoutTimer {...appointmentCheckoutTimerProps} />
      <Box maxWidth={500} mt={2} mx={isMobile ? "auto" : undefined}>
        {!!isCenterUser && (
          <CenterUserAppointmentStripePaymentSection
            depositAmount={appointmentData?.depositAmount}
            totalPrice={priceWithDiscount}
            currencyCode={appointmentData?.center?.defaultCurrencyCode}
            appointment={appointmentData}
          />
        )}
        {!isCenterUser && (
          <CustomerAppointmentStripePaymentSection
            depositAmount={appointmentData?.depositAmount}
            currencyCode={appointmentData?.center?.defaultCurrencyCode}
          />
        )}
      </Box>
    </AppointmentLayout>
  );
};

export default AppointmentStripePaymentScreen;
