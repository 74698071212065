import { defineMessages } from "react-intl";

export default defineMessages({
  searchLabel: {
    id: "Customer.CustomerSearchInput.searchLabel",
    defaultMessage: "Search for existing customer",
  },
  searchErrorMessage: {
    id: "Customer.CustomerSearchInput.searchErrorMessage",
    defaultMessage: "Failed to fetch customers",
  },
});
