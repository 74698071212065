import React from "react";
import { parseISO, format } from "date-fns";
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import Typography from "../../Unknown/Typography";
import Box from "../../Unknown/Box";
import MenuItem from "../../Unknown/MenuItem";
import { formatInternational } from "../../../common/phoneNumberHelpers";

const getCustomerMenuItem = ({
  selectedId,
  customers,
  props,
}: {
  selectedId: string;
  customers: DatabaseRow<"customers">[];
  props: React.HTMLAttributes<HTMLLIElement>;
}): React.ReactNode => {
  const customer = customers.find(({ id }) => id === selectedId);

  if (!customer) return null;

  const customerName = customer
    ? `${customer.last_name}, ${customer.first_name}`
    : "";

  const formattedNumber = formatInternational({
    phoneNumber: customer.mobile_phone_number,
  });

  const dateOfBirth = customer.date_birth
    ? parseISO(customer.date_birth)
    : null;

  const formattedDate = dateOfBirth ? format(dateOfBirth, "MM/dd/yyyy") : null;

  return (
    <MenuItem {...props} key={customer.id}>
      <Box>
        <Typography>{customerName}</Typography>
        {!!formattedDate && (
          <Typography variant="body2">{formattedDate}</Typography>
        )}
        <Typography variant="body2">{customer.email}</Typography>
        {!!formattedNumber && (
          <Typography variant="body2">{formattedNumber}</Typography>
        )}
      </Box>
    </MenuItem>
  );
};

export default getCustomerMenuItem;
