import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "Center.CenterContactInformationPreview.title",
    defaultMessage: "Contact information",
  },
  labelFax: {
    id: "Center.CenterContactInformationPreview.labelFax",
    defaultMessage: "Fax",
  },
  labelPhone: {
    id: "Center.CenterContactInformationPreview.labelPhone",
    defaultMessage: "Phone",
  },
});
