import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import { ITheme } from "../../../common/theme";

type StyleProps = {
  isOpen?: boolean;
};

export default makeStyles<ITheme, StyleProps>(({ palette }) => ({
  headerBoxRoot: {
    backgroundColor: palette.primary.main,
    color: palette.common.white,
  },
  pullerBoxRoot: {
    width: 30,
    height: 6,
    backgroundColor: alpha(palette.common.black, 0.2),
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
  },
  checkoutButtonRoot: {
    backgroundColor: palette.common.white,
    color: palette.primary.main,
    pointerEvents: "all",
    "&.Mui-disabled": {
      backgroundColor: alpha(palette.common.white, 0.6),
      color: palette.primary.main,
    },
  },
}));
