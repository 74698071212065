import React, { useState, SyntheticEvent, useCallback } from "react";
import ReactGA from "react-ga4";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import Button from "../../Unknown/Button";
import Box from "../../Unknown/Box";
import useTranslations from "./useTranslations";
import { GAEventCategory } from "../../../common/constants";

const StripePaymentElement = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { appointmentId } = useParams();

  const { translations } = useTranslations();
  const { submitButtonText } = translations;

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    async (event: SyntheticEvent) => {
      ReactGA.event({
        action: "Click payment submit",
        category: GAEventCategory.click,
      });
      setIsLoading(true);
      try {
        event.preventDefault();

        if (!stripe || !elements) {
          return;
        }

        const result = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${window.location.origin}/${appointmentId}/success`,
          },
        });

        if (result.error) {
          throw new Error(result.error.message);
        }
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    },
    [appointmentId, elements, stripe],
  );

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Box mt={3}>
        <Button
          isLoading={isLoading}
          fullWidth
          variant="contained"
          disabled={!stripe && !elements}
          type="submit"
          size="large"
        >
          {submitButtonText}
        </Button>
      </Box>
    </form>
  );
};

export default StripePaymentElement;
