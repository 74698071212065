export const DEFAULT_CURRENCY_CODE = "USD";
export const DEFAULT_REGION = "us-central1";
export const FUNCTIONS_PREFIX = "bookingApp";

export const MIN_STRING_INPUT_LENGTH = 2;
export const MAX_STRING_INPUT_LENGTH = 100;

export const EMAIL_REG_EXP = /^[\w-+.]+@([\w-]+.)+[\w-]{2,}$/;
export const MAX_PHONE_NUMBER_LEN = 10;
export const MIN_PHONE_NUMBER_LEN = 10;

export const SUPABASE_TOKEN = "SUPABASE_TOKEN";
export const SUPABASE_CENTER_USER_TOKEN_KEY = "SUPABASE_CENTER_USER_TOKEN";
export const OVERRIDDEN_PRICE_KEY = "OVERRIDDEN_PRICE";
export const OVERRIDDEN_DEPOSIT_KEY = "OVERRIDDEN_DEPOSIT";
export const OVERRIDDEN_TIME_KEY = "OVERRIDDEN_START_TIME";

export enum GAEventCategory {
  click = "click",
  pageView = "page view",
}
