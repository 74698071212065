import { createTheme, Theme, ThemeOptions } from "@mui/material";
import { alpha } from "@mui/system";

interface BorderRadius {
  s: number;
  m: number;
}
export interface ITheme extends Theme {
  borderRadius: BorderRadius;
}

interface IThemeOptions extends ThemeOptions {
  borderRadius: BorderRadius;
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 480,
    md: 760,
    lg: 960,
    xl: 1200,
  },
};

const colors = {
  grey: "#DEDEDE",
};

const borderRadius = {
  s: 4,
  m: 8,
};

const defaultTheme = createTheme({
  spacing: 5,
  breakpoints,
  shape: { borderRadius: 4 },
});

const theme = createTheme({
  spacing: 5,
  breakpoints,
  borderRadius,
  shape: { borderRadius: 4 },
  palette: {
    grey: { 100: colors.grey },
  },
  typography: {
    fontFamily: "Roboto, Sans-serif",
    h2: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.33,
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.6,
    },
    h4: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.33,
    },
    h5: {
      fontSize: 11,
      fontWeight: 400,
      lineHeight: 1.66,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.66,
      color: defaultTheme.palette.text.secondary,
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1,
      letterSpacing: 0.15,
      color: defaultTheme.palette.text.secondary,
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.4,
      color: alpha(defaultTheme.palette.common.black, 0.87),
    },
    button: { letterSpacing: 0.5 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          paddingTop: defaultTheme.spacing(1.5),
          paddingRight: defaultTheme.spacing(4.5),
          paddingBottom: defaultTheme.spacing(1.5),
          paddingLeft: defaultTheme.spacing(4.5),
          fontSize: 15,
        },
        sizeMedium: {
          paddingTop: defaultTheme.spacing(1.2),
          paddingRight: defaultTheme.spacing(3.2),
          paddingBottom: defaultTheme.spacing(1.2),
          paddingLeft: defaultTheme.spacing(3.2),
          fontSize: 14,
        },
        root: {
          whiteSpace: "nowrap",
          minWidth: "auto",
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: defaultTheme.palette.common.white,
          color: defaultTheme.palette.text.primary,
          fontSize: 12,
          boxShadow: defaultTheme.shadows[8],
        },
        arrow: { color: defaultTheme.palette.common.white },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up("sm")]: {
            paddingLeft: defaultTheme.spacing(6),
            paddingRight: defaultTheme.spacing(6),
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.66,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
  },
} as IThemeOptions) as ITheme;

export default theme;
