const getAmplifyConfig = () => {
  const {
    REACT_APP_AWS_REGION,
    REACT_APP_AWS_USER_POOL_ID,
    REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  } = process.env;

  if (!REACT_APP_AWS_REGION) {
    throw new Error("Please provide REACT_APP_AWS_REGION in .env file");
  }
  if (!REACT_APP_AWS_USER_POOL_ID) {
    throw new Error("Please provide REACT_APP_AWS_USER_POOL_ID in .env file");
  }
  if (!REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID) {
    throw new Error(
      "Please provide REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID in .env file",
    );
  }

  return {
    Auth: {
      region: REACT_APP_AWS_REGION,
      userPoolId: REACT_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    },
  };
};

const amplifyConfig = getAmplifyConfig();

export default amplifyConfig;
