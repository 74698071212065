import { defineMessages } from "react-intl";

export default defineMessages({
  label: {
    id: "Center.SelectCenterServiceForm.label",
    defaultMessage: "Select MRI Scan",
  },
  removeBtn: {
    id: "Center.SelectCenterServiceForm.removeBtn",
    defaultMessage: "Remove",
  },
  addBtn: {
    id: "Center.SelectCenterServiceForm.addBtn",
    defaultMessage: "Add another MRI scan with {discountAmount} discount",
  },
  singleOfferingsTitle: {
    id: "Center.SelectCenterServiceForm.singleOfferingsTitle",
    defaultMessage: "Single Offerings",
  },
  packagesTitle: {
    id: "Center.SelectCenterServiceForm.packagesTitle",
    defaultMessage: "Packages",
  },
});
