import { useCallback, useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import max from "date-fns/max";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import min from "date-fns/min";
import parseISO from "date-fns/parseISO";
import { BookingCenterOperatingHours } from "./useBookingCenters";
import useDataState from "../../../hooks/useDataState";
import getBookingUnavailableTimeSlots from "./getBookingUnavailableTimeSlots";
import commonMessages from "../../../common/messages";
import getDateLimits from "./getDateLimits";
import getAvailableTimeSlots from "../../../helpers/appointmentOperatingHours/getAvailableTimeSlots";

type DateTimeSlots = {
  maxDurationMinutes: number;
  available: Date[];
};

type BookingAvailableTimeSlots = {
  currentMonthTimeSlots: Record<number, DateTimeSlots>;
  currentMonth: Date;
};

const useBookingAvailableTimeSlots = (params: {
  storageAppointmentId: string | null;
  center: {
    id: string;
    operatingHours: BookingCenterOperatingHours;
    calculatedMinimumTimeBeforeBookingSeconds: number;
    timezoneOffsetMilliseconds: number;
  } | null;
  date: Date | null;
  appointmentDuration: number;
}) => {
  const abortControllerRef = useRef<AbortController | null>(null);

  const { storageAppointmentId, center, date, appointmentDuration } = params;

  const { formatMessage } = useIntl();

  const { dataState, updateDataState } =
    useDataState<BookingAvailableTimeSlots>();

  const dateLimits = useMemo(() => {
    if (!center || !date) return null;

    const {
      calculatedMinimumTimeBeforeBookingSeconds,
      timezoneOffsetMilliseconds,
    } = center || {};

    return getDateLimits({
      timezoneOffsetMillis: timezoneOffsetMilliseconds,
      minTimeBeforeBookingSeconds: calculatedMinimumTimeBeforeBookingSeconds,
    });
  }, [center, date]);

  const updateAvailableTimeSlots = useCallback(async () => {
    try {
      if (abortControllerRef.current) abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();

      if (!center || !date || !dateLimits) return;

      const { id, operatingHours, timezoneOffsetMilliseconds } = center || {};

      updateDataState({ status: "loading" });

      const unavailableTimeSlots = await getBookingUnavailableTimeSlots({
        centerId: id,
        date,
        appointmentId: storageAppointmentId || undefined,
        abortSignal: abortControllerRef.current?.signal,
      });

      const centerTimeSlots = unavailableTimeSlots.map((timeSlot) => ({
        startedAt: parseISO(timeSlot.started_at),
        endedAt: parseISO(timeSlot.ended_at),
      }));

      const startOfMonthDate = startOfMonth(date);
      const endOfMonthDate = endOfMonth(date);

      const minDateTime = dateLimits.minDateTimeUTC;

      const availableTimeSlots = getAvailableTimeSlots({
        startDate: max([startOfMonthDate, minDateTime]),
        endDate: min([endOfMonthDate, dateLimits.maxDateTimeUTC]),
        operatingHours,
        centerTimeSlots,
        timezoneOffsetMillis: timezoneOffsetMilliseconds,
        appointmentDurationMinutes: appointmentDuration,
      });

      updateDataState({
        status: "success",
        data: {
          currentMonthTimeSlots: availableTimeSlots,
          currentMonth: date,
        },
      });
    } catch (error) {
      reportError(error);
      updateDataState({
        status: "error",
        error: formatMessage(commonMessages.defaultError),
      });
    }
  }, [
    appointmentDuration,
    center,
    date,
    dateLimits,
    storageAppointmentId,
    formatMessage,
    updateDataState,
  ]);

  useEffect(() => {
    updateAvailableTimeSlots();
  }, [updateAvailableTimeSlots]);

  return { ...dataState, dateLimits, updateAvailableTimeSlots };
};

export default useBookingAvailableTimeSlots;
