import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import { ITheme } from "../../../common/theme";

export default makeStyles<ITheme>((theme) => ({
  root: {
    border: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
    borderRadius: theme.borderRadius.s,
    overflowY: "auto",
    maxHeight: "inherit",
  },
}));
