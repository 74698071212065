import { defineMessages } from "react-intl";

export default defineMessages({
  yourInformationHeader: {
    id: "Appointment.AppointmentCheckoutUserForm.yourInformationHeader",
    defaultMessage: "Your information",
  },
  yourInformationDesc: {
    id: "Appointment.AppointmentCheckoutUserForm.yourInformationDesc",
    defaultMessage:
      "So we can send you a receipt and confirmation of the appointment",
  },
  emailLabel: {
    id: "Appointment.AppointmentCheckoutUserForm.emailLabel",
    defaultMessage: "Email address",
  },
  phoneNumberLabel: {
    id: "Appointment.AppointmentCheckoutUserForm.phoneNumberLabel",
    defaultMessage: "Mobile phone number",
  },
  firstNameLabel: {
    id: "Appointment.AppointmentCheckoutUserForm.firstNameLabel",
    defaultMessage: "First name",
  },
  lastNameLabel: {
    id: "Appointment.AppointmentCheckoutUserForm.lastNameLabel",
    defaultMessage: "Last name",
  },
  patientInformationHeader: {
    id: "Appointment.AppointmentCheckoutUserForm.patientInformationHeader",
    defaultMessage: "Patient information",
  },
  patientInformationDesc: {
    id: "Appointment.AppointmentCheckoutUserForm.patientInformationDesc",
    defaultMessage: "The information of the person attending the appointment.",
  },
  patientInformationDescAttention: {
    id: "Appointment.AppointmentCheckoutUserForm.patientInformationDescAttention",
    defaultMessage:
      "They will need access to the email address and phone number entered below in order to check in to their appointment.",
  },
  dateOfBirthLabel: {
    id: "Appointment.AppointmentCheckoutUserForm.dateOfBirthLabel",
    defaultMessage: "Date of birth",
  },
  dateOfBirthPlaceholder: {
    id: "Appointment.AppointmentCheckoutUserForm.dateOfBirthPlaceholder",
    defaultMessage: "mm/dd/yyyy",
  },
  pregnantHeader: {
    id: "Appointment.AppointmentCheckoutUserForm.pregnantHeader",
    defaultMessage: "Is the patient pregnant?",
  },
  answerYes: {
    id: "Appointment.AppointmentCheckoutUserForm.answerYes",
    defaultMessage: "Yes",
  },
  answerNo: {
    id: "Appointment.AppointmentCheckoutUserForm.answerNo",
    defaultMessage: "No",
  },
  answerIdk: {
    id: "Appointment.AppointmentCheckoutUserForm.answerIdk",
    defaultMessage: "I don't know",
  },
  wearingDeviceHeader: {
    id: "Appointment.AppointmentCheckoutUserForm.wearingDeviceHeader",
    defaultMessage: "Is the patient wearing a pacemaker or aneurysm clip?",
  },
  emailCheckDialogTitle: {
    id: "Appointment.AppointmentCheckoutUserForm.emailCheckDialogTitle",
    defaultMessage: "Email Address Check",
  },
  emailCheckDialogDesc: {
    id: "Appointment.AppointmentCheckoutUserForm.emailCheckDialogDesc",
    defaultMessage:
      "We noticed you entered the same email address <b>{email}</b> for yourself and the patient.",
  },
  emailCheckDialogExplanation: {
    id: "Appointment.AppointmentCheckoutUserForm.emailCheckDialogExplanation",
    defaultMessage:
      "The patient will need to have access to this email address in order to check in to their appointment.",
  },
  changeEmailButton: {
    id: "Appointment.AppointmentCheckoutUserForm.changeEmailButton",
    defaultMessage: "Change email address",
  },
  okButton: {
    id: "Appointment.AppointmentCheckoutUserForm.okButton",
    defaultMessage: "Yes, this is ok",
  },
  mobilePhoneNumberHelper: {
    id: "Appointment.AppointmentCheckoutUserForm.mobilePhoneNumberHelper",
    defaultMessage: "Enter only numbers, including the area code",
  },
  dateOfBirthHelper: {
    id: "Appointment.AppointmentCheckoutUserForm.dateOfBirthHelper",
    defaultMessage: "Use format mm/dd/yyyy",
  },
});
