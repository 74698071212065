import React, { FC } from "react";
import { useField } from "formik";
import TextField, { TextFieldProps } from "../TextField";

type FormikTextFieldProps = {
  name: string;
} & Omit<
  TextFieldProps,
  | "value"
  | "multiline"
  | "onChange"
  | "onMouseDown"
  | "onFocus"
  | "onBlur"
  | "error"
>;

const FormikTextField: FC<FormikTextFieldProps> = ({
  name,
  helperText,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { touched, error } = meta;

  const innerHelperText = touched && Boolean(error) ? error : helperText;

  return (
    <TextField
      {...props}
      {...field}
      value={field.value || ""}
      error={touched && Boolean(error)}
      helperText={innerHelperText}
    />
  );
};

export default FormikTextField;
