import { defineMessages } from "react-intl";

export default defineMessages({
  answerYes: {
    id: "Unknown.BooleanRadioGroup.answerYes",
    defaultMessage: "Yes",
  },
  answerNo: {
    id: "Unknown.BooleanRadioGroup.answerNo",
    defaultMessage: "No",
  },
});
