import { defineMessages } from "react-intl";

export default defineMessages({
  btnFullAmount: {
    id: "Payment.PaymentTypeSelection.btnFullAmount",
    defaultMessage: "Pay full amount",
  },
  btnDeposit: {
    id: "Payment.PaymentTypeSelection.btnDeposit",
    defaultMessage: "Pay {depositAmount} deposit",
  },
});
