import { useFormik } from "formik";
import { useState, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { AppointmentAuthFormik } from ".";
import { AnswerCustomChallengeFuncType } from "../../Auth/AuthContextProvider";
import getAuthFormSchema from "./getAuthFormSchema";
import messages from "./messages";

const useAppointmentWelcomeDialog = (params: {
  appointmentId?: string;
  answerCustomChallenge: AnswerCustomChallengeFuncType;
}) => {
  const { formatMessage } = useIntl();
  const { appointmentId, answerCustomChallenge } = params;

  const [isWelcomeDialogOpen, setIsWelcomeDialogOpen] = useState(false);

  const [welcomeEmail, setWelcomeEmail] = useState("");

  const onWelcomeResendButtonClick = () => {
    setIsWelcomeDialogOpen(false);
  };

  const onWelcomeClose = () => {
    setIsWelcomeDialogOpen(false);
  };

  const onAuthCodeSubmit = useCallback(
    async (values: AppointmentAuthFormik) => {
      if (!appointmentId) return;

      const { authCode } = values;

      try {
        await answerCustomChallenge({
          appointmentId,
          email: welcomeEmail,
          code: authCode,
        });
        onWelcomeClose();
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert((error as Error).message);
      }
    },
    [answerCustomChallenge, welcomeEmail, appointmentId],
  );

  const authFormSchema = useMemo(() => {
    return getAuthFormSchema({
      invalidCodeError: formatMessage(messages.invalidCodeError),
    });
  }, [formatMessage]);

  const formik = useFormik<AppointmentAuthFormik>({
    initialValues: { authCode: "" },
    onSubmit: onAuthCodeSubmit,
    validationSchema: authFormSchema,
    initialTouched: { authCode: false },
    isInitialValid: false,
  });

  const onWelcomeOpen = useCallback((email: string) => {
    setWelcomeEmail(email);
    setIsWelcomeDialogOpen(true);
  }, []);

  return {
    isWelcomeDialogOpen,
    welcomeEmail,
    onWelcomeOpen,
    onWelcomeResendButtonClick,
    onWelcomeClose,
    formik,
  };
};

export default useAppointmentWelcomeDialog;
