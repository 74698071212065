import Big from "big.js";
import React, {
  FC,
  ReactNode,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { useSessionStorage } from "react-use";

import { useSearchParams } from "react-router-dom";
import useSystemConfig from "../../../hooks/useSystemConfig";
import {
  CenterState,
  CenterServicesState,
  AddOnsState,
  DateState,
  TimeSlotState,
} from "./types";
import useBookingAddOns from "./useBookingAddOns";
import useBookingAvailableTimeSlots from "./useBookingAvailableTimeSlots";
import useBookingCenters from "./useBookingCenters";
import useBookingCenterServices from "./useBookingCenterServices";
import useBookingOfferings from "./useBookingOfferings";
import useBookingPackages from "./useBookingPackages";
import { getStorageAppointment } from "./storageAppointmentHelpers";
import CircularProgress from "../../Unknown/CircularProgress";
import Box from "../../Unknown/Box";
import { useAuthContext } from "../../Auth/AuthContextProvider";
import {
  SUPABASE_CENTER_USER_TOKEN_KEY,
  OVERRIDDEN_PRICE_KEY,
  OVERRIDDEN_TIME_KEY,
  OVERRIDDEN_DEPOSIT_KEY,
} from "../../../common/constants";

interface AppointmentBookingContextProps {
  centers: ReturnType<typeof useBookingCenters>;
  offerings: ReturnType<typeof useBookingOfferings>;
  packages: ReturnType<typeof useBookingPackages>;
  addOns: ReturnType<typeof useBookingAddOns>;
  availableTimeSlots: ReturnType<typeof useBookingAvailableTimeSlots>;
  centerServices: ReturnType<typeof useBookingCenterServices>;

  selectedCenter: CenterState;
  selectedCenterServices: CenterServicesState;
  selectedAddOns: AddOnsState;
  selectedDate: DateState;
  selectedTimeSlot: TimeSlotState;

  selectedMonth: Date;
  setSelectedMonth: (value: Date) => void;

  onCenterChange: (value: CenterState) => void;
  onCenterServicesChange: Dispatch<SetStateAction<CenterServicesState>>;
  onAddOnsChange: Dispatch<SetStateAction<AddOnsState>>;
  onDateChange: (date: Date | null) => void;
  onMonthChange: (date: Date) => void;
  onTimeSlotChange: Dispatch<SetStateAction<TimeSlotState>>;

  appointmentDuration: number;
  isAllCenterServicesValid: boolean;

  isSubmitLoading: boolean;
  setIsSubmitLoading: Dispatch<SetStateAction<boolean>>;

  storageAppointmentId: string | null;
  overriddenPrice: string | null;
  overriddenDeposit: string | null;
  overriddenTime: string | null;
  onPriceOverride: (newPrice: string) => void;
  onDepositOverride: (newDeposit: string) => void;
  onTimeOverride: (newTime: string) => void;
}

interface AppointmentBookingContextProviderProps {
  children?: ReactNode;
}

const AppointmentBookingContext = createContext<AppointmentBookingContextProps>(
  {} as AppointmentBookingContextProps,
);

export const useAppointmentBookingContext = () =>
  useContext(AppointmentBookingContext);

export const AppointmentBookingContextProvider: FC<
  AppointmentBookingContextProviderProps
> = ({ children }) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const prevParams = useMemo(() => {
    const params: Record<string, string> = {};

    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }, [searchParams]);

  const [isInitialCenterSet, setIsInitialCenterSet] = useState(false);

  const [selectedCenter, setSelectedCenter] = useState<CenterState>(null);
  const [selectedAddOns, setSelectedAddOns] = useState<AddOnsState>([]);
  const [selectedDate, setSelectedDate] = useState<DateState>(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlotState>(null);
  const [selectedCenterServices, setSelectedCenterServices] =
    useState<CenterServicesState>([null]);
  const [overriddenPrice, setOverriddenPrice] = useSessionStorage<
    string | null
  >(OVERRIDDEN_PRICE_KEY, null);
  const [overriddenDeposit, setOverriddenDeposit] = useSessionStorage<
    string | null
  >(OVERRIDDEN_DEPOSIT_KEY, null);
  const [overriddenTime, setOverriddenTime] = useSessionStorage<string | null>(
    OVERRIDDEN_TIME_KEY,
    null,
  );

  const centerUserSupabaseToken = sessionStorage.getItem(
    SUPABASE_CENTER_USER_TOKEN_KEY,
  );

  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());

  const [isStorageAppointmentLoading, setIsStorageAppointmentLoading] =
    useState(false);
  const [isStorageAppointmentChecked, setIsStorageAppointmentChecked] =
    useState(false);
  const [storageAppointmentId, setStorageAppointmentId] = useState<
    string | null
  >(null);
  const { isCenterUserLoading, isCenterUser, isTokenChecked } =
    useAuthContext();

  const setAppointmentFromStorage = useCallback(async () => {
    if (isStorageAppointmentChecked) return;
    setIsStorageAppointmentChecked(true);

    try {
      setIsStorageAppointmentLoading(true);
      const storageAppointment = await getStorageAppointment();
      if (!storageAppointment) {
        setIsStorageAppointmentLoading(false);
        return;
      }

      setStorageAppointmentId(storageAppointment.id);
      setSelectedCenter(storageAppointment.center);
      setSelectedAddOns(storageAppointment.addOns);
      setSelectedDate(storageAppointment.date);
      setSelectedTimeSlot(storageAppointment.timeSlot);
      setSelectedCenterServices(storageAppointment.centerServices);

      setSelectedMonth(storageAppointment.month);
    } catch (e) {
      // eslint-disable-next-line no-empty
    } finally {
      setIsStorageAppointmentLoading(false);
    }
  }, [isStorageAppointmentChecked]);

  useEffect(() => {
    setAppointmentFromStorage();
  }, [setAppointmentFromStorage]);

  const { data: systemConfigData } = useSystemConfig();

  const centers = useBookingCenters({ systemConfig: systemConfigData });

  const offerings = useBookingOfferings({
    isCenterUser,
    centerConfig: selectedCenter,
  });

  const packages = useBookingPackages({
    centerConfig: selectedCenter,
    isCenterUser,
  });

  const centerServices = useBookingCenterServices({
    centerConfig: selectedCenter,
    isCenterUser,
  });

  const addOns = useBookingAddOns(selectedCenter?.id);

  const appointmentDuration = useMemo(() => {
    const selectedCenterServicesDurationBig = selectedCenterServices.reduce(
      (acc, selectedCenterService) => {
        return acc.plus(selectedCenterService?.durationMinutes || 0);
      },
      Big(0),
    );

    return selectedCenterServicesDurationBig.toNumber();
  }, [selectedCenterServices]);

  const availableTimeSlots = useBookingAvailableTimeSlots({
    storageAppointmentId,
    center: selectedCenter,
    date: selectedMonth,
    appointmentDuration,
  });

  const onCenterChange: AppointmentBookingContextProps["onCenterChange"] =
    useCallback(
      (value) => {
        setSelectedTimeSlot(null);
        setSelectedDate(null);
        setSelectedMonth(new Date());
        setSelectedAddOns([]);
        setSelectedCenterServices([null]);
        setSelectedCenter(value);
        setSearchParams(
          value ? { ...prevParams, location: value.id } : prevParams,
          {
            replace: false,
          },
        );
      },
      [prevParams, setSearchParams],
    );

  useEffect(() => {
    if (isInitialCenterSet || centers.status !== "success" || selectedCenter) {
      return;
    }

    if (centers.data.length === 1) {
      setIsInitialCenterSet(true);
      onCenterChange(centers.data[0]);
      return;
    }

    const searchParamsCenterId = searchParams.get("location");

    if (!searchParamsCenterId) {
      setIsInitialCenterSet(true);
      return;
    }

    const foundCenter = centers.data.find(
      (center) => center.id === searchParamsCenterId,
    );

    if (!foundCenter) {
      setIsInitialCenterSet(true);
      return;
    }

    setSelectedCenter(foundCenter);
    setIsInitialCenterSet(true);
  }, [
    centers,
    isInitialCenterSet,
    onCenterChange,
    searchParams,
    selectedCenter,
  ]);

  const onCenterServicesChange: Dispatch<SetStateAction<CenterServicesState>> =
    useCallback((value) => {
      setSelectedTimeSlot(null);
      setSelectedDate(null);
      setSelectedCenterServices(value);
    }, []);

  const onMonthChange: AppointmentBookingContextProps["onMonthChange"] =
    useCallback((value) => {
      setSelectedMonth(value);
      setSelectedTimeSlot(null);
      setSelectedDate(null);
    }, []);

  const onDateChange: AppointmentBookingContextProps["onDateChange"] =
    useCallback((value) => {
      setSelectedTimeSlot(null);
      setSelectedDate(value);
    }, []);

  const onPriceOverride = (newPrice: string) => {
    const price = newPrice.replace(/[^0-9.]/g, "");

    setOverriddenPrice(price);
  };

  const onTimeOverride = (newTime: string) => {
    setOverriddenTime(newTime);
  };

  const onDepositOverride = (newDeposit: string) => {
    const deposit = newDeposit.replace(/[^0-9.]/g, "");

    setOverriddenDeposit(deposit);
  };

  const isAllCenterServicesValid = useMemo(() => {
    const { data, status } = centerServices;

    if (status !== "success") return false;

    return selectedCenterServices.every((selectedCenterService) => {
      if (!selectedCenterService) return false;

      const centerService = data[selectedCenterService.value];

      if (!centerService) return false;

      const isCenterServiceWithDualSide =
        centerService.groupId === "single-offering" &&
        centerService.scanSide === "dual";

      if (isCenterServiceWithDualSide && !selectedCenterService.side) {
        return false;
      }

      return true;
    });
  }, [centerServices, selectedCenterServices]);

  if (
    isStorageAppointmentLoading ||
    centers.status === "loading" ||
    isCenterUserLoading ||
    !isTokenChecked ||
    centerUserSupabaseToken === undefined
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        mt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <AppointmentBookingContext.Provider
      value={{
        centers,
        offerings,
        packages,
        addOns,
        availableTimeSlots,
        centerServices,

        selectedCenter,
        selectedAddOns,
        selectedDate,
        selectedTimeSlot,
        selectedCenterServices,

        selectedMonth,
        setSelectedMonth,

        onCenterChange,
        onAddOnsChange: setSelectedAddOns,
        onDateChange,
        onTimeSlotChange: setSelectedTimeSlot,
        onCenterServicesChange,
        onMonthChange,

        appointmentDuration,
        isAllCenterServicesValid,

        isSubmitLoading,
        setIsSubmitLoading,

        storageAppointmentId,
        overriddenPrice,
        overriddenDeposit,
        overriddenTime,
        onPriceOverride,
        onDepositOverride,
        onTimeOverride,
      }}
    >
      {children}
    </AppointmentBookingContext.Provider>
  );
};
