import React, { FC } from "react";

import AvailabilityCalendarTimeSlots from "../../Unknown/AvailabilityCalendarTimeSlots";
import Box from "../../Unknown/Box";
import CircularProgress from "../../Unknown/CircularProgress";
import { useAppointmentBookingContext } from "../AppointmentBookingContext";

const AppointmentTimeSlotsSelecting: FC = () => {
  const {
    selectedDate,
    selectedTimeSlot,
    onTimeSlotChange,
    selectedCenter,
    availableTimeSlots,
    isSubmitLoading,
  } = useAppointmentBookingContext();

  const { data, status } = availableTimeSlots;

  if (!selectedCenter || !selectedDate || status === "error") {
    return null;
  }

  if (status !== "success") {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        mt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  const selectedDayDate = selectedDate.getDate();

  const availableCalendarTimeSlots =
    data.currentMonthTimeSlots[selectedDayDate]?.available || [];

  return (
    <AvailabilityCalendarTimeSlots
      availableTimeSlots={availableCalendarTimeSlots}
      selectedTimeSlot={selectedTimeSlot}
      onTimeSlotChange={onTimeSlotChange}
      isDisabled={isSubmitLoading}
    />
  );
};

export default AppointmentTimeSlotsSelecting;
