import max from "date-fns/max";
import addMilliseconds from "date-fns/addMilliseconds";
import differenceInMinutes from "date-fns/differenceInMinutes";
import eachMinuteOfInterval from "date-fns/eachMinuteOfInterval";
import getIntervalOperatingHours from "./getIntervalOperatingHours";
import reverseTimeSlots from "./reverseTimeSlots";
import { AvailableTimeSlots, OperatingHours, TimeSlot } from "./types";

const getAvailableTimeSlots = (params: {
  startDate: Date;
  endDate: Date;
  operatingHours: OperatingHours;
  centerTimeSlots: TimeSlot[];
  timezoneOffsetMillis: number;
  appointmentDurationMinutes: number;
}) => {
  const {
    startDate,
    endDate,
    operatingHours,
    centerTimeSlots,
    timezoneOffsetMillis,
    appointmentDurationMinutes,
  } = params;

  if (appointmentDurationMinutes === 0) return {};

  const workingDaysData = getIntervalOperatingHours({
    startDate,
    endDate: max([startDate, endDate]),
    operatingHours,
    timezoneOffsetMillis,
  });

  const unavailableTimeSlotsTimezone = centerTimeSlots.reduce(
    (unavailableSlots, centerTimeSlot) => {
      const unavailableSlot = {
        startedAt: addMilliseconds(
          centerTimeSlot.startedAt,
          timezoneOffsetMillis,
        ),
        endedAt: addMilliseconds(centerTimeSlot.endedAt, timezoneOffsetMillis),
      };

      return [...unavailableSlots, unavailableSlot];
    },
    [] as {
      startedAt: Date;
      endedAt: Date;
    }[],
  );

  const availableTimeSlotsWorkingDays = workingDaysData
    .map((workingDay) => {
      const availableTimeSlots = reverseTimeSlots({
        startOfDate: workingDay.startOfOperatingDayTimezone,
        endOfDate: workingDay.endOfOperatingDayTimezone,
        timeSlots: unavailableTimeSlotsTimezone,
      });

      const maxDurationMinutes = availableTimeSlots.reduce((localMax, curr) => {
        const currDuration = differenceInMinutes(curr.endedAt, curr.startedAt);
        return Math.max(localMax, currDuration);
      }, 0);

      const result = {
        day: workingDay.day,
        availableTimeSlots,
        maxDurationMinutes,
      };

      return result;
    })
    .filter((workingDay) => workingDay.availableTimeSlots.length);

  const availableTimeSlotsForDuration = availableTimeSlotsWorkingDays.map(
    (availableTimeSlotsWorkingDay) => {
      const available = availableTimeSlotsWorkingDay.availableTimeSlots
        .map((availableTimeSlotWorkingDay) => {
          const intervals = eachMinuteOfInterval(
            {
              start: availableTimeSlotWorkingDay.startedAt,
              end: availableTimeSlotWorkingDay.endedAt,
            },
            { step: appointmentDurationMinutes },
          );
          intervals.splice(-1);
          return intervals;
        })
        .flat();

      return {
        day: availableTimeSlotsWorkingDay.day,
        maxDurationMinutes: availableTimeSlotsWorkingDay.maxDurationMinutes,
        available,
      };
    },
  );

  const result = availableTimeSlotsForDuration.reduce<AvailableTimeSlots>(
    (acc, dayData) => {
      const { day, maxDurationMinutes, available } = dayData;
      if (!available.length) return acc;
      return { ...acc, [day]: { maxDurationMinutes, available } };
    },
    {},
  );

  return result;
};

export default getAvailableTimeSlots;
