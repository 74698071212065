import * as Yup from "yup";

const authCodeLength = 6;

const getAuthFormSchema = (params: { invalidCodeError: string }) => {
  const { invalidCodeError } = params;

  return Yup.object().shape({
    authCode: Yup.string()
      .trim()
      .matches(new RegExp(`^\\d{${authCodeLength}}$`), invalidCodeError)
      .required(invalidCodeError),
  });
};

export default getAuthFormSchema;
