import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import messages from "./messages";

export type CenterOpeningHoursPreviewTranslations = {
  title: string;
  closed: string;
  shortMonday: string;
  shortTuesday: string;
  shortWednesday: string;
  shortThursday: string;
  shortFriday: string;
  shortSaturday: string;
  shortSunday: string;
};

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations: CenterOpeningHoursPreviewTranslations = {
    title: formatMessage(messages.title),
    closed: formatMessage(messages.closed),
    shortMonday: formatMessage(commonMessages.shortMonday),
    shortTuesday: formatMessage(commonMessages.shortTuesday),
    shortWednesday: formatMessage(commonMessages.shortWednesday),
    shortThursday: formatMessage(commonMessages.shortThursday),
    shortFriday: formatMessage(commonMessages.shortFriday),
    shortSaturday: formatMessage(commonMessages.shortSaturday),
    shortSunday: formatMessage(commonMessages.shortSunday),
  };

  return translations;
};

export default useTranslations;
