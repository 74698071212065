import addMilliseconds from "date-fns/addMilliseconds";
import getHours from "date-fns/getHours";
import addMinutes from "date-fns/addMinutes";
import getMinutes from "date-fns/getMinutes";
import startOfDay from "date-fns/startOfDay";
import { useMemo, useCallback } from "react";
import hoursToMinutes from "date-fns/hoursToMinutes";
import Big from "big.js";
import getCenterAddress from "../../../common/getCenterAddress";
import { useAppointmentBookingContext } from "../../Appointment/AppointmentBookingContext";
import { BookingCenter } from "../../Appointment/AppointmentBookingContext/useBookingCenters";
import { CenterPreviewProps } from "../CenterPreview";
import objectKeys from "../../../common/objectKeys";
import useTranslations from "./useTranslations";
import { formatInternational } from "../../../common/phoneNumberHelpers";

const calcMinutesWithTZ = (
  minutesUTC: number,
  centerOffsetMilliseconds: number,
) => {
  const dateCenterTZ = addMinutes(
    addMilliseconds(startOfDay(new Date()), centerOffsetMilliseconds),
    minutesUTC,
  );

  const hours = getHours(dateCenterTZ);
  const minutes = getMinutes(dateCenterTZ);

  const minutesCenterTZ = Big(hoursToMinutes(hours)).plus(minutes).toNumber();

  return minutesCenterTZ;
};

const centerCenterToCenterPreviewProps = (
  center: BookingCenter,
): CenterPreviewProps & { id: string } => {
  const {
    country,
    name,
    addresses,
    city,
    postalCode,
    phoneNumber,
    email,
    state,
    faxNumber,
    operatingHours,
    timezoneOffsetMilliseconds,
  } = center;

  const description = getCenterAddress({
    country,
    addresses,
    city,
    postalCode,
    state,
  });

  const formattedPhoneNumber = phoneNumber
    ? formatInternational({ phoneNumber })
    : "";

  const formattedFaxNumber = faxNumber
    ? formatInternational({ phoneNumber: faxNumber })
    : "";

  const phone = {
    styled: formattedPhoneNumber,
    link: `tel:${phoneNumber}`,
  };

  const fax =
    faxNumber && formattedFaxNumber
      ? {
          styled: formattedFaxNumber,
          link: `tel:${faxNumber}`,
        }
      : undefined;

  const operatingHoursCenterTZ = objectKeys(operatingHours).reduce<
    CenterPreviewProps["operatingHours"]
  >((acc, operationHoursItemKey) => {
    const operationHoursCurrentDayUTC = operatingHours[operationHoursItemKey];

    if (!operationHoursCurrentDayUTC) return acc;

    const operationHoursCurrentDay = {
      fromMinutesAt: calcMinutesWithTZ(
        operationHoursCurrentDayUTC.fromMinutesAtUTC,
        timezoneOffsetMilliseconds,
      ),
      toMinutesAt: calcMinutesWithTZ(
        operationHoursCurrentDayUTC.toMinutesAtUTC,
        timezoneOffsetMilliseconds,
      ),
    };
    return { ...acc, [operationHoursItemKey]: operationHoursCurrentDay };
  }, {});

  return {
    id: center.id,
    name,
    description,
    phone,
    email,
    fax,
    operatingHours: operatingHoursCenterTZ,
  };
};

const useCenterPreviewPropsList = () => {
  const { btnBook, btnChange } = useTranslations();

  const { centers, selectedCenter, onCenterChange, isSubmitLoading } =
    useAppointmentBookingContext();

  const { data, status } = centers;

  const onPreviewActionClickFunc = useCallback(
    (id: string) => {
      return () => {
        if (status !== "success" || selectedCenter) {
          onCenterChange(null);
          return;
        }

        const foundCenter = data.find((center) => center.id === id);

        onCenterChange(foundCenter || null);
      };
    },
    [data, onCenterChange, selectedCenter, status],
  );

  const centerPreviewPropsList = useMemo<
    (CenterPreviewProps & { id: string })[]
  >(() => {
    if (status !== "success") return [];

    if (selectedCenter) {
      const selectedCenterPreviewProps =
        centerCenterToCenterPreviewProps(selectedCenter);

      const isActionButtonShown = data.length !== 1;

      if (isActionButtonShown) {
        selectedCenterPreviewProps.actionButton = {
          props: {
            variant: "outlined",
            disabled: isSubmitLoading,
            onClick: onPreviewActionClickFunc(selectedCenter.id),
          },
          text: btnChange,
        };
      }

      return [selectedCenterPreviewProps];
    }

    const centerPreviewPropsOptions = data.map((center) => {
      const centerPreviewProps = centerCenterToCenterPreviewProps(center);

      centerPreviewProps.actionButton = {
        props: {
          size: "large",
          variant: "contained",
          disabled: isSubmitLoading,
          onClick: onPreviewActionClickFunc(center.id),
        },
        text: btnBook,
      };

      return centerPreviewProps;
    });

    return centerPreviewPropsOptions;
  }, [
    btnBook,
    btnChange,
    data,
    isSubmitLoading,
    onPreviewActionClickFunc,
    selectedCenter,
    status,
  ]);

  return { centerPreviewPropsList };
};

export default useCenterPreviewPropsList;
