import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import { ITheme } from "../../../common/theme";

export default makeStyles<ITheme>(({ palette }) => ({
  descriptionRoot: {
    color: alpha(palette.common.black, 0.6),
  },
  alertRoot: {
    "& .MuiAlert-icon": {
      color: "inherit",
    },
  },
}));
