import { httpsCallable } from "firebase/functions";
import { useFunctions } from "reactfire";
import { useMemo } from "react";
import {
  AppointmentBookingParams,
  AppointmentBookingResponse,
  SendAuthCodeParams,
  SendAuthCodeResponse,
  CreateCustomTokenParams,
  CreateCustomTokenResponse,
  ResetAppointmentCheckoutDurationParams,
  ResetAppointmentCheckoutDurationResponse,
  AppointmentUpdatePaymentTypeParams,
  AppointmentUpdatePaymentTypeResponse,
  AppointmentCreatePaymentParams,
  AppointmentCreatePaymentStripeResponse,
  OnAppointmentExpiredParams,
  OnAppointmentExpiredResponse,
  GetCustomerCheckoutFormDataParams,
  GetCustomerCheckoutFormDataResponse,
  GenerateCenterUserCustomTokensParams,
  GenerateCenterUserCustomTokensResponse,
  SaveAppointmentParams,
  SaveAppointmentResponse,
  GetCustomerPaymentMethodsParams,
  GetCustomerPaymentMethodsResponse,
  PayAppointmentInitialBalanceResponse,
  PayAppointmentInitialBalanceParams,
} from "@Shape-Digital/kudzu-data/lib/types/actions";
import { FUNCTIONS_PREFIX } from "../common/constants";

type FunctionResource = {
  appointmentBooking: [AppointmentBookingParams, AppointmentBookingResponse];
  sendAuthCode: [SendAuthCodeParams, SendAuthCodeResponse];
  createCustomToken: [CreateCustomTokenParams, CreateCustomTokenResponse];
  appointmentCreatePayment: [
    AppointmentCreatePaymentParams,
    AppointmentCreatePaymentStripeResponse,
  ];
  resetAppointmentCheckoutDuration: [
    ResetAppointmentCheckoutDurationParams,
    ResetAppointmentCheckoutDurationResponse,
  ];
  appointmentUpdatePaymentType: [
    AppointmentUpdatePaymentTypeParams,
    AppointmentUpdatePaymentTypeResponse,
  ];
  onAppointmentExpired: [
    OnAppointmentExpiredParams,
    OnAppointmentExpiredResponse,
  ];
  getCustomerCheckoutFormData: [
    GetCustomerCheckoutFormDataParams,
    GetCustomerCheckoutFormDataResponse,
  ];
  generateCenterUserCustomTokens: [
    GenerateCenterUserCustomTokensParams,
    GenerateCenterUserCustomTokensResponse,
  ];
  saveAppointment: [SaveAppointmentParams, SaveAppointmentResponse];
  getCustomerPaymentMethods: [
    GetCustomerPaymentMethodsParams,
    GetCustomerPaymentMethodsResponse,
  ];
  payAppointmentInitialBalance: [
    PayAppointmentInitialBalanceParams,
    PayAppointmentInitialBalanceResponse,
  ];
};

type FunctionReq<K extends keyof FunctionResource> = FunctionResource[K][0];
type FunctionRes<K extends keyof FunctionResource> = FunctionResource[K][1];

const useFirebaseAppFunction = <K extends keyof FunctionResource>(
  functionName: K,
) => {
  const functions = useFunctions();

  const func = useMemo(() => {
    return httpsCallable<FunctionReq<K>, FunctionRes<K>>(
      functions,
      `${FUNCTIONS_PREFIX}-${functionName}`,
    );
  }, [functionName, functions]);

  return func;
};

export default useFirebaseAppFunction;
