import React, { FC } from "react";
import { useIntl } from "react-intl";
import Grid from "../../Unknown/Grid";
import SelectCenterForm from "../../Center/SelectCenterForm";
import CenterServiceSelectForm from "../../Center/SelectCenterServiceForm";
import { useAppointmentBookingContext } from "../AppointmentBookingContext";
import Box from "../../Unknown/Box";
import SelectAddOnsForm from "../../Center/SelectAddOnsForm";
import Typography from "../../Unknown/Typography";
import messages from "./messages";
import AppointmentDateSelecting from "../AppointmentDateSelecting";
import AppointmentTimeSlotsSelecting from "../AppointmentTimeSlotsSelecting";
import useDeviceType from "../../../hooks/useDeviceType";
import useStyles from "./useStyles";

const BookingSection: FC = () => {
  const { calendarRoot } = useStyles();

  const { isDesktop } = useDeviceType();

  const calendarDirection = isDesktop ? "row" : "column";

  const intl = useIntl();
  const { selectedCenter, selectedCenterServices, isAllCenterServicesValid } =
    useAppointmentBookingContext();

  const isCenterSelected = !!selectedCenter;
  const isCenterServiceSelected =
    selectedCenterServices.length && selectedCenterServices.every(Boolean);

  return (
    <Grid container spacing={7} direction="column">
      <Grid item>
        <Typography mb={4} variant="h3">
          {intl.formatMessage(messages.selectCenterTitle)}
        </Typography>

        <SelectCenterForm />
      </Grid>
      {isCenterSelected && (
        <>
          <Grid item>
            <Typography mb={4} variant="h3">
              {intl.formatMessage(messages.selectCenterServiceTitle)}
            </Typography>
            <CenterServiceSelectForm />
            {isCenterServiceSelected && (
              <Box mt={4}>
                <SelectAddOnsForm />
              </Box>
            )}
          </Grid>
          {isAllCenterServicesValid && (
            <Grid item>
              <Typography mb={4} variant="h3">
                {intl.formatMessage(messages.selectDateTitle)}
              </Typography>
              <Grid
                container
                spacing={6}
                direction={calendarDirection}
                flexWrap="nowrap"
              >
                <Grid item width={360}>
                  <Box className={calendarRoot}>
                    <AppointmentDateSelecting />
                  </Box>
                </Grid>
                <Grid item width={270}>
                  <AppointmentTimeSlotsSelecting />
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default BookingSection;
