import React, { FC, useMemo, ChangeEvent, useCallback, Dispatch } from "react";
import { useSearchParams } from "react-router-dom";

import { PaymentType } from "../../../common/types";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import RadioBlocks, { RadioBlockOption } from "../../Unknown/RadioBlocks";
import useTranslations from "./useTranslations";

type PaymentTypeSelectionProps = {
  depositAmount: number;
  currencyCode: string;
  paymentType: string;
  appointmentId?: string;
  setIsError: Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: Dispatch<React.SetStateAction<boolean>>;
  setPaymentType: (paymentType: PaymentType) => void;
};

const PaymentTypeSelection: FC<PaymentTypeSelectionProps> = ({
  depositAmount,
  currencyCode,
  paymentType,
  appointmentId,
  setIsError,
  setIsLoading,
  setPaymentType,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const translations = useTranslations({ depositAmount, currencyCode });
  const appointmentUpdatePaymentType = useFirebaseAppFunction(
    "appointmentUpdatePaymentType",
  );

  const paymentTypeOptions = useMemo<RadioBlockOption[]>(() => {
    const { btnFullAmount, btnDeposit } = translations;

    return [
      { value: PaymentType.Deposit, label: btnDeposit },
      { value: PaymentType.TotalAmount, label: btnFullAmount },
    ];
  }, [translations]);

  const onPaymentTypeChange: (
    event: ChangeEvent<HTMLInputElement>,
    newValue: string,
  ) => void = useCallback(
    async (_, newValue) => {
      try {
        setIsError(false);
        setIsLoading(true);

        setSearchParams({});

        if (!appointmentId) return;

        const allowedValues: PaymentType[] = [
          PaymentType.TotalAmount,
          PaymentType.Deposit,
        ];

        const isValueAllowed = allowedValues.includes(newValue as PaymentType);

        const newPaymentType = (
          isValueAllowed ? newValue : allowedValues[0]
        ) as PaymentType;

        setPaymentType(newPaymentType);

        const { data } = await appointmentUpdatePaymentType({
          appointmentId,
          paymentType: newPaymentType,
        });

        setSearchParams({
          clientSecret: data.clientSecret,
        });
      } catch (error) {
        setIsError(true);
        const { defaultError } = translations;
        // eslint-disable-next-line no-alert
        alert(defaultError);
      } finally {
        setIsLoading(false);
      }
    },
    [
      appointmentId,
      appointmentUpdatePaymentType,
      setIsError,
      setIsLoading,
      setPaymentType,
      setSearchParams,
      translations,
    ],
  );

  return (
    <RadioBlocks
      row
      value={paymentType}
      onChange={onPaymentTypeChange}
      options={paymentTypeOptions}
    />
  );
};

export default PaymentTypeSelection;
