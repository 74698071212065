import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import ReactGA from "react-ga4";
import React, { FC, ReactNode } from "react";
import { Formik, FormikProps } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import format from "date-fns/format";
import formatISO from "date-fns/formatISO";
import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import {
  AppointmentCreatePaymentParams,
  AppointmentCreatePaymentSuccessResponse,
  GetCustomerCheckoutFormDataResponse,
  SaveAppointmentParams,
} from "@Shape-Digital/kudzu-data/lib/types/actions";
import { PatientFormValues } from "../AppointmentCheckoutPatientForm";
import { GuardianFormValues } from "../AppointmentCheckoutGuardianForm";
import { OtherPersonFormValues } from "../AppointmentCheckoutOtherPersonForm";

import getValidationSchema, {
  CHECKOUT_DATE_FORMAT,
} from "./getValidationSchema";
import useTranslations from "./useTranslations";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useAuthContext } from "../../Auth/AuthContextProvider/index";
import {
  GAEventCategory,
  MAX_STRING_INPUT_LENGTH,
  MIN_STRING_INPUT_LENGTH,
} from "../../../common/constants";
import { clearStorageAppointment } from "../AppointmentBookingContext/storageAppointmentHelpers";

const getPatientInitialValues = ({
  customerFormData,
  selectedCustomer,
}: {
  customerFormData: GetCustomerCheckoutFormDataResponse | null;
  selectedCustomer?: DatabaseRow<"customers"> | null;
}) => {
  if (selectedCustomer) {
    const initialValues: PatientFormValues = {
      pregnant: "no",
      wearingDevice: "",
      email: selectedCustomer.email,
      firstName: selectedCustomer.first_name,
      lastName: selectedCustomer.last_name,
      phoneNumber: selectedCustomer.mobile_phone_number || "",
      dateOfBirth: selectedCustomer.date_birth
        ? format(parseISO(selectedCustomer.date_birth), CHECKOUT_DATE_FORMAT)
        : "",
    };

    return initialValues;
  }

  if (customerFormData) {
    const { email, mobile_phone_number, first_name, last_name, date_birth } =
      customerFormData.customer;

    const initialValues: PatientFormValues = {
      pregnant: "no",
      wearingDevice: "",
      email: email || "",
      firstName: first_name,
      lastName: last_name,
      phoneNumber: mobile_phone_number || "",
      dateOfBirth: date_birth
        ? format(parseISO(date_birth), CHECKOUT_DATE_FORMAT)
        : "",
    };

    return initialValues;
  }

  return {
    email: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    pregnant: "no" as const,
    wearingDevice: "" as const,
  };
};

const getGuardianInitialValues = ({
  customerFormData,
  selectedCustomer,
}: {
  customerFormData: GetCustomerCheckoutFormDataResponse | null;
  selectedCustomer?: DatabaseRow<"customers"> | null;
}) => {
  if (selectedCustomer) {
    const initialValues: GuardianFormValues = {
      guardianEmail: selectedCustomer.email,
      guardianPhoneNumber: selectedCustomer.mobile_phone_number || "",
      guardianFirstName: selectedCustomer.first_name,
      guardianLastName: selectedCustomer.last_name,
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      pregnant: "no",
      wearingDevice: "",
    };

    return initialValues;
  }

  if (customerFormData) {
    const { customer, patient } = customerFormData;

    const initialValues: GuardianFormValues = {
      guardianEmail: customer.email || "",
      guardianFirstName: customer.first_name,
      guardianLastName: customer.last_name,
      guardianPhoneNumber: customer.mobile_phone_number || "",
      firstName: patient?.first_name || "",
      lastName: patient?.last_name || "",
      dateOfBirth: patient?.date_birth
        ? format(parseISO(patient?.date_birth), CHECKOUT_DATE_FORMAT)
        : "",
      pregnant: "no",
      wearingDevice: "",
    };

    return initialValues;
  }

  return {
    guardianEmail: "",
    guardianPhoneNumber: "",
    guardianFirstName: "",
    guardianLastName: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    pregnant: "no" as const,
    wearingDevice: "" as const,
  };
};

const getOtherPersonInitialValues = ({
  selectedCustomer,
}: {
  selectedCustomer?: DatabaseRow<"customers"> | null;
}) => {
  if (!selectedCustomer) {
    const initialValues: OtherPersonFormValues = {
      otherPersonFirstName: "",
      otherPersonLastName: "",
      otherPersonEmail: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      dateOfBirth: "",
      pregnant: "no",
      wearingDevice: "",
    };

    return initialValues;
  }

  const initialValues: OtherPersonFormValues = {
    otherPersonFirstName: "",
    otherPersonLastName: "",
    otherPersonEmail: "",
    firstName: selectedCustomer.first_name,
    lastName: selectedCustomer.last_name,
    email: selectedCustomer.email,
    phoneNumber: selectedCustomer.mobile_phone_number || "",
    dateOfBirth: selectedCustomer?.date_birth
      ? format(parseISO(selectedCustomer?.date_birth), CHECKOUT_DATE_FORMAT)
      : "",
    pregnant: "no",
    wearingDevice: "",
  };

  return initialValues;
};

const getInitialTouched = <T extends Record<string, string>>(
  values: Record<keyof T, string>,
): Record<keyof T, boolean> => {
  return Object.keys(values).reduce(
    (resultObj, key) => ({ ...resultObj, [key]: !!values[key] }),
    {} as Record<keyof T, boolean>,
  );
};

const getValidateOnMount = <T extends Record<string, string>>(
  values: Record<keyof T, string>,
): boolean => {
  return Object.keys(values).some((valueKey) => !!values[valueKey]);
};

type AppointmentCheckoutFormSectionWrapperProps = {
  personType: "patient" | "legalGuardian" | "otherPerson" | null;
  customerFormData: GetCustomerCheckoutFormDataResponse;
  selectedCustomer?: DatabaseRow<"customers"> | null;
  isPaymentRequired: boolean;
  children?:
    | ((
        props: FormikProps<
          | PatientFormValues
          | GuardianFormValues
          | OtherPersonFormValues
          | Record<string, never>
        >,
      ) => ReactNode)
    | ReactNode;
};

const getPhoneNumber = (str: string): string => {
  return `+${str.replace(/\D/g, "")}`;
};

const getDateOfBirth = (str: string) =>
  formatISO(parse(str, CHECKOUT_DATE_FORMAT, Date.now()), {
    representation: "date",
  });

const getPaymentUrl = (
  createdPaymentData: AppointmentCreatePaymentSuccessResponse,
  appointmentId: string,
  defaultErrorText: string,
): string => {
  const { providerType } = createdPaymentData;

  switch (providerType) {
    case "stripe": {
      const { clientSecret } = createdPaymentData;

      return `/${appointmentId}/payment/stripe?clientSecret=${clientSecret}`;
    }
    default: {
      throw new Error(defaultErrorText);
    }
  }
};

const AppointmentCheckoutFormSectionWrapper: FC<
  AppointmentCheckoutFormSectionWrapperProps
> = ({
  personType,
  children,
  customerFormData,
  selectedCustomer,
  isPaymentRequired,
}) => {
  const { translations, validationSchemaTranslations } = useTranslations({
    minimumValue: MIN_STRING_INPUT_LENGTH,
    maximumValue: MAX_STRING_INPUT_LENGTH,
  });

  const appointmentCreatePayment = useFirebaseAppFunction(
    "appointmentCreatePayment",
  );

  const saveAppointment = useFirebaseAppFunction("saveAppointment");

  const { awsSession } = useAuthContext();
  const { appointmentId } = useParams();

  const navigate = useNavigate();

  switch (personType) {
    case "patient": {
      const onSubmit = async (values: PatientFormValues) => {
        try {
          if (!appointmentId) {
            throw new Error(translations.defaultError);
          }

          const customerId =
            selectedCustomer?.id || awsSession?.getIdToken()?.payload?.sub;

          const params: AppointmentCreatePaymentParams | SaveAppointmentParams =
            {
              appointmentId,
              customerId,
              creatorType: "patient",
              ...values,
              dateOfBirth: getDateOfBirth(values.dateOfBirth),
              phoneNumber: getPhoneNumber(values.phoneNumber),
            };

          if (isPaymentRequired) {
            ReactGA.event({
              action: "Click confirm and pay",
              category: GAEventCategory.click,
            });
            const { data: appointmentCreatePaymentResponse } =
              await appointmentCreatePayment(params);

            if (appointmentCreatePaymentResponse.status === "error") {
              throw new Error(translations.errorEmailInUse);
            }

            const paymentUrl = getPaymentUrl(
              appointmentCreatePaymentResponse.data,
              appointmentId,
              translations.defaultError,
            );

            clearStorageAppointment();

            navigate(paymentUrl);
          }

          if (!isPaymentRequired) {
            ReactGA.event({
              action: "Click save appointment",
              category: GAEventCategory.click,
            });
            const { data: saveAppointmentResponse } = await saveAppointment(
              params,
            );

            if (saveAppointmentResponse.status === "error") {
              throw new Error(translations.errorEmailInUse);
            }

            clearStorageAppointment();

            navigate(`/${appointmentId}/success`);
          }
        } catch (error) {
          // @TODO use error alert
          // eslint-disable-next-line no-alert
          alert((error as Error).message);
        }
      };

      const validationSchema = getValidationSchema(
        "patientForm",
        validationSchemaTranslations,
        MIN_STRING_INPUT_LENGTH,
        MAX_STRING_INPUT_LENGTH,
      );

      const initialValues = getPatientInitialValues({
        customerFormData,
        selectedCustomer,
      });

      const initialTouched = getInitialTouched(initialValues);
      const validateOnMount = getValidateOnMount(initialValues);

      return (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          initialTouched={initialTouched}
          validateOnMount={validateOnMount}
          enableReinitialize
        >
          {
            children as
              | React.ReactNode
              | ((props: FormikProps<PatientFormValues>) => ReactNode)
          }
        </Formik>
      );
    }
    case "legalGuardian": {
      const onSubmit = async (values: GuardianFormValues) => {
        try {
          if (!appointmentId) {
            throw new Error(translations.defaultError);
          }

          const customerId =
            selectedCustomer?.id || awsSession?.getIdToken()?.payload?.sub;

          const params: AppointmentCreatePaymentParams | SaveAppointmentParams =
            {
              appointmentId,
              customerId: customerId as string | undefined,
              creatorType: "legalGuardian",
              ...values,
              dateOfBirth: getDateOfBirth(values.dateOfBirth),
              guardianPhoneNumber: getPhoneNumber(values.guardianPhoneNumber),
            };

          if (isPaymentRequired) {
            ReactGA.event({
              action: "Click confirm and pay",
              category: GAEventCategory.click,
            });

            const { data: appointmentCreatePaymentResponse } =
              await appointmentCreatePayment(params);

            if (appointmentCreatePaymentResponse.status === "error") {
              throw new Error(translations.errorEmailInUse);
            }

            const paymentUrl = getPaymentUrl(
              appointmentCreatePaymentResponse.data,
              appointmentId,
              translations.defaultError,
            );

            clearStorageAppointment();
            navigate(paymentUrl);
          }

          if (!isPaymentRequired) {
            ReactGA.event({
              action: "Click save appointment",
              category: GAEventCategory.click,
            });

            const { data: saveAppointmentResponse } = await saveAppointment(
              params,
            );

            if (saveAppointmentResponse.status === "error") {
              throw new Error(translations.errorEmailInUse);
            }

            clearStorageAppointment();

            navigate(`/${appointmentId}/success`);
          }
        } catch (error) {
          // @TODO use error alert
          // eslint-disable-next-line no-alert
          alert((error as Error).message);
        }
      };

      const validationSchema = getValidationSchema(
        "guardianForm",
        validationSchemaTranslations,
        MIN_STRING_INPUT_LENGTH,
        MAX_STRING_INPUT_LENGTH,
      );

      const initialValues = getGuardianInitialValues({
        customerFormData,
        selectedCustomer,
      });

      const initialTouched = getInitialTouched(initialValues);
      const validateOnMount = getValidateOnMount(initialValues);

      return (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          initialTouched={initialTouched}
          validateOnMount={validateOnMount}
          enableReinitialize
        >
          {
            children as
              | React.ReactNode
              | ((props: FormikProps<GuardianFormValues>) => ReactNode)
          }
        </Formik>
      );
    }
    case "otherPerson": {
      const onSubmit = async (values: OtherPersonFormValues) => {
        try {
          if (!appointmentId) {
            throw new Error(translations.defaultError);
          }

          const customerId =
            selectedCustomer?.id || awsSession?.getIdToken()?.payload?.sub;

          const params: AppointmentCreatePaymentParams | SaveAppointmentParams =
            {
              appointmentId,
              customerId: customerId as string | undefined,
              creatorType: "otherPerson",
              ...values,
              dateOfBirth: getDateOfBirth(values.dateOfBirth),
              phoneNumber: getPhoneNumber(values.phoneNumber),
            };

          if (isPaymentRequired) {
            ReactGA.event({
              action: "Click confirm and pay",
              category: GAEventCategory.click,
            });

            const { data: createdPaymentData } = await appointmentCreatePayment(
              params,
            );

            if (createdPaymentData.status === "error") {
              throw new Error(translations.errorEmailInUse);
            }

            const paymentUrl = getPaymentUrl(
              createdPaymentData.data,
              appointmentId,
              translations.defaultError,
            );

            clearStorageAppointment();
            navigate(paymentUrl);
          }

          if (!isPaymentRequired) {
            ReactGA.event({
              action: "Click save appointment",
              category: GAEventCategory.click,
            });

            await saveAppointment(params);

            const { data: saveAppointmentResponse } = await saveAppointment(
              params,
            );

            if (saveAppointmentResponse.status === "error") {
              throw new Error(translations.errorEmailInUse);
            }

            clearStorageAppointment();

            navigate(`/${appointmentId}/success`);
          }
        } catch (error) {
          // @TODO use error alert
          // eslint-disable-next-line no-alert
          alert((error as Error).message);
        }
      };

      const validationSchema = getValidationSchema(
        "otherPersonForm",
        validationSchemaTranslations,
        MIN_STRING_INPUT_LENGTH,
        MAX_STRING_INPUT_LENGTH,
      );

      const initialValues = getOtherPersonInitialValues({ selectedCustomer });

      return (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {
            children as
              | React.ReactNode
              | ((props: FormikProps<OtherPersonFormValues>) => ReactNode)
          }
        </Formik>
      );
    }
    default: {
      return (
        <Formik initialValues={{}} onSubmit={() => {}}>
          {children}
        </Formik>
      );
    }
  }
};

export default AppointmentCheckoutFormSectionWrapper;
