import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import messages from "./messages";

const useTranslations = (params: {
  depositAmount: number;
  currencyCode: string;
}) => {
  const { depositAmount, currencyCode } = params;

  const { formatMessage, formatNumber } = useIntl();

  const depositAmountString = formatNumber(depositAmount, {
    style: "currency",
    currency: currencyCode,
  }).replace(".00", "");

  const translations = useMemo(
    () => ({
      btnFullAmount: formatMessage(messages.btnFullAmount),
      btnDeposit: formatMessage(messages.btnDeposit, {
        depositAmount: depositAmountString,
      }),
      defaultError: formatMessage(commonMessages.defaultError),
    }),
    [depositAmountString, formatMessage],
  );

  return translations;
};

export default useTranslations;
