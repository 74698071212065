import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(
    () => ({
      authCodeInvalid: formatMessage(messages.authCodeInvalid),
      defaultError: formatMessage(commonMessages.defaultError),
    }),
    [formatMessage],
  );

  return translations;
};

export default useTranslations;
