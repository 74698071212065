import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import { ITheme } from "../../../common/theme";

export default makeStyles<ITheme>((theme) => ({
  locationPaper: {
    padding: theme.spacing(3),
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  priceOverrideInput: {
    color: theme.palette.text.secondary,
  },
}));
