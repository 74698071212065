/* eslint-disable @typescript-eslint/naming-convention */
import Big from "big.js";
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import getTimezoneOffset from "date-fns-tz/getTimezoneOffset";
import commonMessages from "../../../common/messages";
import useDataState from "../../../hooks/useDataState";
import useSupabase from "../../../hooks/useSupabase";
import { SystemConfig } from "../../../hooks/useSystemConfig";

type SupabaseBookingCenterConfig = Pick<
  DatabaseRow<"center_configs">,
  | "discount_amount"
  | "default_currency_code"
  | "default_offering_duration_minutes"
  | "appointment_checkout_duration_seconds"
  | "appointment_checkout_max_repeat_count"
  | "minimum_time_before_booking_seconds"
>;

type SupabaseBookingCenterOperatingHours = Pick<
  DatabaseRow<"center_operating_hours">,
  "day_of_week_number" | "from_minutes_at" | "to_minutes_at"
>;

type SupabaseBookingCenterAddress = DatabaseRow<"center_addresses">;

export type SupabaseBookingCenter = DatabaseRow<"centers"> & {
  center_configs: SupabaseBookingCenterConfig[];
  center_operating_hours: SupabaseBookingCenterOperatingHours[];
  center_addresses: SupabaseBookingCenterAddress[];
};

const supabaseBookingCentersRequest = `
*,
center_configs (
  discount_amount,
  default_currency_code,
  default_offering_duration_minutes,
  appointment_checkout_duration_seconds,
  appointment_checkout_max_repeat_count,
  minimum_time_before_booking_seconds
),
center_operating_hours (
  day_of_week_number,
  from_minutes_at,
  to_minutes_at
),
center_addresses (
  address,
  type
)
`;

export type BookingCenterOperatingHoursDayKey =
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7";

export type BookingCenterOperatingHours = Partial<
  Record<
    BookingCenterOperatingHoursDayKey,
    { fromMinutesAtUTC: number; toMinutesAtUTC: number }
  >
>;

export type BookingCenter = {
  id: string;
  name: string;
  country: string;
  city: string;
  postalCode: string;
  region: string;
  state: string;
  phoneNumber: string;
  email: string;
  faxNumber: string | null;
  discountAmount: number;
  defaultCurrencyCode: string;
  defaultOfferingDurationMinutes: number;
  operatingHours: BookingCenterOperatingHours;
  calculatedMinimumTimeBeforeBookingSeconds: number;
  timezoneOffsetMilliseconds: number;
  addresses: DatabaseRow<"center_addresses">[];
};

const parseOperatingHours = (
  supabaseOperatingHours: SupabaseBookingCenterOperatingHours[],
): BookingCenterOperatingHours => {
  return supabaseOperatingHours.reduce((acc, supabaseOperatingHour) => {
    const { day_of_week_number, from_minutes_at, to_minutes_at } =
      supabaseOperatingHour;

    if (acc[day_of_week_number]) {
      throw new Error("Day of week number has already defined");
    }

    return {
      ...acc,
      [day_of_week_number]: {
        fromMinutesAtUTC: from_minutes_at,
        toMinutesAtUTC: to_minutes_at,
      },
    };
  }, {} as BookingCenterOperatingHours);
};

const getCenterConfig = (params: {
  systemConfig: SystemConfig;
  centerConfig?: SupabaseBookingCenterConfig;
}) => {
  const { systemConfig, centerConfig } = params;
  const appointmentCheckoutDurationSeconds =
    centerConfig?.appointment_checkout_duration_seconds ??
    systemConfig.appointmentCheckoutDurationSeconds;

  const appointmentCheckoutMaxRepeatCount =
    centerConfig?.appointment_checkout_max_repeat_count ??
    systemConfig.appointmentCheckoutMaxRepeatCount;

  const minimumTimeBeforeBookingSeconds =
    centerConfig?.minimum_time_before_booking_seconds ??
    systemConfig.minimumTimeBeforeBookingSeconds;

  const calculatedMinimumTimeBeforeBookingSeconds = Big(
    appointmentCheckoutDurationSeconds,
  )
    .times(appointmentCheckoutMaxRepeatCount)
    .plus(minimumTimeBeforeBookingSeconds)
    .toNumber();

  return {
    calculatedMinimumTimeBeforeBookingSeconds,

    discountAmount:
      centerConfig?.discount_amount ?? systemConfig.discountAmount,

    defaultCurrencyCode:
      centerConfig?.default_currency_code ?? systemConfig.defaultCurrencyCode,

    defaultOfferingDurationMinutes:
      centerConfig?.default_offering_duration_minutes ??
      systemConfig.defaultOfferingDurationMinutes,
  };
};

export const parseCenter = (
  center: SupabaseBookingCenter,
  systemConfig: SystemConfig,
): BookingCenter => {
  const {
    id,
    name,
    country,
    city,
    postal_code,
    state,
    region,
    center_configs,
    center_operating_hours,
    phone_number,
    email,
    fax_number,
    timezone,
    center_addresses,
  } = center;

  const {
    discountAmount,
    defaultCurrencyCode,
    defaultOfferingDurationMinutes,
    calculatedMinimumTimeBeforeBookingSeconds,
  } = getCenterConfig({
    systemConfig,
    centerConfig: center_configs[0],
  });

  const timezoneOffsetMilliseconds = timezone ? getTimezoneOffset(timezone) : 0;

  return {
    id,
    name,
    country,
    city,
    postalCode: postal_code,
    state,
    region,
    phoneNumber: phone_number,
    email,
    faxNumber: fax_number || null,
    discountAmount,
    defaultCurrencyCode,
    defaultOfferingDurationMinutes,
    calculatedMinimumTimeBeforeBookingSeconds,
    operatingHours: parseOperatingHours(center_operating_hours),
    timezoneOffsetMilliseconds,
    addresses: center_addresses,
  };
};

const useBookingCenters = ({
  systemConfig,
}: {
  systemConfig: SystemConfig | null;
}) => {
  const supabase = useSupabase();
  const { formatMessage } = useIntl();

  const { dataState, updateDataState } = useDataState<BookingCenter[]>();

  const getCentersRequest = useCallback(async () => {
    try {
      updateDataState({ status: "loading" });

      if (!systemConfig) return;

      const response = await supabase
        .from<SupabaseBookingCenter>("centers")
        .select(supabaseBookingCentersRequest)
        .order("name")
        .limit(1, { foreignTable: "center_configs" });

      if (response.error) {
        throw new Error(response.error.message);
      }

      if (response.data) {
        try {
          const parsedCenters = response.data.map((center) =>
            parseCenter(center, systemConfig),
          );

          updateDataState({ status: "success", data: parsedCenters });
        } catch (error) {
          throw new Error(formatMessage(commonMessages.defaultError));
        }
      }
    } catch (err) {
      updateDataState({ status: "error", error: (err as Error).message });
    }
  }, [formatMessage, supabase, systemConfig, updateDataState]);

  useEffect(() => {
    getCentersRequest();
  }, [getCentersRequest]);

  return dataState;
};

export default useBookingCenters;
