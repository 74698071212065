import Big from "big.js";

export type SummaryPositionAddOn = {
  name: string;
  price: number;
};
export type SummaryPositionCenterService = {
  name: string;
  price: number;
  depositAmount: number;
};

const getTotalSummary = (params: {
  centerServices: SummaryPositionCenterService[];
  addOns: SummaryPositionAddOn[];
  discountAmount?: number;
  overriddenPrice: number | null;
  overriddenDeposit: number | null;
}) => {
  const {
    centerServices,
    addOns,
    discountAmount,
    overriddenPrice,
    overriddenDeposit,
  } = params;

  const centerServicesPriceBig = centerServices.reduce(
    (sum, centerService) => sum.plus(centerService.price),
    Big(0),
  );

  const addOnsPriceBig = addOns.reduce(
    (sum, addOn) => sum.plus(addOn.price),
    Big(0),
  );

  const price = centerServicesPriceBig.plus(addOnsPriceBig).round(2).toNumber();

  const additionalServicesLength = Math.max(centerServices.length - 1, 0);

  const discountSum = discountAmount
    ? Big(discountAmount).times(additionalServicesLength).toNumber()
    : 0;

  const priceWithDiscount = Big(price).minus(discountSum).toNumber();

  const isOverriddenPriceError =
    overriddenPrice !== null &&
    !Number.isNaN(overriddenPrice) &&
    overriddenPrice !== 0 &&
    Big(overriddenPrice).gte(priceWithDiscount);

  const isOverriddenDepositError =
    overriddenDeposit !== null &&
    !Number.isNaN(overriddenDeposit) &&
    (Big(overriddenDeposit).lt(0) ||
      Big(overriddenDeposit).gt(priceWithDiscount) ||
      (typeof overriddenPrice === "number" &&
        Big(overriddenDeposit).gt(overriddenPrice)));

  const servicesDepositAmount = centerServices
    .reduce(
      (sum, centerService) => sum.plus(centerService.depositAmount),
      Big(0),
    )
    .round(2)
    .toNumber();

  const depositAmount =
    overriddenPrice === null ||
    Number.isNaN(overriddenPrice) ||
    isOverriddenPriceError
      ? servicesDepositAmount
      : Math.min(servicesDepositAmount, overriddenPrice);

  const finalPrice =
    overriddenPrice === null ||
    Number.isNaN(overriddenPrice) ||
    isOverriddenPriceError
      ? Big(price).minus(discountSum).toNumber()
      : overriddenPrice;

  return {
    depositAmount:
      typeof overriddenDeposit === "number" && !isOverriddenDepositError
        ? overriddenDeposit
        : depositAmount,
    price,
    discountSum,
    priceWithDiscount: finalPrice,
    isOverriddenPriceError,
    isOverriddenDepositError,
  };
};

export default getTotalSummary;
