import { defineMessages } from "react-intl";

const commonMessages = defineMessages({
  defaultError: {
    id: "common.messages.defaultError",
    defaultMessage: "Unknown error",
  },
  minimumLengthError: {
    id: "common.messages.minimumLengthError",
    defaultMessage: "Must be at least {value} characters",
  },
  maximumLengthError: {
    id: "common.messages.maximumLengthError",
    defaultMessage: "Must be at most {value} characters",
  },
  shortMonday: {
    id: "common.messages.shortMonday",
    defaultMessage: "Mon",
  },
  shortTuesday: {
    id: "common.messages.shortTuesday",
    defaultMessage: "Tue",
  },
  shortWednesday: {
    id: "common.messages.shortWednesday",
    defaultMessage: "Wed",
  },
  shortThursday: {
    id: "common.messages.shortThursday",
    defaultMessage: "Thu",
  },
  shortFriday: {
    id: "common.messages.shortFriday",
    defaultMessage: "Fri",
  },
  shortSaturday: {
    id: "common.messages.shortSaturday",
    defaultMessage: "Sat",
  },
  shortSunday: {
    id: "common.messages.shortSunday",
    defaultMessage: "Sun",
  },
});

export default commonMessages;
