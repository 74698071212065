/* eslint-disable react/no-danger */
import React, { FC, useState, ChangeEventHandler } from "react";
import { useFormikContext } from "formik";
import { FormattedMessage } from "react-intl";
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import Box from "../../Unknown/Box";
import useTranslations from "./useTranslations";
import FormikTextField from "../../Unknown/FormikTextField";
import TextField from "../../Unknown/TextField";
import FormikRadioGroup, {
  FormikRadioGroupOption,
} from "../../Unknown/FormikRadioGroup";
import Dialog from "../../Unknown/Dialog";
import DialogTitle from "../../Unknown/DialogTitle";
import DialogContent from "../../Unknown/DialogContent";
import DialogActions from "../../Unknown/DialogActions";
import Button from "../../Unknown/Button";
import messages from "./messages";
import FormikPhoneInput from "../../Unknown/FormikPhoneInput";
import formatEmail from "../../../common/formatEmail";
import CustomerSearchInput from "../../Customer/CustomerSearchInput";

export type OtherPersonFormValues = {
  otherPersonFirstName: string;
  otherPersonLastName: string;
  otherPersonEmail: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: string;
  pregnant: "yes" | "no" | "idk" | "";
  wearingDevice: "yes" | "no" | "idk" | "";
};

type AppointmentCheckoutOtherPersonFormProps = {
  onNotPossibleAnswerClick: (question: string) => void;
  isCenterUser?: boolean;
  onSelectCustomer?: (customer: DatabaseRow<"customers">) => void;
};

const AppointmentCheckoutOtherPersonForm: FC<
  AppointmentCheckoutOtherPersonFormProps
> = ({ isCenterUser, onNotPossibleAnswerClick, onSelectCustomer }) => {
  const { isSubmitting, values, touched, errors, handleBlur, setFieldValue } =
    useFormikContext<OtherPersonFormValues>();

  const translations = useTranslations();

  const [isEmailCheckDialogOpen, setIsEmailCheckDialogOpen] = useState(false);

  const { answerYes, answerNo, answerIdk, wearingDeviceHeader } = translations;

  // Uncomment the code if there is a need for displaying pregnancy question in the future.
  // const onPregnantClick = () => {
  //   onNotPossibleAnswerClick(pregnantHeader);
  // };

  const onWearingDeviceClick = () => {
    onNotPossibleAnswerClick(wearingDeviceHeader);
  };

  // const pregnantOptions: FormikRadioGroupOption[] = [
  //   { value: "yes", label: answerYes, onClick: onPregnantClick },
  //   { value: "no", label: answerNo },
  //   { value: "idk", label: answerIdk },
  // ];

  const wearingDeviceOptions: FormikRadioGroupOption[] = [
    { value: "yes", label: answerYes, onClick: onWearingDeviceClick },
    { value: "no", label: answerNo },
    { value: "idk", label: answerIdk },
  ];

  const onEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const email = formatEmail(event.target.value);
    setFieldValue("email", email);

    const { name } = event.target;

    if (!email) return;

    const isOtherPersonEmailSame =
      name === "email" && email === values.otherPersonEmail;
    const isPatientEmailSame =
      name === "otherPersonEmail" && email === values.email;

    if (isOtherPersonEmailSame || isPatientEmailSame) {
      setIsEmailCheckDialogOpen(true);
    }
  };

  const onEmailCheckEmailClose = () => setIsEmailCheckDialogOpen(false);

  const onEmailChangeButtonClick = () => {
    setFieldValue("email" as keyof OtherPersonFormValues, "");
    setIsEmailCheckDialogOpen(false);
  };

  return (
    <>
      <Grid container spacing={8} direction="column">
        <Grid item container spacing={4} direction="column">
          <Grid item>
            <Typography variant="h3">
              {translations.yourInformationHeader}
            </Typography>
            <Typography mt={1} variant="h4" letterSpacing={0.4}>
              {translations.yourInformationDesc}
            </Typography>
          </Grid>
          <Grid item>
            <FormikTextField
              autoComplete="off"
              required
              fullWidth
              name="otherPersonFirstName"
              label={translations.firstNameLabel}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item>
            <FormikTextField
              autoComplete="off"
              required
              fullWidth
              name="otherPersonLastName"
              label={translations.lastNameLabel}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item>
            <TextField
              value={values.otherPersonEmail}
              name="otherPersonEmail"
              fullWidth
              label={translations.emailLabel}
              error={
                touched.otherPersonEmail && Boolean(errors.otherPersonEmail)
              }
              helperText={touched.otherPersonEmail && errors.otherPersonEmail}
              disabled={isSubmitting}
              onChange={({ target }) =>
                setFieldValue("otherPersonEmail", formatEmail(target.value))
              }
              onBlur={handleBlur}
              autoComplete="off"
              required
            />
          </Grid>
        </Grid>
        <Grid item container spacing={4} direction="column">
          <Grid item>
            <Typography variant="h3">
              {translations.patientInformationHeader}
            </Typography>
            <Typography mt={1} variant="h4" letterSpacing={0.4}>
              {translations.patientInformationDesc}{" "}
              <Box component="span" sx={{ fontWeight: 700 }}>
                {translations.patientInformationDescAttention}
              </Box>
            </Typography>
          </Grid>
          {isCenterUser && (
            <Grid item>
              <CustomerSearchInput onSelectCustomer={onSelectCustomer} />
            </Grid>
          )}
          <Grid item>
            <FormikTextField
              autoComplete="off"
              required
              fullWidth
              name="firstName"
              label={translations.firstNameLabel}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item>
            <FormikTextField
              autoComplete="off"
              required
              fullWidth
              name="lastName"
              label={translations.lastNameLabel}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item>
            <TextField
              value={values.email}
              name="email"
              fullWidth
              label={translations.emailLabel}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              disabled={isSubmitting}
              onChange={onEmailChange}
              onBlur={handleBlur}
              autoComplete="off"
              required
            />
          </Grid>
          <Grid item>
            <FormikPhoneInput
              name="phoneNumber"
              autoComplete="off"
              required
              fullWidth
              disabled={isSubmitting}
              label={translations.phoneNumberLabel}
            />
          </Grid>
          <Grid item>
            <FormikTextField
              autoComplete="off"
              required
              fullWidth
              name="dateOfBirth"
              type="date"
              label={translations.dateOfBirthLabel}
              disabled={isSubmitting}
              InputLabelProps={{ shrink: true }}
              helperText={translations.dateOfBirthHelper}
              placeholder={translations.dateOfBirthPlaceholder}
            />
          </Grid>
        </Grid>
        {/* <Grid item container spacing={4} direction="column">
          <Grid item>
            <Typography variant="h3">{translations.pregnantHeader}</Typography>
          </Grid>
          <Grid item>
            <FormikRadioGroup
              name="pregnant"
              isDisabled={isSubmitting}
              options={pregnantOptions}
            />
          </Grid>
        </Grid> */}
        <Grid item container spacing={4} direction="column">
          <Grid item>
            <Typography variant="h3">
              {translations.wearingDeviceHeader}
            </Typography>
          </Grid>
          <Grid item>
            <FormikRadioGroup
              name="wearingDevice"
              isDisabled={isSubmitting}
              options={wearingDeviceOptions}
            />
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={isEmailCheckDialogOpen}
        onClose={onEmailCheckEmailClose}
        aria-labelledby="email-check-title"
      >
        <DialogTitle id="email-check-title">
          {translations.emailCheckDialogTitle}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ overflowWrap: "break-word" }}>
            <FormattedMessage
              {...messages.emailCheckDialogDesc}
              values={{
                b: (chunks) => <b>{chunks}</b>,
                email: values.email,
              }}
            />
          </Box>

          <Box mt={5}>{translations.emailCheckDialogExplanation}</Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onEmailChangeButtonClick}>
            {translations.changeEmailButton}
          </Button>
          <Button onClick={onEmailCheckEmailClose}>
            {translations.okButton}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AppointmentCheckoutOtherPersonForm;
