import Big from "big.js";
import { useCallback, useEffect, useMemo } from "react";
import {
  DatabaseEnum,
  DatabaseRow,
} from "@Shape-Digital/kudzu-data/lib/types/common";
import useDataState from "../../../hooks/useDataState";
import useSupabase from "../../../hooks/useSupabase";

export type SupabaseBookingPackageOffering = Pick<
  DatabaseRow<"center_offerings">,
  | "id"
  | "name"
  | "description"
  | "deposit_amount"
  | "price"
  | "scan_side"
  | "duration_minutes"
>;

export type SupabaseBookingPackage = Pick<
  DatabaseRow<"center_packages">,
  | "id"
  | "name"
  | "description"
  | "visibility"
  | "center_id"
  | "price"
  | "deposit_amount"
> & {
  center_offerings: SupabaseBookingPackageOffering[];
};

const supabaseBookingPackagesRequest = `
id,
name,
description,
price,
deposit_amount,
visibility,
center_id,
center_offerings (
  id,
  name,
  description,
  deposit_amount,
  price,
  scan_side,
  duration_minutes
)
`;

export type BookingPackage = {
  id: string;
  name: string;
  description: string;
  depositAmount: number;
  price: number;
  durationMinutes: number;
  discountPrice: number;
};

export const parsePackage = (
  packageItem: SupabaseBookingPackage,
  defaultOfferingDurationMinutes: number,
  discountAmount: number,
): BookingPackage => {
  const { id, name, description, center_offerings, price, deposit_amount } =
    packageItem;

  const durationMinutes = center_offerings.reduce(
    (sum, offering) =>
      sum + (offering.duration_minutes ?? defaultOfferingDurationMinutes),
    0,
  );

  const discountPrice = discountAmount
    ? Big(price).minus(discountAmount).toNumber()
    : price;

  return {
    id,
    name,
    description,
    depositAmount: deposit_amount,
    price,
    durationMinutes,
    discountPrice,
  };
};

type PackagesCenterConfig = {
  id: string;
  defaultOfferingDurationMinutes: number;
  discountAmount: number;
};

const useBookingPackages = (params: {
  centerConfig: PackagesCenterConfig | null;
  isCenterUser: boolean;
}) => {
  const { centerConfig, isCenterUser } = params;

  const supabase = useSupabase();

  const { dataState, updateDataState } = useDataState<BookingPackage[]>();

  const privateVisibilityProps = useMemo(() => {
    return isCenterUser ? ["private" as const] : [];
  }, [isCenterUser]);

  const visibility: DatabaseEnum["package_visibility"][] = useMemo(() => {
    return ["public", ...privateVisibilityProps];
  }, [privateVisibilityProps]);

  const allowedPackageOfferingVisibilities: DatabaseEnum["offering_visibility"][] =
    useMemo(() => {
      return ["public", "only_package", ...privateVisibilityProps];
    }, [privateVisibilityProps]);

  const getPackagesRequest = useCallback(async () => {
    try {
      if (!centerConfig) return;

      const { id, defaultOfferingDurationMinutes, discountAmount } =
        centerConfig;

      updateDataState({ status: "loading" });

      const response = await supabase
        .from<SupabaseBookingPackage>("center_packages")
        .select(supabaseBookingPackagesRequest)
        .in(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          "center_offerings.visibility" as any,
          allowedPackageOfferingVisibilities,
        )
        .in("visibility", visibility)
        .eq("center_id", id);

      if (response.error) {
        throw new Error(response.error.message);
      }

      if (response.data) {
        const parsedPackages = response.data.map((packageItem) =>
          parsePackage(
            packageItem,
            defaultOfferingDurationMinutes,
            discountAmount,
          ),
        );

        updateDataState({ status: "success", data: parsedPackages });
      }
    } catch (err) {
      updateDataState({ status: "error", error: (err as Error).message });
    }
  }, [
    allowedPackageOfferingVisibilities,
    centerConfig,
    supabase,
    updateDataState,
    visibility,
  ]);

  useEffect(() => {
    getPackagesRequest();
  }, [getPackagesRequest]);

  return dataState;
};

export default useBookingPackages;
