/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import isPast from "date-fns/isPast";
import parse from "date-fns/parse";
import { PatientFormValues } from "../AppointmentCheckoutPatientForm";
import { GuardianFormValues } from "../AppointmentCheckoutGuardianForm";
import { OtherPersonFormValues } from "../AppointmentCheckoutOtherPersonForm";
import { EMAIL_REG_EXP, MIN_PHONE_NUMBER_LEN } from "../../../common/constants";

export type ValidationTranslations = {
  requiredField: string;
  dateIsInvalid: string;
  emailIsInvalid: string;
  phoneNumberIsInvalid: string;
  pregnantError: string;
  wearingDeviceError: string;
  minimumLengthError: string;
  maximumLengthError: string;
  phoneNumberMinLengthError: string;
  phoneNumberMaxLengthError: string;
};

type FormType = "patientForm" | "guardianForm" | "otherPersonForm";

export const CHECKOUT_DATE_FORMAT = "yyyy-MM-dd";

const getValidationSchema = (
  formType: FormType,
  translations: ValidationTranslations,
  minimumStringLength: number,
  maximumStringLength: number,
) => {
  const {
    requiredField,
    dateIsInvalid,
    emailIsInvalid,
    phoneNumberIsInvalid,
    pregnantError,
    wearingDeviceError,
    minimumLengthError,
    maximumLengthError,
  } = translations;

  const requiredString = Yup.string().trim().required(requiredField);

  const requiredDefaultString = requiredString
    .min(minimumStringLength, minimumLengthError)
    .max(maximumStringLength, maximumLengthError);

  const email = requiredDefaultString.matches(EMAIL_REG_EXP, emailIsInvalid);

  const phoneNumber = Yup.string()
    .matches(new RegExp(/[0-9]+$/), phoneNumberIsInvalid)
    .test(function testPhoneNumberLength(value) {
      if (!value) {
        return this.createError({ message: requiredField });
      }

      return true;
    })
    .min(MIN_PHONE_NUMBER_LEN, phoneNumberIsInvalid)
    .required(requiredField);

  const dateOfBirth = requiredDefaultString.test(function testDate(value) {
    if (!value) {
      return this.createError({ message: requiredField });
    }

    const date = parse(value, CHECKOUT_DATE_FORMAT, Date.now());

    if (!isPast(date)) {
      return this.createError({ message: dateIsInvalid });
    }

    return true;
  });

  const yesNoPregnantString = requiredDefaultString.oneOf(
    ["no"],
    pregnantError,
  );

  const yesNoDeviceString = requiredDefaultString.oneOf(
    ["no"],
    wearingDeviceError,
  );

  if (formType === "patientForm") {
    return Yup.object().shape<Record<keyof PatientFormValues, any>>({
      email,
      phoneNumber,
      firstName: requiredDefaultString,
      lastName: requiredDefaultString,
      dateOfBirth,
      pregnant: yesNoPregnantString,
      wearingDevice: yesNoDeviceString,
    });
  }

  if (formType === "guardianForm") {
    return Yup.object().shape<Record<keyof GuardianFormValues, any>>({
      guardianEmail: email,
      guardianPhoneNumber: phoneNumber,
      guardianFirstName: requiredDefaultString,
      guardianLastName: requiredDefaultString,
      firstName: requiredDefaultString,
      lastName: requiredDefaultString,
      dateOfBirth,
      pregnant: yesNoPregnantString,
      wearingDevice: yesNoDeviceString,
    });
  }

  const yesNoIdkPregnantString = requiredDefaultString.oneOf(
    ["no", "idk"],
    pregnantError,
  );
  const yesNoIdkDeviceString = requiredDefaultString.oneOf(
    ["no", "idk"],
    wearingDeviceError,
  );

  if (formType === "otherPersonForm") {
    return Yup.object().shape<Record<keyof OtherPersonFormValues, any>>({
      otherPersonFirstName: requiredDefaultString,
      otherPersonLastName: requiredDefaultString,
      otherPersonEmail: email,
      firstName: requiredDefaultString,
      lastName: requiredDefaultString,
      email,
      phoneNumber,
      dateOfBirth,
      pregnant: yesNoIdkPregnantString,
      wearingDevice: yesNoIdkDeviceString,
    });
  }

  throw new Error("formType is not allowed");
};

export default getValidationSchema;
