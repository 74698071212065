import { CheckboxGroupOption } from "../../Unknown/CheckboxGroup";

type AddOn = {
  id: string;
  name: string;
  price: number;
};

const parseAddOnToCheckboxGroupOption = (
  addOn: AddOn,
  currencyCode: string,
  checked: boolean,
): CheckboxGroupOption => {
  const { id, name, price } = addOn;
  return {
    value: id,
    label: name,
    price,
    currencyCode,
    checked,
  };
};

export default parseAddOnToCheckboxGroupOption;
