import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import { ITheme } from "../../../common/theme";

export default makeStyles<ITheme>(({ palette, borderRadius, spacing }) => ({
  containerRoot: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: alpha(palette.common.black, 0.25),
    borderRadius: borderRadius.s,
    paddingTop: spacing(3.5),
    paddingRight: spacing(3),
    paddingBottom: spacing(3.5),
    paddingLeft: spacing(3),
  },
  descriptionRoot: {
    color: alpha(palette.common.black, 0.6),
    fontSize: 12,
  },
}));
